import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { styles } from "../../styles/styles";
import dayjs from "dayjs";
import { ASbutton } from "../../components/buttons/ASbutton";
import { MdNotificationImportant } from "react-icons/md";

import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { DatePicker } from "@mui/x-date-pickers";
import { editUser, getUserByUserRef } from "../../store/actions/users";
import { fsTimestampCreador } from "../../firebase/firebase-config";
const MySwal = withReactContent(Swal);
export const UserModal = ({ open, onClose, user, onRefresh }) => {
  const theme = useTheme();

  const [parsedDate, setParsedDate] = useState(dayjs("2005-01-01"));
  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(true);

  const getInfo = useCallback(async () => {
    try {
      const userData = await getUserByUserRef(user.userRef);
      setUserInfo(userData);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [user]);

  useEffect(() => {
    console.log(userInfo);
    getInfo();
  }, [getInfo]);
  const selectedPlan = user?.plan;

  const formatDate = (date) =>
    !date.seconds
      ? date // Already a javascript date object
      : date.toDate();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
  });

  const renderList = () => {
    if (!selectedPlan) {
      return (
        <Grid container item md={10}>
          <Grid item md={8}>
            <Typography
              textAlign={"left"}
              variant="body1"
              sx={{ fontSize: "0.8rem !important" }}
            >
              No hay datos disponibles
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return selectedPlan.data.map((data) => (
      <Grid container item md={10}>
        <Grid item md={8}>
          <Typography
            textAlign={"left"}
            variant="body1"
            sx={{ fontSize: "0.8rem !important" }}
            key={data.id}
          >
            {data.description}
          </Typography>
        </Grid>
        <Grid item md={3.7}>
          <Typography variant="body2" key={data.id} textAlign={"right"}>
            {data.value}
          </Typography>
        </Grid>
      </Grid>
    ));
  };

  useEffect(() => {
    if (userInfo?.birthday) {
      // Convert the user's birthday (timestamp or string) to a Dayjs object
      const birthdayDayjs = formatDate(userInfo.birthday);
      const parsed = dayjs(birthdayDayjs);
      setParsedDate(parsed);
      console.log([parsed]);
    }
  }, [userInfo]);

  const formik = useFormik({
    enableReinitialize: true, // Enable reinitialization when initial values change

    initialValues: {
      email: user?.userEmail ?? "",
      name: userInfo?.name ?? "",
      lastname: userInfo?.lastname ?? "",
      secondLastname: userInfo?.secondLastname ?? "",
      phone: userInfo?.phone ?? "",
      price: selectedPlan?.price ?? "0",
      birthday: parsedDate,
      personalId: userInfo?.personalId ?? "",
      activationDate: user.activationDate ?? "",
      status: user.status ?? "",
      duration: user.duration ?? "",
    },

    onSubmit: async (values) => {
      // Before the onSubmit function
      // console.log("parsedDate:", parsedDate);
      // console.log("formik.values.birthday:", formik.values.birthday);

      // console.log("values", values);
      const timestampBirthday = fsTimestampCreador.fromDate(
        new Date(formik.values.birthday)
      );

      // console.log("Timestamp birthday:", timestampBirthday);

      const confirmResult = await MySwal.fire({
        text: "¿Estás seguro de que quieres editar este usuario?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, editar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });
      if (confirmResult.isConfirmed) {
        const loadingSwal = MySwal.fire({
          title: "Guardando...",
          html: '<div class="swal2-spinner"></div>',
          showConfirmButton: false,
          allowOutsideClick: false,
        });

        const data = {
          birthday: timestampBirthday,
          name: values.name,
          lastname: values.lastname,
          secondLastname: values.secondLastname,
          phone: values.phone,
          personalId: values.personalId,
        };
        try {
          await editUser(user.userId, data);
          MySwal.fire("Cambios Guardados", "", "success");
          onClose();
          onRefresh();
          loadingSwal.close();
        } catch (error) {
          MySwal.fire("Error", "Hubo un error al editar el usuario", "error");
          console.log(error);
          onClose();
          loadingSwal.close();
        }
      }
    },
  });
  useEffect(() => {
    // Initialize formik values when userInfo changes
    formik.setValues({
      email: user?.userEmail ?? "",
      name: userInfo?.name ?? "",
      lastname: userInfo?.lastname ?? "",
      secondLastname: userInfo?.secondLastname ?? "",
      phone: userInfo?.phone ?? "",
      price: selectedPlan?.price ?? "0",
      birthday: parsedDate,
      personalId: userInfo?.personalId ?? "",
      activationDate: user.activationDate ?? "",
      status: user.status ?? "",
      duration: user.duration ?? "",
    });
  }, [userInfo]);
  // useEffect(() => {
  //   console.log(user.userId);
  // }, []);

  // if (!user) {
  //   return null;
  // }
  if (loading) {
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"md"}
      PaperProps={{
        sx: {
          // backgroundColor: theme.palette.secondary.secondary,
          width: "100%",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle color={theme.palette.primary.main}></DialogTitle>
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"md"}
      PaperProps={{
        sx: {
          // backgroundColor: theme.palette.secondary.secondary,
          width: "100%",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle color={theme.palette.primary.main}>
        {userInfo?.name + " " + userInfo?.lastname}
      </DialogTitle>
      <DialogContent>
        <Box sx={styles.formContainer}>
          <form>
            <Box sx={[styles.plansCard, { mt: 0, mb: 2 }]}>
              <Grid container sx={styles.summaryCard}>
                <Grid
                  container
                  columnGap={0}
                  justifyContent={"space-between"}
                  mb={4}
                  mt={2}
                >
                  <Grid item xs={12} sm={12} md={3.7}>
                    <FormControl fullWidth>
                      <TextField
                        label="Nombre"
                        variant="outlined"
                        disabled={userInfo.isActiveMember}
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3.7}>
                    <FormControl fullWidth>
                      <TextField
                        label="Apellido"
                        name="lastname"
                        variant="outlined"
                        disabled={userInfo.isActiveMember}
                        value={formik.values.lastname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.lastname &&
                          Boolean(formik.errors.lastname)
                        }
                        helperText={
                          formik.touched.lastname && formik.errors.lastname
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3.7}>
                    <FormControl fullWidth>
                      <TextField
                        label="Segundo Apellido"
                        variant="outlined"
                        name="secondLastname"
                        disabled={userInfo.isActiveMember}
                        value={formik.values.secondLastname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.secondLastname &&
                          Boolean(formik.errors.secondLastname)
                        }
                        helperText={
                          formik.touched.secondLastname &&
                          formik.errors.secondLastname
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                {/* ROW 2 */}

                <Grid
                  container
                  columnGap={0}
                  justifyContent={"space-between"}
                  mb={4}
                >
                  <Grid item xs={12} sm={12} md={3.7}>
                    <FormControl fullWidth>
                      <TextField
                        label="Correo Electrónico"
                        variant="outlined"
                        disabled
                        name="email"
                        value={user?.userEmail}
                        InputProps={{
                          readOnly: true,
                          pointerEvents: "none",
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2.5}>
                    <FormControl fullWidth>
                      <TextField
                        label="Telêfono"
                        variant="outlined"
                        name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.phone && Boolean(formik.errors.phone)
                        }
                        helperText={formik.touched.phone && formik.errors.phone}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4.85}>
                    <FormControl fullWidth>
                      <TextField
                        label="Identificación"
                        variant="outlined"
                        name="personalId"
                        disabled={userInfo.isActiveMember}
                        value={formik.values.personalId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.personalId &&
                          Boolean(formik.errors.personalId)
                        }
                        helperText={
                          formik.touched.personalId && formik.errors.personalId
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                {/* ROW 3 */}

                <Grid
                  container
                  columnGap={0}
                  justifyContent={"space-between"}
                  mb={2}
                >
                  <Grid item xs={12} sm={12} md={3.7}>
                    <FormControl fullWidth>
                      <DatePicker
                        label="Fecha de Nacimiento"
                        id="birthday"
                        name="birthday"
                        value={parsedDate}
                        disabled={userInfo.isActiveMember}
                        onChange={(newValue) => {
                          formik.setFieldValue("birthday", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={
                              formik.touched.birthday &&
                              Boolean(formik.errors.birthday)
                            }
                            helperText={
                              formik.touched.birthday && formik.errors.birthday
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                columnGap={2}
                justifyContent={"space-between"}
                mt={4}
              >
                <Grid item md={5.5} container mb={3} sx={styles.summaryCard}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      mb: 2,
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h6">
                      {selectedPlan?.subTier}
                    </Typography>
                    <Typography variant="body1">
                      Código: {selectedPlan?.code}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} mb={2}>
                    <FormControl fullWidth>
                      <TextField
                        label="Duración del Plan"
                        variant="outlined"
                        value={`${user?.duration} meses`}
                        InputProps={{
                          readOnly: true,
                          pointerEvents: "none",
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} mb={2}>
                    <FormControl fullWidth>
                      <TextField
                        label="Estado del Plan"
                        variant="outlined"
                        value={
                          user?.status === "active" ? "Activo" : "Inactivo"
                        }
                        InputProps={{
                          readOnly: true,
                          pointerEvents: "none",
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                {/* @ND ROW */}

                <Grid item md={5.5} container mb={3} sx={styles.summaryCard}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",

                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h6">Detalles del plan</Typography>
                    <Typography variant="body1">
                      Código: {selectedPlan?.code}
                    </Typography>
                  </Box>

                  <Grid
                    container
                    sx={{
                      margin: "0 auto",

                      pt: 1,
                      pb: 1,
                    }}
                  >
                    {renderList()}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Grid
              container
              sx={styles.buttonsContainer}
              justifyContent={"flex-end"}
              direction="row"
            >
              <Grid item>
                <ASbutton
                  onClick={onClose}
                  type="submit"
                  backgroundColor={"grey"}
                  color={"white"}
                  buttonText={"Volver"}
                />
              </Grid>
              <Grid item>
                <Box ml={2}>
                  <ASbutton
                    onClick={formik.handleSubmit}
                    backgroundColor={theme.palette.primary.main}
                    color={"white"}
                    buttonText={"Guardar"}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>{/* Your actions or buttons here */}</DialogActions>
    </Dialog>
  );
};
