import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MUIDataTable from "mui-datatables";
import { Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import CustomBodyWithSpinner from "../general/CustomBodyWithSpinner";
import { useNavigate } from "react-router-dom";
import { IoRefreshOutline } from "react-icons/io5";
import { IconButton } from "@mui/material";

import {
  getAllUsersFromAssociation,
  getUsersFromAssociationCodes,
} from "../../store/actions/users";
import { getAssociationCodes } from "../../store/actions/association";

export const HomeTable = ({
  associationId,

  setUsersFromDB,
  setActiveUsersFromDB,
  activeUsersFromDB,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { codes } = useSelector((state) => state.association);
  const { users } = useSelector((state) => state.users);

  const isFirstUse = useRef(true);
  const [loading, setloading] = useState(false);

  const [noDataAvailable, setNoDataAvailable] = useState(false);

  const getUsersFromFB = useCallback(() => {
    try {
      dispatch(getAssociationCodes(associationId));
      const filteredUsers = codes.filter((u) => u.status === "active");

      setUsersFromDB(codes);
      setActiveUsersFromDB(filteredUsers);
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getUsersFromFB();
      isFirstUse.current = false;
    } else if (codes?.length > 0) {
      setloading(false);
      const filteredUsers = codes.filter((u) => u.status === "active");
      setUsersFromDB(codes);
      setActiveUsersFromDB(filteredUsers);
    } else {
      const timeout = setTimeout(() => {
        setNoDataAvailable(true);
      }, 4200);

      return () => clearTimeout(timeout);
    }
    if (noDataAvailable) {
      setloading(false);
    }
  }, [getUsersFromFB, codes]);

  const handleRefresh = () => {
    setloading(true);
    getUsersFromFB();
    setTimeout(() => {
      setloading(false);
    }, 1000);
  };

  const BodyComponent = useMemo(
    () => (tableProps) => {
      if (noDataAvailable) {
        return (
          <CustomBodyWithSpinner loading={!noDataAvailable} {...tableProps} />
        );
      }

      return (
        <CustomBodyWithSpinner
          loading={loading || codes?.length === 0}
          {...tableProps}
        />
      );
    },
    [codes, loading, noDataAvailable]
  );

  const columns = [
    {
      name: "userEmail",
      label: "Email",
      options: {
        display: true,
        filter: false,
      },
    },

    {
      name: "codeId",
      label: "Código",
      options: {
        display: true,
        filter: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return value === "active" ? "Activo" : "Inactivo";
        },
        filter: false,
      },
    },
    {
      name: "duration",
      label: "Duración",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A";
        },
        filter: false,
      },
    },
    {
      name: "activationDate",
      label: "Fecha de activación",
      options: {
        customBodyRender: (value) => {
          if (typeof value === "object" && value.seconds && value.nanoseconds) {
            const date = new Date(
              value.seconds * 1000 + value.nanoseconds / 1000000
            );
            const formattedDate = date.toLocaleDateString();
            return formattedDate;
          } else {
            return "-";
          }
        },

        filter: false,
      },
    },
  ];
  const CustomToolbar = ({ onRefresh }) => {
    return (
      <IconButton className="refresh-button" onClick={onRefresh}>
        <IoRefreshOutline size={25} />
      </IconButton>
    );
  };
  const options = {
    filter: true,
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 15,

    sortOrder: {
      name: "name",
      direction: "asc",
    },
    textLabels: {
      body: {
        noMatch: "No hay usuarios activos",
      },
    },
    sortCompare: (order) => (a, b) => {
      const isAsc = order === "asc";

      const column = "lastname";

      const aValue =
        a.data[column] !== undefined &&
        a.data[column] !== null &&
        a.data[column].trim() !== ""
          ? a.data[column]
          : "N/A";
      const bValue =
        b.data[column] !== undefined &&
        b.data[column] !== null &&
        b.data[column].trim() !== ""
          ? b.data[column]
          : "N/A";

      if (isAsc) {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    },
    customToolbar: () => {
      return <CustomToolbar onRefresh={handleRefresh} />;
    },
  };

  return (
    <Box mt={6} sx={{ width: "100%" }}>
      <MUIDataTable
        title="Usuarios Activos"
        data={activeUsersFromDB}
        columns={columns}
        options={options}
        components={{ TableBody: BodyComponent }}
      />
    </Box>
  );
};
