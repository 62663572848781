import jwtDecode from "jwt-decode";

import React, { useState } from "react";
import { Box, Container, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { styles } from "../../styles/styles";

import B2bPlans from "../../components/inclusions/B2bPlans";
import { B2BForm } from "../../components/inclusions/B2BForm";
import dayjs from "dayjs";
import { B2bSummary } from "../../components/inclusions/B2bSummary";
import { setSale, uploadFiles } from "../../store/actions/users";
import { refreshToken } from "../../store/actions/auth";
import { useSelector } from "react-redux";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { InclusionModal } from "../../components/inclusions/InclusionModal";
import B2bDocumentation from "../../components/inclusions/B2bDocumentation";
import { fsTimestampCreador } from "../../firebase/firebase-config";
import { InclusionsTable } from "../../components/inclusions/InclusionsTable";

const MySwal = withReactContent(Swal);

export const Inclusion = () => {
  const theme = useTheme();
  const { idToken } = useSelector((state) => state.auth);
  const { association } = useSelector((state) => state.association);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [apportionmentPrice, setApportionmentPrice] = useState(0);
  //IMAGE
  const [imageUpload, setImageUpload] = useState("");
  const [image, setImage] = useState("");
  const [fileUrl, setFileUrl] = useState(null);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [code, setCode] = useState("");

  const [dialogOpen, setDialogOpen] = useState(false);

  const [files, setFiles] = useState([]);
  const [userId, setUserId] = useState();

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setActiveStep(0);
    setData({
      name: "",
      lastname: "",
      secondLastname: "",
      phone: "",
      email: "",
      birthday: dayjs("2005-01-01"),
      civilState: "",
      gender: "",
      nationality: "",
      personalId: "",
      address: "",
      province: "",
      canton: "",
      district: "",
      zipCode: "",
      billNumber: "",
      price: 0,
      planDuration: "",
      apportionmentPrice: 0,
    });
  };

  const [data, setData] = useState({
    name: "",
    lastname: "",
    secondLastname: "",
    phone: "",
    email: "",
    birthday: dayjs("2005-01-01"),
    civilState: "",
    gender: "",
    nationality: "",
    personalId: "",
    address: "",
    province: "",
    canton: "",
    district: "",
    zipCode: "",
    billNumber: 0,
    price: 0,
    planDuration: "",
    apportionmentPrice: 0,
  });

  const handleTabChange = (event, newValue) => {
    setActiveStep(newValue);
  };
  const formatDate = (date) =>
    !date.seconds
      ? date // Already a javascript date object
      : date.toDate();

  const handleCancel = () => {
    setActiveStep(0);
    setData([]);
  };

  const handleRegister = async () => {
    const timestampBirthday = new Date(data.birthday);

    const firestoreTimestamp = fsTimestampCreador.fromDate(
      new Date(timestampBirthday)
    );

    const userData = {
      name: data.name,
      lastname: data.lastname,
      secondLastname: data.secondLastname ?? "",
      email: data.email ?? "",
      birthday: timestampBirthday,
      civilState: data.civilState ?? "",
      gender: data.gender ?? "",
      idType: data.nationality ?? "",
      address: data.address ?? "",
      province: data.province ?? "",
      canton: data.canton ?? "",
      district: data.district ?? "",
      zipCode: data.zipCode ?? "",
      device: data.device ?? "",
      personalId: data.personalId ?? "",
      phone: data.phone ?? "",
    };

    const finalData = {
      user: userData,
      plan: selectedPlan,
      planDuration: data.planDuration,
      finalPrice: apportionmentPrice,
      billNumber: 0,
      associationId: association.associationId,
    };

    console.log("FINAL DATA", finalData);

    // const productData = {
    //   planSelected: selectedPlan ?? "",
    //   price: data.price ?? 0,
    //   billNumber: data.billNumber ?? "",
    //   apportionmentPrice: apportionmentPrice ?? 0,
    // };
    const confirmResult = await MySwal.fire({
      title: "Confirmar ",
      text: "¿Estás seguro de que quieres agregar este usuario?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, agregar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    });
    if (confirmResult.isConfirmed) {
      const loadingSwal = MySwal.fire({
        title: "Guardando...",

        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          MySwal.showLoading();
        },
      });
      try {
        if (isValidToken(idToken)) {
          const res = await setSale(finalData, idToken);
          if (res.ok) {
            setQrCodeUrl(res.codeInfo.qrUrl);
            setCode(res.codeInfo.code);

            const id = res.userId;

            if (files) {
              await uploadFiles(id, association.associationId, files);
            }
            setUserId(id);

            MySwal.fire({
              title: `Usuario ${userData.name} creado con el correo ${userData.email}`,
              icon: "success",

              confirmButtonText: "Continuar",
            }).then((result) => {
              if (result.isConfirmed) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }
            });
          } else {
            // Handle error cases based on response data
            const errorMessage = res.message; // Adjust this based on your response structure
            MySwal.fire("Error", `${errorMessage}`, "error");
          }
        } else {
          const newToken = await refreshToken();
          console.log(newToken);
          const res = await setSale(finalData, newToken);
          if (res.ok) {
            setQrCodeUrl(res.codeInfo.qrUrl);
            setCode(res.codeInfo.code);
            const id = res.userId;
            setUserId(id);

            if (files) {
              await uploadFiles(id, association.associationId, files);
            }

            MySwal.fire({
              title: `Usuario ${userData.name} creado con el correo ${userData.email}`,
              icon: "success",

              confirmButtonText: "Continuar",
            }).then((result) => {
              if (result.isConfirmed) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }
            });
          } else {
            // Handle error cases based on response data
            const errorMessage = res.message; // Adjust this based on your response structure
            MySwal.fire("Error", `${errorMessage}`, "error");
          }
        }

        loadingSwal.close();
      } catch (error) {
        console.log(error);
        loadingSwal.close();

        MySwal.fire("Error", "Hubo un error al crear el usuario", "error");
      }
      loadingSwal.close();
    }
  };

  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };

  const steps = [
    "Información del Asociado",
    "Tipo de Plan",
    "Documentaciòn",
    "Resumen del Plan",
  ];

  const handleNext = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));

    console.log("Data after handleNext:", { ...data, ...newData });

    if (activeStep === 2) {
      handleRegister(newData);

      return;
    }
    // if (activeStep === 3) {
    //   handleActivation(newData);

    //   return;
    // }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Container maxWidth="xxl">
      <Box sx={styles.formCard}>
        <Box mt={4}>
          <Typography variant="h5" sx={styles.title}>
            Inclusiones
          </Typography>
        </Box>

        <Tabs
          value={activeStep}
          onChange={handleTabChange}
          indicatorColor="primary"
          sx={{ marginTop: 2, marginLeft: 1 }}
        >
          {steps.map((label, index) => (
            <Tab key={index} label={label} disabled={index > activeStep} />
          ))}
        </Tabs>

        <Box>
          {activeStep === 0 && (
            <B2BForm
              handleNext={handleNext}
              handleBack={handleBack}
              data={data}
              activeStep={activeStep}
              loadUrl={setFileUrl}
              imageUpload={imageUpload}
              setImageUpload={setImageUpload}
              setImage={setImage}
              image={image}
            />
          )}

          {activeStep === 1 && (
            <B2bPlans
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              associationId={association.associationId}
              apportionmentPrice={apportionmentPrice}
              setApportionmentPrice={setApportionmentPrice}
            />
          )}
          {activeStep === 2 && (
            <B2bDocumentation
              handleNext={handleNext}
              handleBack={handleBack}
              files={files}
              setSelectedPlan={setSelectedPlan}
              setFiles={setFiles}
            />
          )}
          {activeStep === 3 && (
            <B2bSummary
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
              selectedPlan={selectedPlan}
              apportionmentPrice={apportionmentPrice}
              setApportionmentPrice={setApportionmentPrice}
              data={data}
              userId={userId}
              code={code}
              qrCodeUrl={qrCodeUrl}
              associationId={association.associationId}
              handleOpenDialog={handleOpenDialog}
              dialogOpen={dialogOpen}
              handleCloseDialog={handleCloseDialog}
              handleCancel={handleCancel}
            />
          )}
          {/* {activeStep == 3 && (
            <B2bSummary
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
            />
          )} */}
        </Box>
      </Box>
    </Container>
  );
};
