import { Box } from "@mui/material";
import LogInForm from "../../components/login/LoginForm";

export const Login = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <LogInForm />
  </Box>
);
