import { auth, db, storageRef } from "../../firebase/firebase-config";
import { ADMIN_API_URL, API_URL } from "../../utils/globals";

export const GET_USERS = "GET_USERS";
export const REFRESH_USERS = "REFRESH_USERS";
export const IS_LOADING_USERS = "IS_LOADING_USERS";

export const getAssociation = async (id) => {
  try {
    const associationRef = db.collection("associations").doc(id);
    const associationSnapshot = await associationRef.get();

    if (associationSnapshot.exists) {
      const associationData = associationSnapshot.data();
      return associationData;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAllUsers = () => {
  return (dispatch) => {
    dispatch(isLoading(true));
    db.collection("users")
      .get()
      .then(async (itemsFromFs) => {
        const userPromises = itemsFromFs.docs.map(async (item) => {
          const userData = item.data();
          const { personalId, phone } = await getPrivateData(item.id);
          const codeName = "AMD";
          const { isB2b, code } = await getWalletData(item.id);

          if (isB2b && code) {
            const trimmedCode = code.split("-")[0]; // Trim code before the hyphen
            if (trimmedCode === codeName) {
              const name = userData.name || "N/A";
              const lastname =
                userData.lastname !== undefined &&
                userData.lastname !== null &&
                userData.lastname.trim() !== ""
                  ? userData.lastname
                  : "N/A";
              const secondLastname = userData.secondLastname || "N/A";
              const email = userData.email || "N/A";
              const plan = userData.planType?.subTier || "N/A";

              return {
                id: item.id,
                name: name.toString(),
                lastname: lastname.toString(),
                secondLastname: secondLastname.toString(),
                email: email,
                plan: plan.toString() ?? "N/A",
                personalId: personalId ?? "N/A",
                phone: phone ?? "N/A",
                ...userData,
              };
            }
          }

          return null; // Skip users that don't meet the criteria
        });

        const usersWithData = await Promise.all(userPromises);

        // Filter out null values (users that didn't meet the criteria)
        const filteredUsers = usersWithData.filter((user) => user !== null);

        dispatch(getUsers(filteredUsers));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
// export const getAllUsersByCode = () => {
//   return (dispatch) => {
//     dispatch(isLoading(true));
//     db.collection("users")
//       .get()
//       .then(async (itemsFromFs) => {
//         const userPromises = itemsFromFs.docs.map(async (item) => {
//           const userData = item.data();
//           const codeName = "AMD";
//           const { personalId, phone } = await getPrivateData(item.id);

//           if (isB2b && code) {
//             const trimmedCode = code.split("-")[0]; // Trim code before the hyphen
//             if (trimmedCode === codeName) {
//               const name = userData.name || "N/A";
//               const lastname =
//                 userData.lastname !== undefined &&
//                 userData.lastname !== null &&
//                 userData.lastname.trim() !== ""
//                   ? userData.lastname
//                   : "N/A";
//               const secondLastname = userData.secondLastname || "N/A";
//               const email = userData.email || "N/A";
//               const plan = userData.planType?.subTier || "N/A";

//               return {
//                 id: item.id,
//                 name: name.toString(),
//                 lastname: lastname.toString(),
//                 secondLastname: secondLastname.toString(),
//                 email: email,
//                 plan: plan.toString() ?? "N/A",
//                 personalId: personalId ?? "N/A",
//                 phone: phone ?? "N/A",
//                 ...userData,
//               };
//             }
//           }

//           return null; // Skip users that don't meet the criteria
//         });

//         const usersWithData = await Promise.all(userPromises);

//         // Filter out null values (users that didn't meet the criteria)
//         const filteredUsers = usersWithData.filter((user) => user !== null);

//         dispatch(getUsers(filteredUsers));
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
// };

export const getAllUsersFromAssociation = (associationCode, associationId) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    db.collection("users")
      .where("isB2bMember", "==", true)
      .where("planType.code", "==", associationCode)
      .get()
      .then(async (itemsFromFs) => {
        const userPromises = itemsFromFs.docs.map(async (item) => {
          const userData = item.data();

          const { personalId, phone } = await getPrivateData(item.id);
          const code = await getAssociationCodesFromUser(
            associationId,
            item.id
          );

          return {
            id: item.id,
            name: userData.name ?? "N/A",
            lastname: userData.lastname ?? "N/A",
            secondLastname: userData.secondLastname ?? "N/A",
            email: userData.email ?? "N/A",
            plan: userData.planType?.subTier ?? "N/A",
            personalId: personalId ?? "N/A",
            phone: phone ?? "N/A",
            code: code,
            ...userData,
          };
        });

        const usersWithData = await Promise.all(userPromises);

        const filteredUsers = usersWithData.filter((user) => user !== null);
        // console.log(filteredUsers.filter((code) => code.id));

        dispatch(getUsers(filteredUsers));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getAssociationCodesFromUser = async (associationId, userId) => {
  try {
    const codeQuerySnapshot = await db
      .collection("associations")
      .doc(associationId)
      .collection("codes")
      .where("userRef", "==", userId)
      .orderBy("activationDate", "desc")
      .get();

    if (!codeQuerySnapshot.empty) {
      const newestCodeDoc = codeQuerySnapshot.docs[0];
      const codeData = newestCodeDoc.data();
      const codeId = newestCodeDoc.id;
      // console.log(codeData);
      return {
        activationDate: codeData.activationDate ?? "N/A",
        duration: codeData.duration ?? "N/A",
        status: codeData.status ?? false,
        productId: codeData.productId ?? "N/A",

        code: codeId,
        ...codeData,
      };
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getUsersFromAssociationCodes = (associationId) => {
  return async (dispatch) => {
    dispatch(isLoading(true));

    try {
      const codeSnapshots = await db
        .collection("associations")
        .doc(associationId)
        .collection("codes")
        .get();

      const userPromises = codeSnapshots.docs.map(async (codeDoc) => {
        const userRef = codeDoc.data().userRef;
        const userSnapshot = await db.collection("users").doc(userRef).get();

        if (!userSnapshot.exists) {
          console.log("User does not exist for ID:", userRef);
          return null;
        }

        const userData = userSnapshot.data();
        const { personalId, phone } = await getPrivateData(userRef);

        const { activationDate, duration, expirationDate, productId, status } =
          codeDoc.data();

        return {
          id: userRef,
          name: userData.name ?? "N/A",
          lastname: userData.lastname ?? "N/A",
          secondLastname: userData.secondLastname ?? "N/A",
          email: userData.email ?? "N/A",
          plan: userData.planType?.subTier ?? "N/A",
          personalId: personalId ?? "N/A",
          phone: phone ?? "N/A",
          activationDate: activationDate ?? "N/A",
          duration: duration ?? "N/A",
          expirationDate: expirationDate ?? "N/A",
          productId: productId ?? "N/A",
          status: status ?? "N/A",
          ...userData,
        };
      });

      const usersData = await Promise.all(userPromises);
      const filteredUsersData = usersData.filter((user) => user !== null); // Filter out null users

      dispatch(getUsers(filteredUsersData));
    } catch (error) {
      console.log(error);
    }
  };
};
export const getUserByUserRef = async (userRef) => {
  try {
    const userSnapshot = await db.collection("users").doc(userRef).get();

    if (!userSnapshot.exists) {
      console.log("User does not exist for ID:", userRef);
      return null;
    }

    const userData = userSnapshot.data();
    const { personalId, phone } = await getPrivateData(userRef);

    return {
      userId: userRef,
      name: userData.name ?? "N/A",
      lastname: userData.lastname ?? "N/A",
      secondLastname: userData.secondLastname ?? "N/A",
      email: userData.email ?? "N/A",
      plan: userData.planType?.subTier ?? "N/A",
      personalId: personalId ?? "N/A",
      phone: phone ?? "N/A",
      ...userData,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const getUserByEmail = async (email) => {
  try {
    // Find the user by email address
    const userQuery = await db
      .collection("users")
      .where("email", "==", email)
      .get();

    if (userQuery.empty) {
      console.log("User not found for email:", email);
      return null;
    }

    const userDoc = userQuery.docs[0];
    const userData = userDoc.data();
    const userRef = userDoc.id;

    const { personalId, phone } = await getPrivateData(userRef);

    return {
      userId: userRef,
      name: userData.name ?? "N/A",
      lastname: userData.lastname ?? "N/A",
      secondLastname: userData.secondLastname ?? "N/A",
      email: userData.email ?? "N/A",

      personalId: personalId ?? "N/A",
      phone: phone ?? "N/A",
      ...userData,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getCodeByUserRef = async (associationId, userRef) => {
  try {
    const codesRef = db
      .collection("associations")
      .doc(associationId)
      .collection("codes");

    const snapshot = await codesRef
      .where("userRef", "==", userRef)
      .orderBy("activationDate", "desc")
      .limit(1)
      .get();

    if (snapshot.empty) {
      console.log("No codes found for the given user reference.");
      return null;
    }

    // Get the latest code with the highest activation date
    const latestCodeSnapshot = snapshot.docs[0];
    const latestCodeId = latestCodeSnapshot.id;
    const latestCodeData = latestCodeSnapshot.data();

    // Return an object containing both the ID and code data
    return { codeId: latestCodeId, data: latestCodeData };
  } catch (error) {
    console.error("Error fetching code:", error);
    throw error;
  }
};

export const updateCodeLink = async (associationId, codeId) => {
  const updateData = {
    linkUsed: true,
  };
  await db
    .collection("associations")
    .doc(associationId)
    .collection("codes")
    .doc(codeId)
    .set(updateData, { merge: true });
};

export const getCodeFromFb = (codeId, associationId, onUpdate) => {
  const codeDocRef = db
    .collection("associations")
    .doc(associationId)
    .collection("codes")
    .doc(codeId);

  const unsubscribe = codeDocRef.onSnapshot(
    (codeSnapshot) => {
      if (codeSnapshot.exists) {
        const codeData = codeSnapshot.data();
        // Process the code data from Firestore as needed
        console.log("Code data:", codeData);
        onUpdate(codeData); // Call the provided callback with the updated data
      } else {
        console.log("No code data found for the given code ID.");
        onUpdate(null); // Call the callback with null when no data exists
      }
    },
    (error) => {
      console.error("Error fetching code data:", error);
    }
  );

  // Return a function that can be used to unsubscribe the listener
  return () => {
    unsubscribe();
  };
};
export const getCodeAsyncFromFb = async (codeId) => {
  try {
    const associationsSnapshot = await db.collection("associations").get();
    for (const associationDoc of associationsSnapshot.docs) {
      const codeDocRef = associationDoc.ref.collection("codes").doc(codeId);
      const codeSnapshot = await codeDocRef.get();

      if (codeSnapshot.exists) {
        const codeData = { ...codeSnapshot.data(), codeId: codeSnapshot.id };

        const associationId = associationDoc.id;

        console.log("Code data:", codeData);
        console.log("Association ID:", associationId);

        return { codeData, associationId };
      }
    }

    console.log("No code data found for the given code ID.");
    return null;
  } catch (error) {
    console.error("Error fetching code data:", error);
    return null;
  }
};

export const getPrivateData = async (userId) => {
  return await db
    .collection("users")
    .doc(userId)
    .collection("private")
    .doc("data")
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.log("Error getting private data:", error);
      return {};
    });
};

export const getWalletData = async (userId) => {
  return await db
    .collection("users")
    .doc(userId)
    .collection("private")
    .doc("wallet")
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.log("Error getting wallet data:", error);
      return {};
    });
};

// export const createB2bUser = async (data) => {
//   const id = await db.collection("users").add(data);

//   return id;
// };
export const setSale = async (data, token) => {
  const url = API_URL + "/b2b/user";

  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};
export const activateUser = async (data, token) => {
  const url = API_URL + "/b2b/user";

  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};
export const activateUserAcc = async (data) => {
  const url = API_URL + "/b2b/user/acceptTerms";

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};
export const activateUserAccUser = async (data) => {
  const url = API_URL + "/b2b/user/new";
  console.log(data);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const addProductToUser = async (data, token) => {
  const url = `${API_URL}/b2b/code`;
  console.log("data", data);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const createUser = async (data, token) => {
  const url = ADMIN_API_URL + "/createUser";

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const removeUser = async (data, token) => {
  const url = API_URL + "/b2b/user";

  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};
export const updateAuthUserPhotoURL = (photoURL) => {
  const user = { photoURL };
  return auth.currentUser.updateProfile(user);
};

export const uploadPhoto = async (uid, img) => {
  try {
    const ref = storageRef(`/users/${uid}/profile`);
    const res = await ref.putFile(img);
    if (res.state === "success") {
      const imageURL = await ref.getDownloadURL();
      await updateAuthUserPhotoURL(imageURL);
      await db.doc(`users/${uid}`).set({ photoURL: imageURL }, { merge: true });
    }
  } catch (err) {
    console.log(err);
  }
};

export const uploadFiles = async (userId, associationId, files) => {
  console.log("Association ID:", associationId);
  console.log("User ID:", userId);
  console.log("files:", files);
  try {
    const userRef = db.doc(`users/${userId}`);
    const userDoc = await userRef.get();

    const storagePath = `associations/${associationId}/${userId}/userFiles/`;
    const ref = storageRef.ref(storagePath);
    if (!files) {
      console.log("Files array is undefined or null");
      return;
    }
    const uploadPromises = files.map(async (fileData) => {
      const { id, file } = fileData; // Destructure id and file from the object
      const fileRef = ref.child(file.name); // Use original file name as the reference key

      await fileRef.put(file);

      const imageURL = await fileRef.getDownloadURL();

      const fileType = file.type.startsWith("image/") ? "image" : "pdf";

      return { link: imageURL, type: fileType };
    });

    const uploadedFilesData = await Promise.all(uploadPromises);

    const existingFiles = userDoc.data()?.associationFiles?.files || [];
    const updatedFiles = existingFiles.concat(uploadedFilesData);

    const associationDataRef = db.doc(`users/${userId}/private/documents`);
    await associationDataRef.set(
      {
        associationFiles: {
          files: updatedFiles,
        },
      },
      { merge: true }
    );
  } catch (err) {
    console.log(err);
  }
};

export const countUsers = async (onCount) => {
  const query = db.collection("users");
  let count = 0;
  query.onSnapshot((snapshot) => {
    snapshot.docChanges().forEach((doc) => {
      if (doc.type === "added") onCount(++count);
      if (doc.type === "removed") onCount(--count);
    });
  });
};

export const editUser = async (userId, data) => {
  try {
    await db.collection("users").doc(userId).set(data, { merge: true });
  } catch (error) {
    console.log(error);
  }
};

export const getDataFromCode = async (code) => {
  const url = `${API_URL}/b2b/user/${code}`;

  const headers = {
    "Content-Type": "application/json",
  };

  const options = {
    method: "GET",
    headers: headers,
  };

  try {
    const response = await fetch(url, options);

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const doesUserHaveCode = async (associationId, userRef) => {
  try {
    const codesRef = db
      .collection("associations")
      .doc(associationId)
      .collection("codes");

    const snapshot = await codesRef.where("userRef", "==", userRef).get();

    return !snapshot.empty;
  } catch (error) {
    console.error("Error checking if user has a code:", error);
    throw error;
  }
};

//ACTIONS

export const getUsers = (users) => ({
  type: GET_USERS,
  payload: {
    users,
  },
});

export const refreshUsers = (shouldRefresh) => ({
  type: REFRESH_USERS,
  payload: shouldRefresh,
});

export const isLoading = (isLoading) => ({
  type: IS_LOADING_USERS,
  payload: {
    isLoading,
  },
});
