import jwtDecode from "jwt-decode";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { styles } from "../../styles/styles";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField"; // Import FilePond image preview styles

import { ASbutton } from "../../components/buttons/ASbutton";
import { isMobile } from "react-device-detect";

import { useFormik } from "formik";
import * as Yup from "yup";

import { activateUserAccUser } from "../../store/actions/users";

import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Terms } from "../../pages/acceptance/Terms";

const MySwal = withReactContent(Swal);

export const AccSummary = ({
  handleBack,
  apportionmentPrice,
  setApportionmentPrice,

  associationId,

  setLinkAvailable,
  selectedPlan,
  data,
  loading,
}) => {
  const theme = useTheme();
  const { idToken } = useSelector((state) => state.auth);
  const [priceNoIVA, setPriceNoIva] = useState(0);
  const [priceToCharge, setPriceToCharge] = useState(0);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
  });

  // const userId = "6hBHGxSxajTBsLWsk55rXvUxNqo2";
  const [readTerms, setReadTerms] = useState(false);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const overallDate = new Date();
    const year = overallDate.getFullYear();
    const month = overallDate.getMonth() + 1;
    const date = overallDate.getDate();

    //Days in the month
    const apportionmentDays = new Date(year, month, 0).getDate();
    //Days left in the month
    const apportionment = apportionmentDays - date + 1;
    //SET Price without IVA
    setPriceNoIva(selectedPlan.finalPrice - selectedPlan.finalPrice * 0.13);

    setApportionmentPrice((priceNoIVA / apportionmentDays) * apportionment);

    setPriceToCharge(
      (apportionmentPrice + apportionmentPrice * 0.13).toFixed(2)
    );
  }, [apportionmentPrice, selectedPlan, priceNoIVA]);

  const renderList = () => {
    return selectedPlan.data.map((data) => (
      <Grid container item md={12}>
        <Grid item md={8}>
          <Typography
            textAlign={"left"}
            variant="body1"
            sx={{ fontSize: "0.8rem !important" }}
            key={data.id}
          >
            {data.description}
          </Typography>
        </Grid>
        <Grid item md={4}>
          <Typography variant="body2" key={data.id} textAlign={"right"}>
            {data.value}
          </Typography>
        </Grid>
      </Grid>
    ));
  };

  const formik = useFormik({
    initialValues: {
      email: data?.email,
      price: selectedPlan.price ?? "0",
      acceptTerms: false,
      billNumber: 0,
      apportionmentPrice: 0,
    },
    enableReinitialize: true,

    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      const timestampBirthday = new Date(data.birthday);

      const userData = {
        name: data.name,
        lastname: data.lastname,
        secondLastname: data.secondLastname ?? "",
        email: data.email ?? "",
        birthday: timestampBirthday,
        civilState: data.civilState ?? "",
        gender: data.gender ?? "",
        idType: data.nationality ?? "",
        address: data.address ?? "",
        province: data.province ?? "",
        canton: data.canton ?? "",
        district: data.district ?? "",
        zipCode: data.zipCode ?? "",
        device: data.device ?? "",
        personalId: data.personalId ?? "",
        phone: data.phone ?? "",
      };

      const requestData = {
        user: userData,
        plan: selectedPlan,
        planDuration: data.planDuration,
        finalPrice: apportionmentPrice,
        billNumber: 0,
        associationId,
      };

      const confirmResult = await Swal.fire({
        title: "Confirmar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, agregar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });

      if (confirmResult.isConfirmed) {
        const loadingSwal = Swal.fire({
          title: "Guardando...",

          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          const res = await activateUserAccUser(requestData);

          if (res.ok) {
            loadingSwal.close();
            MySwal.fire({
              title: `Solicitud enviada`,
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "OK",
              cancelButtonText: "Cancelar",
            });
            setLinkAvailable(false);
          } else {
            loadingSwal.close();

            MySwal.fire("Error", "Hubo un error al crear el usuario", "error");
          }
        } catch (error) {
          console.log(error);
          loadingSwal.close();

          MySwal.fire("Error", "Hubo un error al crear el usuario", "error");
        }
      }
    },
  });
  const renderContent = () => {
    if (isMobile) {
      return (
        <Grid item xs={12} sm={12} md={12} mb={3}>
          <FormControl fullWidth>
            <Box
              ref={scrollContainerRef} // Attach the ref to the scroll container
              sx={{
                padding: "8px",
                maxHeight: "200px", // Set the desired max height for the box
                overflowY: "auto",
                borderRadius: "18px",
                borderWidth: "2px !important",
                border: "solid",
                borderColor: "#ECECEE",
              }}
              onTouchMove={(e) => {
                e.preventDefault();

                const { scrollTop, scrollHeight, clientHeight } = e.target;
                const isAtBottom = scrollTop + clientHeight === scrollHeight;

                // Set the checkbox state based on whether the user is at the bottom
                formik.setFieldValue("acceptTerms", isAtBottom);
                setReadTerms(isAtBottom);
              }}
            >
              <Terms />
            </Box>
          </FormControl>
        </Grid>
      );
    }
    return (
      <Grid item xs={12} sm={12} md={12} mb={3}>
        <FormControl fullWidth>
          <Box
            ref={scrollContainerRef} // Attach the ref to the scroll container
            sx={{
              padding: "8px",
              maxHeight: "200px", // Set the desired max height for the box
              overflowY: "auto",
              borderRadius: "18px",
              borderWidth: "2px !important",
              border: "solid",
              borderColor: "#ECECEE",
            }}
            onScroll={(e) => {
              const { scrollTop, scrollHeight, clientHeight } = e.target;
              const isAtBottom = scrollTop + clientHeight === scrollHeight; // Check if the scroll position is at the bottom

              // Set the checkbox state based on whether the user is at the bottom
              formik.setFieldValue("acceptTerms", isAtBottom);
              setReadTerms(isAtBottom);
            }}
          >
            <Terms />
          </Box>
        </FormControl>
      </Grid>
    );
  };

  if (loading) {
    return (
      <Box sx={styles.formContainer}>
        <Box sx={[styles.plansCard, { mt: 0, mb: 2 }]}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={styles.formContainerAcceptance}>
      <Box sx={[styles.plansCard, { mt: 0, mb: 2 }]}>
        <Grid container columnGap={2} justifyContent={"flex-start"} mb={2}>
          <Grid item xs={12} sm={12} md={12}>
            <FormControl fullWidth>
              <TextField
                label="Nombre"
                variant="outlined"
                value={data?.name}
                InputProps={{
                  readOnly: true,
                  pointerEvents: "none",
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container columnGap={0} justifyContent={"flex-start"} mb={4}>
          <Grid item xs={12} sm={12} md={12}>
            <FormControl fullWidth>
              <TextField
                label="Correo Electrónico"
                variant="outlined"
                value={data?.email}
                InputProps={{
                  readOnly: true,
                  pointerEvents: "none",
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={styles.formTitle} mb={4}>
          <Typography
            variant="h7"
            sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
          >
            Resumen
          </Typography>
        </Box>
        <Grid container columnGap={2} justifyContent={"space-between"}>
          <Grid item md={12} container mb={3} sx={styles.summaryCard}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",

                flexDirection: "column",
              }}
            >
              <Typography variant="h6">{selectedPlan.subTier}</Typography>
              <Typography variant="body1">
                Código: {selectedPlan.code}
              </Typography>

              <Grid
                container
                sx={{
                  margin: "0 auto",
                  mt: 2,

                  pt: 1,
                  pb: 1,
                }}
              >
                <Box mb={1}>
                  <Typography variant="body1">Detalles del Plan</Typography>
                </Box>
                {renderList()}
              </Grid>
            </Box>
          </Grid>
          <Grid item md={12} container mb={3} sx={styles.summaryCard}>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  label="Precio"
                  variant="standard"
                  value={selectedPlan.finalPrice}
                  InputProps={{
                    readOnly: true,
                    pointerEvents: "none",
                  }}
                />
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  label="Prorateo"
                  variant="standard"
                  value={450}
                  disabled
                />
              </FormControl>
            </Grid> */}
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  label="Prima a pagar"
                  variant="standard"
                  value={priceToCharge}
                  id="ApportionmentPrice"
                  InputProps={{
                    readOnly: true,
                  }}
                  name="ApportionmentPrice"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <Typography variant="body2">
                  Este plan entrara en vigencia el dia y hora en que la
                  asociacion acepte esta solicitud. Al activarse este plan
                  autorizas su recurrencia mensual el primer día del mes.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* @ND ROW */}

        <Grid container spacing={2} mb={3} mt={2}>
          {renderContent()}
          <Grid item xs={12} sm={12} md={12}>
            <FormControl component="fieldset">
              <FormControlLabel
                value="acceptTerms"
                label="Acepto términos y condiciones"
                labelPlacement="end"
                disabled={!readTerms}
                control={
                  <Checkbox
                    name="acceptTerms"
                    checked={formik.values.acceptTerms}
                    onChange={formik.handleChange}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        sx={styles.buttonsContainer}
        justifyContent={"flex-end"}
        direction="row"
      >
        <Grid item>
          <ASbutton
            onClick={() => handleBack()}
            type="submit"
            backgroundColor={"grey"}
            color={"white"}
            buttonText={"Atrás"}
          />
        </Grid>
        <Grid item>
          <Box ml={2}>
            <ASbutton
              onClick={formik.handleSubmit}
              backgroundColor={theme.palette.primary.main}
              color={"white"}
              disabled={formik.values.acceptTerms === false}
              buttonText={"Finalizar"}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
