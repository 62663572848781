import jwtDecode from "jwt-decode";
import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { styles } from "../../styles/styles";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField"; // Import FilePond image preview styles

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  activateUser,
  getCodeFromFb,
  getUserByUserRef,
  updateCodeLink,
} from "../../store/actions/users";

import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { refreshToken } from "../../store/actions/auth";
import { ASbutton } from "../buttons/ASbutton";

const MySwal = withReactContent(Swal);

export const GenerateReview = ({
  apportionmentPrice,
  setApportionmentPrice,
  data,
  selectedPlan,
  userisActive,
  userHasCode,
}) => {
  const theme = useTheme();
  const { idToken } = useSelector((state) => state.auth);
  const [priceNoIVA, setPriceNoIva] = useState(0);
  const [priceToCharge, setPriceToCharge] = useState(0);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
  });
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const overallDate = new Date();
    const year = overallDate.getFullYear();
    const month = overallDate.getMonth() + 1;
    const date = overallDate.getDate();

    //Days in the month
    const apportionmentDays = new Date(year, month, 0).getDate();
    //Days left in the month
    const apportionment = apportionmentDays - date + 1;
    //SET Price without IVA
    setPriceNoIva(selectedPlan.finalPrice - selectedPlan.finalPrice * 0.13);

    setApportionmentPrice((priceNoIVA / apportionmentDays) * apportionment);

    setPriceToCharge(
      (apportionmentPrice + apportionmentPrice * 0.13).toFixed(2)
    );
  }, [apportionmentPrice, selectedPlan, priceNoIVA]);

  const renderList = () => {
    if (!selectedPlan.data) {
      // Data is not available, show loading indicator
      return (
        <Grid container item md={12}>
          <CircularProgress />
        </Grid>
      );
    }

    return selectedPlan.data.map((data, index) => (
      <Grid container item md={12} key={index}>
        <Grid item md={8}>
          <Typography
            textAlign={"left"}
            variant="body1"
            sx={{ fontSize: "0.8rem !important" }}
          >
            {data?.description}
          </Typography>
        </Grid>
        <Grid item md={4}>
          <Typography variant="body2" key={data.id} textAlign={"right"}>
            {data?.value}
          </Typography>
        </Grid>
      </Grid>
    ));
  };

  const formik = useFormik({
    initialValues: {
      email: data?.email,
      price: selectedPlan.price ?? "0",
      acceptTerms: false,
      billNumber: 0,
      apportionmentPrice: 0,
    },
    enableReinitialize: true,
  });

  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };

  if (!selectedPlan) {
    return (
      <>
        <Box sx={styles.formTitle} mb={2}>
          <Typography
            variant="h7"
            sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
          >
            Resumen
          </Typography>
        </Box>
        <Grid
          container
          columnGap={2}
          justifyContent={"flex-start"}
          sx={{ position: "relative" }}
        />
        <Grid item md={12} container mb={3} sx={styles.summaryCard2}>
          {(userisActive || userHasCode) && (
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                background: "rgba(0, 0, 0, 0.7)",
                width: "100%",
                height: "100%",
                zIndex: 999,
                borderRadius: "18px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 19.5,
                }}
              >
                <Typography
                  variant="h7"
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    textAlign: "center",
                    margin: "1em auto",
                    pr: 0.8,
                    pl: 0.8,
                  }}
                >
                  {userHasCode
                    ? "Usuario ya tiene código activo"
                    : "Usuario ya se encuentra activo, contacte a soporte"}
                </Typography>
              </Box>
            </div>
          )}
          <Box sx={[styles.plansCard, { mt: 0, mb: 2 }]}>--</Box>
        </Grid>

        {/* @ND ROW */}
      </>
    );
  }

  return (
    <Box>
      <Box sx={styles.formTitle} mb={2}>
        <Typography
          variant="h7"
          sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
        >
          Resumen
        </Typography>
      </Box>

      <Grid container columnGap={2} justifyContent={"flex-start"}>
        <Grid item md={12} container mb={3} sx={styles.summaryCard2}>
          {userisActive ||
            (userHasCode && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  background: "rgba(0, 0, 0, 0.7)",
                  width: "100%",
                  height: "100%",
                  zIndex: 999,
                  borderRadius: "18px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 18,
                  }}
                >
                  <Typography
                    variant="h7"
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      textAlign: "center",
                      margin: "1em auto",
                      pr: 0.8,
                      pl: 0.8,
                    }}
                  >
                    {userHasCode
                      ? "Usuario ya tiene código activo "
                      : "Usuario ya se encuentra activo , contacte a soporte"}
                  </Typography>
                </Box>
              </div>
            ))}
          <Grid item xs={12} sm={12} md={12}>
            <FormControl fullWidth>
              <TextField
                label="Precio Mensual"
                variant="standard"
                value={selectedPlan?.finalPrice}
                InputProps={{
                  readOnly: true,
                  pointerEvents: "none",
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <FormControl fullWidth>
              <TextField
                label="Prima a pagar"
                variant="standard"
                value={priceToCharge}
                id="ApportionmentPrice"
                InputProps={{
                  readOnly: true,
                }}
                name="ApportionmentPrice"
              />
            </FormControl>
          </Grid>
          <Grid item>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={
                  {
                    // Your styles for the bottom text
                  }
                }
              >
                El pago de el plan {selectedPlan.subTier} fue ajustado a la
                fecha {new Date().getDate()}/{new Date().getMonth() + 1}/
                {new Date().getFullYear()} . Al activar este plan el usuario
                autoriza su recurrencia mensual el primer día del mes en curso
                por un total de ₡{selectedPlan.finalPrice} I.V.A.I. mensuales.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/* @ND ROW */}
    </Box>
  );
};
