import { Box, Container } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { styles } from "../../styles/styles";
import { Typography } from "@mui/material";
import { UsersTable } from "../../components/users/UsersTable";
import { ExclusionsTable } from "../../components/exclusions/ExclusionsTable";
import { PendingExclusionsTable } from "../../components/exclusions/PendingExclusionsTable";

const Exclusion = () => {
  const dispatch = useDispatch();
  const { association } = useSelector((state) => state.association);
  const { adminData } = useSelector((state) => state.auth);
  const associationId = adminData.associationInfo.associationId;
  return (
    <Container maxWidth={"xxl"}>
      <Box sx={styles.formCard}>
        <Box mt={4}>
          <Typography variant="h5" sx={styles.title}>
            Exclusiones
          </Typography>
        </Box>

        <ExclusionsTable
          associationId={associationId}
          associationCode={association.code}
        />
        <PendingExclusionsTable
          associationId={associationId}
          associationCode={association.code}
        />
      </Box>
    </Container>
  );
};

export default Exclusion;
