import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAssociationProducts } from "../../store/actions/products";
import { ASbutton } from "../buttons/ASbutton";
import { styles } from "../../styles/styles";
import { useFormik } from "formik";
import { MdNotificationImportant } from "react-icons/md";

const GeneratePlans = ({
  selectedPlan,
  setSelectedPlan,
  associationId,
  userisActive,
  handleWhatsappLink,
  noData,
  userHasCode,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);
  const [productsFromDB, setProductsFromDB] = useState([]);
  const [loading, setloading] = useState(false);
  const isFirstUse = useRef(true);
  const [noDataAvailable, setNoDataAvailable] = useState(false);

  const getProductsFromFB = useCallback(() => {
    try {
      dispatch(getAssociationProducts(associationId));
    } catch (error) {
      console.log(error);
    }
    // console.log(products);
  }, [products]);

  const dummyPlans = [
    { id: 1, subTier: "Basic Plan", code: "BASIC123", price: 10.99 },
    { id: 2, subTier: "Standard Plan", code: "STANDARD456", price: 19.99 },
    { id: 3, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 4, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 5, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 6, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 7, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 8, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 9, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 10, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },

    // ... add more dummy plans
  ];

  const duration = [
    {
      value: 12,
      label: "12 meses",
    },
    {
      value: 6,
      label: "6 meses",
    },
    {
      value: 3,
      label: "3 meses",
    },
  ];

  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getProductsFromFB();
      isFirstUse.current = false;
    } else if (products?.length > 0) {
      setloading(false);
      setProductsFromDB(products);
    } else {
      const timeout = setTimeout(() => {
        setNoDataAvailable(true);
      }, 4200);

      return () => clearTimeout(timeout);
    }
    if (noDataAvailable) {
      setloading(false);
    }
  }, [getProductsFromFB, productsFromDB]);

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan.id === selectedPlan ? null : plan);
  };

  const renderCard = (plan, index) => (
    <Grid item xs={12} sm={6} md={6} lg={8} key={index}>
      <Paper
        elevation={selectedPlan.id === plan.id ? 5 : 2} // Add elevated effect for selected card
        style={{
          padding: "16px",
          backgroundColor:
            userisActive || noData || userHasCode
              ? "#F6F6F6"
              : selectedPlan.id === plan.id
              ? "#FAFAFB"
              : "#F6F6F6", // Change background color for selected card
          cursor:
            userisActive || noData || userHasCode ? "not-allowed" : "pointer",
          borderRadius: "18px",
          borderWidth: "1px ",
          border: selectedPlan.id === plan.id ? "solid" : "solid",
          borderColor:
            userisActive || noData || userHasCode
              ? "white"
              : selectedPlan.id === plan.id
              ? theme.palette.primary.main
              : "white",
        }}
        onClick={
          userisActive || noData || userHasCode
            ? () => {}
            : () => handlePlanSelect(plan)
        }
      >
        <Typography variant="h6" gutterBottom>
          {plan.subTier}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Código {plan.code}
        </Typography>
      </Paper>
    </Grid>
  );

  const renderGrid = () => {
    if (loading && !noDataAvailable) {
      return (
        <Grid container rowGap={2} sx={[styles.planSelectionLoading]}>
          <CircularProgress />
        </Grid>
      );
    }
    if (noDataAvailable) {
      return (
        <Grid
          container
          rowGap={2}
          sx={[styles.planSelectionLoading, { padding: 2 }]}
        >
          <Typography>No Hay productos disponibles</Typography>
        </Grid>
      );
    }

    return (
      <Grid
        container
        rowGap={2}
        sx={
          userisActive
            ? [
                styles.planSelection2,
                { padding: 2, mb: 2.5, overflow: "hidden" },
              ]
            : [styles.planSelection2, { padding: 2, mb: 2.5 }]
        }
        justifyContent={
          productsFromDB.length > 2 ? "space-around" : "flex-start"
        }
      >
        {productsFromDB.map((plan) => renderCard(plan))}
        {(userisActive || userHasCode) && (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              background: "rgba(0, 0, 0, 0.7)",
              width: "100%",
              height: "100%",
              borderRadius: "18px",
              zIndex: 999,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <Typography
                variant="h7"
                sx={{
                  fontWeight: "bold",
                  color: "white",
                  textAlign: "center",
                  margin: "1em auto",
                }}
              >
                {userHasCode
                  ? "Usuario ya tiene código activo"
                  : "Usuario ya se encuentra activo, contacte a soporte"}
              </Typography>
            </Box>
          </div>
        )}
      </Grid>
    );
  };

  return (
    <>
      <Box sx={styles.formTitle} mb={2}>
        <Typography
          variant="h7"
          sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
        >
          Selecciona un plan
        </Typography>
      </Box>
      {renderGrid()}
    </>
  );
};

export default GeneratePlans;
