import firebase from "firebase/compat/app";

import React, { useCallback, useEffect, useState } from "react";
import { Box, Container, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { styles } from "../../styles/styles";

import dayjs from "dayjs";

import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { AccForm } from "../../components/acceptance/AccForm";
import { AccSummary } from "../../components/acceptance/AccSummary";
import { AccPlans } from "../../components/acceptance/AccPlans";
import { getDataFromAssociation } from "../../store/actions/association";

const MySwal = withReactContent(Swal);

export const AcceptanceUser = () => {
  const theme = useTheme();
  const { association } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [apportionmentPrice, setApportionmentPrice] = useState(0);
  const [linkAvailable, setLinkAvailable] = useState(true);
  //IMAGE
  const [imageUpload, setImageUpload] = useState("");
  const [image, setImage] = useState("");
  const [fileUrl, setFileUrl] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [associationInfo, setAssociationInfo] = useState([]);

  const getInfo = useCallback(async () => {
    try {
      const associationFromFB = await getDataFromAssociation(association);
      setAssociationInfo(associationFromFB.associationPlans);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const trackEmailLinkClick = () => {
    firebase.analytics().logEvent("association_email_click", {
      link_type: "email",
    });
  };

  useEffect(() => {
    trackEmailLinkClick();
  }, []);

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setActiveStep(0);
    setData({
      name: "",
      lastname: "",
      secondLastname: "",
      phone: "",
      email: "",
      birthday: dayjs("2005-01-01"),
      civilState: "",
      gender: "",
      nationality: "",
      personalId: "",
      address: "",
      province: "",
      canton: "",
      district: "",
      zipCode: "",
      billNumber: 0,
      price: 0,
      planDuration: "",
      apportionmentPrice: 0,
    });
  };

  const [data, setData] = useState({
    name: "",
    lastname: "",
    secondLastname: "",
    phone: "",
    email: "",
    birthday: dayjs("2005-01-01"),
    civilState: "",
    gender: "",
    nationality: "",
    personalId: "",
    address: "",
    province: "",
    canton: "",
    district: "",
    zipCode: "",
    billNumber: 0,
    price: 0,
    planDuration: "",
    apportionmentPrice: 0,
  });
  // const handleCloseDialog = () => {
  //   setDialogOpen(false);
  //   setActiveStep(0);
  //   setData({
  //     name: "",
  //     lastname: "",
  //     lastname2: "",
  //     phone: "",
  //     email: "",
  //     birthday: dayjs("2005-01-01"),
  //     civilState: "",
  //     gender: "",
  //     nationality: "",
  //     personalId: "",
  //     address: "",
  //     province: "",
  //     canton: "",
  //     district: "",
  //     zipCode: "",
  //     billNumber: "",
  //     price: 0,
  //     planDuration: "",
  //     apportionmentPrice: 0,
  //   });
  // };

  const handleTabChange = (event, newValue) => {
    setActiveStep(newValue);
  };
  const handleRegister = () => {
    console.log(data);
  };

  const steps = [
    "Información del Asociado",
    "Tipo de Plan",
    "Resumen del Plan",
  ];

  const handleNext = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));

    // console.log("Data after handleNext:", { ...data, ...newData });

    // if (activeStep === 3) {
    //   handleActivation(newData);

    //   return;
    // }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  if (linkAvailable === false) {
    return (
      <Container
        maxWidth="xs"
        id="acceptanceContainer"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 5,
        }}
      >
        <Box
          sx={{
            // width: "80%",
            // maxWidth: 350,
            minHeight: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            margin: "1em auto 4em auto",
            borderRadius: "18px",
            borderWidth: "2px !important",
            border: "solid",
            borderColor: "#ECECEE",
            backgroundColor: "#F9FBFF",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;",
            p: 2,
          }}
        >
          <Box mt={2} mb={4}>
            <Typography
              variant="h5"
              color="warning"
              sx={[
                styles.title,
                { textAlign: "center", color: theme.palette.primary.main },
              ]}
            >
              Tu solicitud de inclusión ha sido enviada
            </Typography>
          </Box>
        </Box>
      </Container>
    );
  }
  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          // width: "80%",
          // maxWidth: 350,

          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "1em auto 4em auto",
          borderRadius: "18px",
          borderWidth: "2px !important",
          border: "solid",
          borderColor: "#ECECEE",
          backgroundColor: "#F9FBFF",
          boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;",
          p: 2,
        }}
      >
        <Tabs
          value={activeStep}
          onChange={handleTabChange}
          indicatorColor="primary"
          sx={{ marginTop: 1, marginLeft: 1 }}
        >
          {steps.map((label, index) => (
            <Tab key={index} label={label} disabled={index > activeStep} />
          ))}
        </Tabs>

        <Box>
          {activeStep === 0 && (
            <AccForm
              handleNext={handleNext}
              handleBack={handleBack}
              data={data}
              activeStep={activeStep}
              loadUrl={setFileUrl}
              imageUpload={imageUpload}
              setImageUpload={setImageUpload}
              setImage={setImage}
              image={image}
            />
          )}

          {activeStep === 1 && (
            <AccPlans
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              associationId={association}
              associationInfo={associationInfo}
              apportionmentPrice={apportionmentPrice}
              setApportionmentPrice={setApportionmentPrice}
            />
          )}

          {activeStep === 2 && (
            <AccSummary
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
              selectedPlan={selectedPlan}
              apportionmentPrice={apportionmentPrice}
              setApportionmentPrice={setApportionmentPrice}
              data={data}
              associationInfo={associationInfo}
              associationId={association}
              handleOpenDialog={handleOpenDialog}
              dialogOpen={dialogOpen}
              handleCloseDialog={handleCloseDialog}
              setLinkAvailable={setLinkAvailable}
            />
          )}
        </Box>
      </Box>
    </Container>
  );
};
