import { Container } from "@mui/material";
import "./coin-loader.scss";

export const CoinLoader = ({ size, fullScreen }) => {
  return (
    <Container
      maxWidth={false}
      sx={{
        display: "flex",
        justfiyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        flex: 1,
      }}
    >
      <div
        className="bg-loader"
        style={{
          width: `${size.width}px`,
          height: `${size.height}px`,
          margin: "0 auto",
        }}
      ></div>
    </Container>
  );
};
