import {
  GET_ASSOCIATION,
  IS_LOADING_ASSOCIATION,
  GET_ASSOCIATION_CODES,
  GET_NOTIFICATIONS,
} from "../actions/association";

const INITIAL_STATE = {
  association: [],
  isLoading: false,
  codes: [],
  notifications: [],
};

const associationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ASSOCIATION:
      return {
        ...state,
        association: action.payload.association,
        // displayName: action.payload.displayName,
      };
    case GET_ASSOCIATION_CODES:
      return {
        ...state,
        codes: action.payload.codes,
        // displayName: action.payload.displayName,
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.notifications,
        // displayName: action.payload.displayName,
      };

    case IS_LOADING_ASSOCIATION:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };

    default:
      return state;
  }
};

export default associationReducer;
