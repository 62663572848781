import jwtDecode from "jwt-decode";
import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { styles } from "../../styles/styles";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField"; // Import FilePond image preview styles

import { ASbutton } from "../../components/buttons/ASbutton";
import { MdNotificationImportant } from "react-icons/md";

import { useFormik } from "formik";
import * as Yup from "yup";

import QRCode from "react-qr-code";
import {
  activateUser,
  getCodeFromFb,
  getUserByUserRef,
  updateCodeLink,
} from "../../store/actions/users";
import { InclusionModal } from "./InclusionModal";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { refreshToken } from "../../store/actions/auth";

const MySwal = withReactContent(Swal);

export const B2bSummary = ({
  handleCancel,
  apportionmentPrice,
  setApportionmentPrice,
  userId,
  code,
  qrCodeUrl,
  associationId,
  handleCloseDialog,
  dialogOpen,
  handleOpenDialog,
}) => {
  const theme = useTheme();
  const { idToken } = useSelector((state) => state.auth);
  const [priceNoIVA, setPriceNoIva] = useState(0);
  const [priceToCharge, setPriceToCharge] = useState(0);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
  });
  const [loading, setloading] = useState(true);

  const [userData, setUserData] = useState();
  const [codeData, setCodeData] = useState();
  const [selectedPlan, setSelectedPlan] = useState([]);
  const extractedCode = code.split("-")[1]; // Extract the code part after "-"
  // const userId = "6hBHGxSxajTBsLWsk55rXvUxNqo2";
  // const code = "A2G3F6ED15";
  useEffect(() => {
    const unsubscribe = getCodeFromFb(extractedCode, associationId, (data) => {
      setCodeData(data);
    });

    return () => {
      unsubscribe();
    };
  }, [code]);

  const getUserFromFB = useCallback(async () => {
    try {
      const dataFromUser = await getUserByUserRef(userId);
      const plan = dataFromUser.planType;

      setUserData(dataFromUser);

      setSelectedPlan(plan);

      setloading(false);
    } catch (error) {
      console.log(error);
    }
  }, [userId]);

  useEffect(() => {
    getUserFromFB();
  }, [getUserFromFB]);

  useEffect(() => {
    const overallDate = new Date();
    const year = overallDate.getFullYear();
    const month = overallDate.getMonth() + 1;
    const date = overallDate.getDate();

    //Days in the month
    const apportionmentDays = new Date(year, month, 0).getDate();
    //Days left in the month
    const apportionment = apportionmentDays - date + 1;
    //SET Price without IVA
    setPriceNoIva(selectedPlan.finalPrice - selectedPlan.finalPrice * 0.13);

    setApportionmentPrice((priceNoIVA / apportionmentDays) * apportionment);

    setPriceToCharge(
      (apportionmentPrice + apportionmentPrice * 0.13).toFixed(2)
    );
  }, [apportionmentPrice, selectedPlan, priceNoIVA]);

  const renderList = () => {
    if (!selectedPlan.data) {
      // Data is not available, show loading indicator
      return (
        <Grid container item md={12}>
          <CircularProgress />
        </Grid>
      );
    }

    return selectedPlan.data.map((data, index) => (
      <Grid container item md={12} key={index}>
        <Grid item md={8}>
          <Typography
            textAlign={"left"}
            variant="body1"
            sx={{ fontSize: "0.8rem !important" }}
          >
            {data?.description}
          </Typography>
        </Grid>
        <Grid item md={4}>
          <Typography variant="body2" key={data.id} textAlign={"right"}>
            {data?.value}
          </Typography>
        </Grid>
      </Grid>
    ));
  };

  const formik = useFormik({
    initialValues: {
      email: userData?.email,
      price: selectedPlan.price ?? "0",
      acceptTerms: false,
      billNumber: 0,
      apportionmentPrice: 0,
    },
    enableReinitialize: true,

    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      const confirmResult = await Swal.fire({
        title: "Confirmar",
        text: "¿Estás seguro de que quieres activar este usuario?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, agregar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });

      if (confirmResult.isConfirmed) {
        const loadingSwal = Swal.fire({
          title: "Guardando...",

          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          const type = "acceptance";
          const billNumber = values.billNumber ?? 0;
          const requestData = {
            userId,
            code: extractedCode,
            associationId,
            type,
          };

          if (codeData) {
            if (isValidToken(idToken)) {
              const res = await activateUser(requestData, idToken);

              if (res.ok) {
                await updateCodeLink(associationId, extractedCode);
                loadingSwal.close();
                handleOpenDialog();
              } else {
                loadingSwal.close();

                MySwal.fire(
                  "Error",
                  "Hubo un error al crear el usuario",
                  "error"
                );
              }
            } else {
              const newToken = await refreshToken();
              const res = await activateUser(requestData, newToken);

              if (res.ok) {
                await updateCodeLink(associationId, extractedCode);
                loadingSwal.close();

                handleOpenDialog();
              } else {
                loadingSwal.close();

                MySwal.fire(
                  "Error",
                  "Hubo un error al crear el usuario",
                  "error"
                );
              }
            }
          }
        } catch (error) {
          console.log(error);
          loadingSwal.close();

          MySwal.fire("Error", "Hubo un error al crear el usuario", "error");
        }
      }
    },
  });

  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };

  if (loading) {
    return (
      <Box sx={styles.formContainer}>
        <Box sx={[styles.plansCard, { mt: 0, mb: 2 }]}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }
  if (!codeData) {
    return (
      <Box sx={styles.formContainer}>
        <Box sx={[styles.plansCard, { mt: 0, mb: 2 }]}>No hay datos</Box>
      </Box>
    );
  }

  return (
    <Box sx={styles.formContainer}>
      <Box sx={[styles.plansCard, { mt: 0, mb: 2 }]}>
        <Grid container columnGap={2} justifyContent={"flex-start"} mb={2}>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Nombre"
                variant="outlined"
                value={userData?.name}
                InputProps={{
                  readOnly: true,
                  pointerEvents: "none",
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container columnGap={0} justifyContent={"flex-start"} mb={4}>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Correo Electrónico"
                variant="outlined"
                value={userData?.email}
                InputProps={{
                  readOnly: true,
                  pointerEvents: "none",
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={styles.formTitle} mb={4}>
          <Typography
            variant="h7"
            sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
          >
            Resumen
          </Typography>
        </Box>
        <Grid container columnGap={2} justifyContent={"space-between"}>
          <Grid item md={5.5} container mb={3} sx={styles.summaryCard}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",

                flexDirection: "column",
              }}
            >
              <Typography variant="h6">{selectedPlan?.subTier}</Typography>
              <Typography variant="body1">
                Código: {selectedPlan?.code}
              </Typography>

              <Grid
                container
                sx={{
                  margin: "0 auto",
                  mt: 2,

                  pt: 1,
                  pb: 1,
                }}
              >
                <Box mb={1}>
                  <Typography variant="body1">Detalles del Plan</Typography>
                </Box>
                {renderList()}
              </Grid>
            </Box>
          </Grid>
          <Grid item md={5.5} container mb={3} sx={styles.summaryCard}>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  label="Precio Mensual"
                  variant="standard"
                  value={selectedPlan?.finalPrice}
                  InputProps={{
                    readOnly: true,
                    pointerEvents: "none",
                  }}
                />
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  label="Prorateo"
                  variant="standard"
                  value={450}
                  disabled
                />
              </FormControl>
            </Grid> */}
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  label="Prima a pagar"
                  variant="standard"
                  value={priceToCharge}
                  id="ApportionmentPrice"
                  InputProps={{
                    readOnly: true,
                  }}
                  name="ApportionmentPrice"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body2"
                  sx={
                    {
                      // Your styles for the bottom text
                    }
                  }
                >
                  El pago de tu plan {selectedPlan.subTier} fue ajustando a la
                  fecha {new Date().getDate()}/{new Date().getMonth() + 1}/
                  {new Date().getFullYear()} . Al activar este plan autorizas su
                  recurrencia mensual el primer día del mes en curso por un
                  total de ₡{selectedPlan.finalPrice} I.V.A.I. mensuales.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* @ND ROW */}

        <Grid
          container
          spacing={2}
          mb={3}
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Grid item xs={12} sm={12} md={4}>
            <FormControl component="fieldset">
              <FormControlLabel
                value="acceptTerms"
                label="El usuario aceptó términos y condiciones"
                labelPlacement="end"
                control={
                  <Checkbox
                    name="acceptTerms"
                    disabled={codeData.statusCode !== 3}
                    checked={codeData.statusCode === 3}
                    onChange={formik.handleChange}
                    color="success"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
              />
            </FormControl>
          </Grid>
          <Grid container spacing={2} mb={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Box mt={3} sx={styles.qrCard}>
                <Box mb={2}>
                  <Typography>Escanee el Código QR </Typography>
                </Box>
                <QRCode value={qrCodeUrl} size={150} />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormControl fullWidth>
              <TextField
                label="Comprobante de Pago"
                variant="outlined"
                value={formik.values.billNumber}
                id="billNumber"
                name="billNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.billNumber && Boolean(formik.errors.billNumber)
                }
                helperText={
                  formik.touched.billNumber && formik.errors.billNumber
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        sx={styles.buttonsContainer}
        justifyContent={"flex-end"}
        direction="row"
      >
        <Grid item>
          <ASbutton
            onClick={handleCancel}
            type="submit"
            disabled
            backgroundColor={"grey"}
            color={"white"}
            buttonText={"Cancelar"}
          />
        </Grid>
        <Grid item>
          <Box ml={2}>
            <ASbutton
              onClick={formik.handleSubmit}
              backgroundColor={theme.palette.primary.main}
              color={"white"}
              disabled={codeData.statusCode !== 3}
              buttonText={"Finalizar"}
            />
          </Box>
        </Grid>
      </Grid>
      {userData && (
        <InclusionModal
          open={dialogOpen}
          onClose={handleCloseDialog}
          name={userData.name}
          lastname={userData.lastname}
          qrCodeUrl={qrCodeUrl}
          code={code}
        />
      )}
    </Box>
  );
};
