import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/analytics";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD_PQrP0LBNN6bP3jZPckLxob4HV4prTvE",
  authDomain: "allsports-69231.firebaseapp.com",
  databaseURL: "https://allsports-69231-default-rtdb.firebaseio.com",
  projectId: "allsports-69231",
  storageBucket: "allsports-69231.appspot.com",
  messagingSenderId: "799750229360",
  appId: "1:799750229360:web:bea4704c2721d14684d3b5",
  measurementId: "G-QY5SWJT34C",
};

firebase.initializeApp(firebaseConfig);

const fsTimestampCreador = firebase.firestore.Timestamp;
const db = firebase.firestore();
const auth = firebase.auth();
const analytics = firebase.analytics();
const storageRef = firebase.storage();

export { auth, db, analytics, fsTimestampCreador, storageRef };
