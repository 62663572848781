import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Container, Grid, useTheme } from "@mui/material";
import "./home.scss";
import { styles } from "../../styles/styles";
import { InfoCard } from "../../components/cards/InfoCard";
import { ProfileCard } from "../../components/cards/ProfileCard";
import { HomeTable } from "../../components/home/HomeTable";
import { InfoCardData } from "../../utils/HomeData";
import { getAssociationData } from "../../store/actions/association";
import { useDispatch, useSelector } from "react-redux";
const Home = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { association } = useSelector((state) => state.association);
  const { adminData } = useSelector((state) => state.auth);

  const isFirstUse = useRef(true);
  const [associationData, setAssociationData] = useState([]);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [loading, setloading] = useState(false);
  const [usersFromDb, setUsersFromDB] = useState([]);
  const associationId = adminData?.associationInfo?.associationId;
  const [activeUsersFromDB, setActiveUsersFromDB] = useState([]);
  const getAssociationFromFB = useCallback(async () => {
    try {
      getAssociationData(associationId);

      setAssociationData(association);
    } catch (error) {
      console.log(error);
    }
  }, [association]);

  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getAssociationFromFB();
      setAssociationData(association);
      isFirstUse.current = false;
    } else if (!association) {
      getAssociationFromFB();
      setloading(false);

      setAssociationData(association);
    }
  }, [getAssociationFromFB]);

  // useEffect(() => {
  //   console.log(association);
  // }, [association]);

  const renderInfoCards = () => {
    return InfoCardData.map((item, index) => (
      <InfoCard
        key={item.id}
        theme={theme}
        item={item}
        index={index}
        associationCode={association.code}
        associationid={associationId}
        usersFromDb={usersFromDb}
        activeUsersFromDB={activeUsersFromDB}
      />
    ));
  };

  return (
    <Container maxWidth="xxl">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: 8,
        }}
      >
        <Grid container sx={styles.infoSection} mt={2}>
          <Grid container item md={5}>
            <ProfileCard data={association} />
          </Grid>
          <Grid
            container
            item
            md={7}
            xs={12}
            alignItems={"center"}
            justifyContent={"space-around"}
            columnSpacing={2}
            sx={{
              [theme.breakpoints.down("md")]: {
                margin: "2em auto !important",
              },
            }}
          >
            {renderInfoCards()}
          </Grid>
        </Grid>
        <Box>
          <HomeTable
            associationId={associationId}
            association={association}
            associationCode={association.codeName}
            setUsersFromDB={setUsersFromDB}
            activeUsersFromDB={activeUsersFromDB}
            setActiveUsersFromDB={setActiveUsersFromDB}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default Home;
