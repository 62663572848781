import { db } from "../firebase/firebase-config";

export const ADMIN_API_URL = `https://us-central1-allsports-69231.cloudfunctions.net/admins`;
export const API_URL = `https://us-central1-allsports-69231.cloudfunctions.net`;

export const getCodeAsyncFromFbOutside = async (codeId) => {
  try {
    const associationsSnapshot = await db.collection("associations").get();
    for (const associationDoc of associationsSnapshot.docs) {
      const codeDocRef = associationDoc.ref.collection("codes").doc(codeId);
      const codeSnapshot = await codeDocRef.get();

      if (codeSnapshot.exists) {
        const codeData = { ...codeSnapshot.data(), codeId: codeSnapshot.id };

        const associationId = associationDoc.id;

        console.log("Code data:", codeData);
        console.log("Association ID:", associationId);

        return { codeData, associationId };
      }
    }

    console.log("No code data found for the given code ID.");
    return null;
  } catch (error) {
    console.error("Error fetching code data:", error);
    return null;
  }
};

export const getUserByUserRefOutside = async (userRef) => {
  try {
    const userSnapshot = await db.collection("users").doc(userRef).get();

    if (!userSnapshot.exists) {
      console.log("User does not exist for ID:", userRef);
      return null;
    }

    const userData = userSnapshot.data();
    const { personalId, phone } = await getPrivateData(userRef);

    return {
      userId: userRef,
      name: userData.name ?? "N/A",
      lastname: userData.lastname ?? "N/A",
      secondLastname: userData.secondLastname ?? "N/A",
      email: userData.email ?? "N/A",
      plan: userData.planType?.subTier ?? "N/A",
      personalId: personalId ?? "N/A",
      phone: phone ?? "N/A",
      ...userData,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const getPrivateData = async (userId) => {
  return await db
    .collection("users")
    .doc(userId)
    .collection("private")
    .doc("data")
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.log("Error getting private data:", error);
      return {};
    });
};
