import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { Md10K } from "react-icons/md";
import { styles } from "../../styles/styles";
import { useSelector } from "react-redux";

export const InfoCard = ({
  item,
  theme,
  index,

  activeUsersFromDB,
}) => {
  const options = { weekday: "long", day: "numeric", month: "long" };
  const formattedDate = new Date().toLocaleDateString("es-CR", options);

  const { codes } = useSelector((state) => state.association);

  const renderData = () => {
    if (index === 0) {
      return (
        <Typography
          variant="h6"
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: "0.7rem !important",
            },
          }}
        >
          {codes ? codes.length : 0}
        </Typography>
      );
    }
    if (index === 1) {
      return (
        <Typography
          variant="h6"
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: "0.7rem !important",
            },
          }}
        >
          {activeUsersFromDB ? activeUsersFromDB.length : 0}
        </Typography>
      );
    }
    if (index === 2) {
      return (
        <Typography
          variant="h6"
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: "0.7rem !important",
            },
          }}
        >
          1
        </Typography>
      );
    }
    if (index === 3) {
      return (
        <Typography
          variant="h6"
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: "0.7rem !important",
            },
          }}
        >
          0
        </Typography>
      );
    }
  };

  return (
    <Grid
      item
      xs={5}
      md={5.5}
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "18px",
        borderWidth: "2px !important",
        border: "solid",
        borderColor: "#ECECEE",
        height: "145px",
        paddingRight: "20px",
        [theme.breakpoints.down("md")]: {
          mt: 2,
        },
      }}
    >
      <Box
        sx={[styles.rowB, { justifyContent: "flex-start", flex: 1 }]}
        pt={0.2}
      >
        <Box
          sx={{
            display: "flex",
            backgroundColor: "#F8F7FF",
            width: "36px",
            height: "36px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "4px",
          }}
          mr={1}
        >
          {item.iconName}

          {/* <Md10K color={theme.palette.primary.main} /> */}
        </Box>
        <Typography
          variant="body1"
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: "0.75rem !important",
            },
          }}
        >
          {item.title}{" "}
        </Typography>
      </Box>
      <Box sx={[styles.rowB, { justifyContent: "space-between", flex: 2 }]}>
        {renderData()}
        <Box
          sx={{
            width: "66px",
            height: "32px",

            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundColor: "#EAF9F4",
            borderRadius: "4px",
          }}
        >
          <Md10K color="#31BF82" />
          <Box>
            <Typography
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: "0.7rem !important",
                },
              }}
            >
              12%
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ borderWidth: "2px" }} />
      <Box sx={[styles.rowB, { justifyContent: "space-between", flex: 1 }]}>
        <Typography
          variant="body2"
          sx={{
            textTransform: "capitalize",
            [theme.breakpoints.down("md")]: {
              fontSize: "0.7rem !important",
            },
          }}
        >{`Update: ${formattedDate}`}</Typography>
      </Box>
    </Grid>
  );
};
