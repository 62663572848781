import { Typography } from "@mui/material";
import React from "react";

export const Terms = () => {
  return (
    <Typography variant="body1" sx={{ fontSize: "12px !important" }}>
      1. Introducción ​ Lea atentamente . Lea atentamente los Términos y
      Condiciones de uso detallados a continuación, ya que establecen de forma
      vinculante y excluyente, las reglas de uso de todos los servicios, sitios
      web y aplicaciones de software desarrolladas y/o proporcionados por ALL
      SPORTS PLUS® incluido el acceso a los mismos. EL HECHO DE ACCEDER, VER O
      UTILIZAR CONTENIDOS, PRODUCTOS O SERVICIOS A TRAVÉS DE LA PLATAFORMA
      DIGITAL DE ALL SPORTS PLUS®, PONE DE MANIFIESTO QUE EL USUARIO COMPRENDE Y
      ACEPTA LAS CONDICIONES GENERALES COMO UN ACUERDO LEGAL VINCULANTE. 2.1.
      Definiciones ​ Para preciso entendimiento de los Términos y Condiciones de
      ALL SPORTS PLUS®, se establecen las siguientes definiciones: ​ ALL SPORTS
      PLUS®: empresa registrada con sede en Costa Rica bajo la razón social ALL
      SPORTS PLUS ASP S.A., con cédula jurídica 3-101-784447 y propietaria de
      las marca registradas: ALL SPORTS PLUS®, ALL SPORTS +® y ALL SPORTS
      V.I.P®. Plataforma digital: conjunto de aplicativos móviles, sitios web,
      softwares y demás recursos digitales utilizados y/o desarrollados por ALL
      SPORTS PLUS en función de su operación comercial y difundidos a través de
      cualquiera canal digital. Usuario: persona física que a través un
      dispositivo inteligente accede total o parcialmente a la plataforma ALL
      SPORTS PLUS® y sus funcionalidades. Cuenta de usuario: cuenta de acceso
      virtual que permite al usuario hacer uso de funcionalidades y consumo
      contenidos de la plataforma digital que requieren subscripción y están
      asociados a un plan. Plan Training: plan de subscripción gratuita sin
      caducidad, asociado a una cuenta de usuario estándar al momento del primer
      registro en la Plataforma Digital. Plan Premium: plan de suscripción paga
      de renovación mensual, asociado a la compra de Plan de Seguro en las
      categorías: BRONCE, PLATA y ORO, que otorga la condición de cliente y
      habilita el uso del beneficio de canje de All Sports Coins por productos y
      servicios así como la activación de los niveles. Plan de seguro: conjunto
      de coberturas del Seguro Médico contratado según la categoría seleccionada
      y detalladas en la sección “SEGURO MÉDICO”. Los montos máximos de
      coberturas varían en función de la categoría contratada. ​​ ​ Beneficios
      complementarios: Red de descuentos, canje de productos y servicios,
      asistencias médicas, artículos promocionales y demás recursos a los que el
      cliente tiene acceso como contratante de un Plan de Seguro y detallados en
      la sección “TIPOS DE BENEFICIOS Y ACCESO”. QR verificador: código QR
      digital exclusivo para clientes de un Plan de Premium. Este permite
      verificar la "Condición de Aplicabilidad" de las coberturas y los
      beneficios complementarios. Condición de Aplicabilidad: Condición
      inherente a un cliente que se encuentra al día con los pagos respectivos
      de su Plan de Seguro y que permite acceder a las coberturas contratadas.
      Sponsor: persona física, empresa, cooperativa, proveedor o cualquier otra
      figura legal que brinde a través de un convenio, beneficios a los usuarios
      de la plataforma digital de ALL SPORTS PLUS®. All Sports Coins: moneda
      digital no bancarizada creada por All Sports Plus y utilizada en la
      Plataforma Digital, que se genera a partir de la conversión de las
      kilocalorías (kcal) quemadas durante actividades físico-deportivas y
      utilizada para canjear por productos y servicios ofertados por los
      Sponsors. Para más detalles consultar el Reglamento sobre la conversión y
      uso de All Sports Coins. En adelante “Coins”. Integración: procedimiento
      donde el usuario deliberadamente vincula a la Plataforma Digital una
      aplicación de Salud y Deporte de terceros con el objetivo de facilitar
      datos de seguimiento físico y deportivo que permitan generar “Coins”. ​ ​
      ​ 2. Condiciones generales ​ Las condiciones generales indicadas a
      continuación regulan el acceso, descarga y uso de cualquier bien, recurso
      y/o herramienta digital desarrollada o provista por ALL SPORTS PLUS® y
      alojadas en dominios y subdominios, aplicativos móviles o softwares
      propiedad del ALL SPORTS PLUS, así como cualquier bien, recurso y/o
      herramienta digital distribuida a través de plataformas digitales de
      terceros y utilizadas por ALL SPORTS PLUS® para la comercialización de sus
      productos e imagen. En adelante: “Contenidos”. ​ ​ 2.1. Edad y requisitos
      de idoneidad ​ Para utilizar los servicios de ALL SPORTS PLUS® y acceder a
      los Contenidos, usted debe tener al menos 18 años, ser capaz de celebrar
      un contrato vinculante con nosotros sin que se lo prohíban las leyes
      pertinentes y residir en un país en el cual esté disponible el servicio.
      Usted se compromete legalmente a que toda la información de registro que
      le entregue a ALL SPORTS PLUS® es verdadera, precisa y completa, y se
      compromete a que esto se mantenga así. Si usted no cumple con los
      requisitos de edad mínimos e idoneidad, no se puede registrar como usuario
      en ALL SPORTS PLUS®. ​ ​ 2.2. Limitaciones y modificaciones del servicio ​
      Realizamos todos los esfuerzos razonables para mantener el Servicio de ALL
      SPORTS PLUS® en funcionamiento y proporcionarle una gran experiencia de
      usuario. Sin embargo, la oferta de nuestro servicio y su disponibilidad
      pueden cambiar de vez en cuando, según las leyes pertinentes y sin
      responsabilidades hacia usted, por ejemplo: ​ Es posible que los Servicios
      de ALL SPORTS PLUS® experimenten interrupciones temporales debido a
      dificultades técnicas, mantenimiento, pruebas o actualizaciones, incluidas
      las necesarias para reflejar los cambios en las leyes y los requisitos
      normativos pertinentes. Nuestro objetivo es evolucionar y mejorar nuestros
      servicios de forma constante, por lo que es posible que modifiquemos la
      Plataforma Digital de ALL SPORTS PLUS® en parte o su totalidad,
      suspendamos o dejemos de proporcionarlo de manera temporal o permanente,
      funciones características, planes de suscripción y ofertas promocionales
      en particular. ALL SPORTS PLUS® no está obligado a mantener ningún
      contenido específico mediante su Plataforma Digital y es posible que se
      eliminen contenidos sin previo aviso. ​ ​ 2.4. Cuentas de usuario ​ Para
      utilizar los servicios de ALL SPORTS PLUS® de forma parcial o total,
      deberá crear una cuenta en la Plataforma Digital. Las credenciales de
      acceso del usuario estarán asociadas al correo electrónico y contraseña
      suministrada durante el proceso de registro. Es deber del cliente mantener
      la confidencialidad de las credenciales de ingreso a su cuenta, ya que el
      usuario acepta la absoluta responsabilidad de todas las actividades que
      ocurran con el uso de su cuenta. La cuenta de usuario le permitirá el
      acceso a la configuración de cuenta, perfil, gestión de métodos de pago,
      así como acceso a los contenidos en aplicativos móviles de ALL SPORTS
      PLUS®. Notifique de forma inmediata a nuestro equipo de servicio de
      atención al cliente si perdió o robaron su usuario y/o contraseña, o si
      piensa que alguien ingresó a su cuenta sin autorización. ALL SPORTS PLUS®
      puede eliminar su usuario, o solicitarle que lo cambie, por cualquier
      motivo. El usuario manifiesta estar de acuerdo con las Normas de Uso de la
      Plataforma Digital: ​​ No podrá compartir ni alentar contenido que difame
      o amenace a otras personas. No podrá realizar declaraciones ni compartir
      material que incite al odio o que denigre la integridad de otras personas
      por su color de piel, orientación sexual, credo religioso, condición de
      discapacidad o su aspecto. No podrá compartir ni alentar contenido que
      discuta actividades ilegales con la intención de cometerlas. No podrá
      compartir ni alentar contenido o declaraciones que inciten o promuevan
      prácticas antideportivas. No podrá compartir ni alentar contenido o
      declaraciones que inciten o promuevan prácticas poco saludables o que
      pongan en riesgo la salud integral de las personas. No podrá compartir ni
      alentar contenido que infrinja la propiedad intelectual de otros,
      incluyendo copyright, marcas registradas o negociar secretos comerciales.
      No podrá compartir ni alentar material que contenga lenguaje o imágenes
      que puedan ser consideradas obscenas o inapropiadas para el ambiente de la
      plataforma. No podrá compartir ni alentar material o declaraciones que
      promuevan la organización de actividades de partidos políticos. No utiliza
      la plataforma para compartir ni alentar ningún tipo de material
      publicitario o cualquier otra forma de solicitud comercial que no haya
      sido autorizado previamente por los moderadores y el equipo comercial de
      ALL SPORTS PLUS®. ALL SPORTS PLUS® se reserva el derecho de cerrar
      cuentas, remover o editar contenidos, según lo considere conveniente para
      el resguardo de las Normas de Uso de la Plataforma Digital. ​ ​ 2.5. Uso
      no autorizado ​ Se prohíbe la colocación comercial de productos propiedad
      de ALL SPORTS PLUS por parte de cualquier persona no autorizada. Salvo que
      se indique explícitamente en un documento oficial debidamente legalizado,
      no se considerará bajo ningún término la compra de un Plan de Seguro
      términos como una licencia de Derechos de Propiedad Intelectual, y el
      cliente acepta no vender, licenciar, alquilar, modificar, distribuir,
      copiar, reproducir, transmitir, exhibir públicamente, realizar
      públicamente, publicar, adaptar, editar o crear trabajos derivados de los
      mismos. En caso de detectar algún tipo de uso no autorizado ALL SPORTS
      PLUS® recurrirá a vías legales correspondientes en la legislación
      costarricense. ​ 2.6. Comprobación antifraude ​ La compra del cliente
      puede ser aplazada para la comprobación antifraude. También puede ser
      suspendida por más tiempo para una investigación más rigurosa, para evitar
      transacciones fraudulentas. ​ ​ 2.7. Sobre el uso de la propiedad ​ Tanto
      el usuario de la plataforma digital como el cliente no podrán declarar
      propiedad intelectual o exclusiva sobre la imagen de la marca o sobre el
      beneficio contratado. La plataforma ALL SPORTS PLUS incluyendo el sitio
      web, productos, derechos de imagen, marcas comerciales, contenidos
      digitales e impresos y materiales promocionales son propiedad exclusiva de
      ALL SPORTS PLUS®. ​ 2.8. Política sobre el uso de datos personales ​ La
      entrega de información es facultativa. El usuario y/o cliente al otorgar
      información personal en cualquiera de los canales de comunicación o
      formularios oficiales de ALL SPORTS PLUS®, autoriza expresamente a ALL
      SPORTS PLUS su uso discrecional, incluyendo la cesión de datos personales
      a terceros, con fines comerciales y operativos. La información
      suministrada por parte del usuario o cliente será manejada con carácter
      confidencial y estará sujeta a todos los parámetros de la Ley 8968 de
      Protección de la Persona frente al Tratamiento de sus Datos Personales.
      Así mismo el usuario tiene derecho a ejercer las acciones que otorga la
      Ley 8968 sobre el uso de sus datos. Para más información consulta nuestra
      Política de Privacidad. ​ ​ 2.9. Descargo de responsabilidad de garantía ​
      ALL SPORTS PLUS® realizará todos los esfuerzos razonables para que el
      servicio de la Plataforma Digital se brinde de acuerdo con las
      especificaciones detallas; sin embargo, ALL SPORTS PLUS®, no otorga
      garantías de usabilidad y disponibilidad de ningún tipo, ya sean
      explícitas, implícitas o legales. ALL SPORTS PLUS® no se responsabilidad
      por daños causados por usuarios que haciendo uso de la Plataforma Digital,
      distribuyan contenido tipo malware u otros componentes dañinos que puedan
      afectar a terceros. Así mismo ALL SPORTS PLUS® no se responsabiliza de las
      afectaciones por contenido de usuarios, productos o servicios
      publicitados, promocionados u ofrecido por terceros, ya sea en la
      Plataforma Digital de ALL SPORTS PLUS® o a través del mismo o cualquier
      sitio web vinculado, ni garantiza o asume ninguna responsabilidad por
      ellos y no se hace responsable de ninguna transacción entre usted y
      cualquier proveedor tercero de lo mencionado anteriormente. Ningún consejo
      o información, ya sea oral o por escrito, que obtenga de ALL SPORTS PLUS®
      creará garantía alguna en nombre de ALL SPORTS PLUS®. El usuario acepta
      que al utilizar la Plataforma Digital de ALL SPORTS PLUS®, puede acceder a
      funciones de filtrado de contenido explícito, pero incluso con estas
      funciones puede encontrar algún contenido explícito creado por usuarios. ​
      ​ 2.10. LIMITACIÓN DE RESPONSABILIDAD​ ​ Usted acepta que la única y
      exclusiva solución para cualquier problema o falta de satisfacción con el
      Servicio de ALL SPORTS PLUS® es desinstalar cualquier software de ALL
      SPORTS PLUS® y dejar de usar el Servicio de ALL SPORTS PLUS®, sujeta a las
      leyes pertinentes. Usted acepta que ALL SPORTS PLUS® no tiene obligación
      ni responsabilidad alguna que provenga de aplicaciones de terceros o su
      contenido, que esté disponible a través del Servicio de ALL SPORTS PLUS® o
      en relación con el mismo y aunque su relación con dichas aplicaciones de
      terceros esté regida por acuerdos separados con dichos terceros, su única
      y exclusiva solución, con respecto a ALL SPORTS PLUS®, por cualquier
      problema o falta de satisfacción con las aplicaciones de terceros o su
      contenido, es desinstalarlas o dejar de utilizarlas. ​ ​ ​ 3. Suscripción
      y planes ​ 4. Plan Training ​ Toda cuenta de usuario registrada por
      primera vez en la Plataforma Digital de ALL SPORTS PLUS® se asociará de
      forma automática y gratuita a un Plan Training, el cual le permitirá al
      usuario acceder a los contenidos. El Plan Training no tiene periodo de
      caducidad y puede ser cancelado únicamente mediante la eliminación de la
      cuenta de usuario de ALL SPORTS PLUS®. ​ 4. Plan Premium ​ El usuario de
      ALL SPORTS PLUS® pude optar por mejorar su Plan Training mediante la
      compra de un Plan Premium en las categorías BRONCE, PLATA Y ORO. Este plan
      de pago y de recurrencia mensual, formaliza la compra de Seguro de Gastos
      Médicos que le permite al cliente titular acceder a coberturas y
      beneficios que se detallan en la sección 3.1.1 Seguro Médico. ​ ​ ​ 4.
      Compras y transacciones ​ 4.1. Compras a través de la plataforma digital ​
      Las transacciones realizadas a través ALL SPORTS PLUS® pueden hacerse
      exclusivamente mediante tarjetas de crédito y débito de entidades
      bancarias. Como parte del proceso de validación de pago y para garantizar
      la legitimidad de las ordenes, ALL SPORTS PLUS® puede llegar a solicitar
      información adicional a los clientes para corroborar y validar la
      legalidad de la transacción y de los métodos de pago suministrados. ​ ​
      4.2. Errores tipográficos ​ De existir un error tipográfico en alguno de
      los precios mostrados, o si la información facilitada de un producto no
      corresponde a las características correctas, haciendo que el cliente tome
      una decisión de compra en base a dicho error, ALL SPORTS PLUS® le
      comunicará la equivocación al usuario de forma oportuna, quien tendrá el
      derecho a rescindir su compra, así mismo ALL SPORTS PLUS® podrá anular la
      orden sin perjuicio para las partes involucradas. ​ 4.3. Política de
      reembolso ​ Si el cliente tiene contratado un Plan de Seguro o producto
      que ALL SPORTS PLUS® deje de proporcionar antes de su periodo de vigencia,
      ALL SPORTS PLUS® reembolsará el monto prepagado correspondientes al
      periodo de la parte no utilizada de su suscripción paga al momento que se
      deje de proporcionar. La información de facturación y de su cuenta deben
      estar actualizadas para que pueda procesarse el reembolso correspondiente.
      En caso de cancelación anticipada del Plan de Seguro, el monto del
      reembolso se calculará sobre el saldo de días restantes del periodo de
      vigencia del Plan de Seguro prepagado, a partir de la notificación al
      cliente de la cancelación de su servicio. ALL SPORTS PLUS® no tiene
      ninguna responsabilidad ante usted, ni ninguna obligación de
      proporcionarle un reembolso en relación con cortes o fallas de internet u
      otros servicios, causados por las acciones de las autoridades
      gubernamentales, locales, de terceros o de eventos que escapen a nuestra
      relación comercial. ​ ​ ​ ​ 5. Seguro Médico ​ Todos los Planes Premium de
      ALL SPORTS PLUS® formalizan la compra de un Plan de Seguro o Seguro Médico
      cuyos tipos y montos máximos de cobertura se establecen según las
      categorías BRONCE, PLATA y ORO, las cuales se detallan en la sección
      COBERTURAS Y BENEFICIOS DEL SEGURO MÉDICO. ​ SEGUROS DEL MAGISTERIO S.A.
      certifica que este seguro opera bajo inclusión automática, motivo por el
      cual usted quedara asegurado a partir de la fecha de inclusión consignada
      al momento de finalizar con éxito el proceso de compra, y según el ámbito
      de cobertura indicado y detallado en las Condiciones Generales del Seguro.
      Por tanto, su Solicitud de Inclusión se convertirá a la vez en un
      Certificado de Seguro de manera automática. ​ El cliente manifiesta que
      previo a la adquisición del Seguro Colectivo de Vida ha recibido la
      información referente a la entidad aseguradora, contrato de seguro,
      incluyendo los beneficios, coberturas y exclusiones de la póliza, período
      de vigencia del seguro, procedimiento para el pago de la prima y demás
      información previa al perfeccionamiento del contrato de seguros, la cual
      estará disponible en este enlace permanentemente. El cliente da fe que
      acepta y entiende las Condiciones Generales del seguro contratado que
      forman parte del Seguro de Accidentes Personales y que pueden ser
      consultadas en este enlace. ​ Así mismo, el cliente manifiesta que se ha
      hecho de su conocimiento que la eficacia de la cobertura está sujeta al
      aporte económico de la prima en los plazos previstos incluidos en el Plan
      de Seguro de ALL SPORTS PLUS. Por su parte, SEGUROS DEL MAGISTERIO S.A. se
      obliga a notificar al cliente, cualquier decisión que tenga por objeto
      rescindir o anular el contrato de seguro, a fin de que esté en posibilidad
      de hacer valer las acciones conducentes a la salvaguarda de sus intereses.
      ​ En el momento de la compra del Plan de Seguro y, por tanto, la toma del
      seguro, se asignarán de forma automática Beneficiario(s) genéricos, los
      cuales serán establecidos en un procedimiento sucesorio correspondiente,
      de igual forma, en caso de que la designación se tome ineficaz o el seguro
      se quede sin Beneficiario(s) por cualquier causa. Posterior a la compra,
      el Asegurado podrá modificar en cualquier momento a los beneficiarios,
      siempre que el Seguro este vigente, mediante una notificación de documento
      escrito y firmado por el Asegurado (se puede explorar hacer un formulario
      en la plataforma para este caso) dirigido a ASEGURADORA SA. Si se designa
      más de un Beneficiario(s) y en tal designación el Asegurado ha dejado de
      especificar sus respectivos intereses asegurables (porcentaje de
      distribución de la indemnización), los Beneficiarios(s) tendrán una
      participación igual. Si cualquier Beneficiario(s). ​ El usuario manifiesta
      expresamente que leyó y aceptó los términos y condiciones establecidos
      para el uso del mismo y el reclamo de los beneficios asociados al Plan de
      Seguro, celebrando así un contrato legal vinculante entre el cliente y ALL
      SPORTS PLUS®. Así mismo el cliente acepta haber conocido con previo
      detalle los precios, cuotas, montos de cobertura y limitaciones relativas
      al Plan de Seguro que contrata y aprueba que ALL SPORTS PLUS® reserve el
      derecho de cambiar los términos, condiciones, precios, cuotas y montos de
      cobertura en cualquier momento y sin previo aviso. El cliente acepta haber
      sido informado que los Términos y Condiciones se encontrará accesibles
      permanentemente en este enlace, comprometiéndose a consultar
      periódicamente los mismo y verificar su vigencia en cada momento. ​ ​ 5.1.
      Coberturas y beneficios del Seguro Médico ​ 5.1.1. Cobertura de gastos
      médicos en caso de accidente. BRONCE, PLATA, ORO. Este beneficio podrá
      reclamarse siempre y cuando el hecho generador sea ocasionado o se derive
      de un accidente. ​ Cobertura por muerte accidental. BRONCE, PLATA, ORO.
      Este beneficio podrá reclamarse siempre y cuando el hecho generador sea
      ocasionado o se derive de un accidente. ​ 5.1.2. Cobertura por incapacidad
      total y permanente en caso de Accidente. BRONCE, PLATA, ORO. Este
      beneficio podrá reclamarse siempre y cuando el hecho generador sea
      ocasionado o se derive de un accidente. ​ 5.1.3. Asistencia Persona
      Activa. El cliente titular de un Plan de Seguro, en cualquiera de sus
      categorías BRONCE, PLATA U ORO, tienen derecho al uso de las siguientes
      asistencias: ​ Medicina General. Visita médica al domicilio en caso de
      necesidad (No requiere autorización previa). Orientación médica
      telefónica. Traslado médico terrestre en ambulancia en caso de accidente.
      Nutrición. Asistencia con especialista en Nutrición. Información y
      orientación nutricional telefónica como consejos nutricionales, guía para
      la preparación de dietas saludables, información sobre contenido calórico
      de los alimentos o tabla de calorías y recetas especiales para diabetes,
      sobrepeso o intolerantes a lactosa. Fisioterapia. Visita de un
      fisioterapeuta al domicilio o consultorio. Orientación telefónica en temas
      relacionados a lesiones o dolores musculares. Traslado médico terrestre en
      ambulancia en caso de una lesión efectuando una actividad física. Apoyo de
      hospitalización. Ver más detalles en este enlace El detalle de las
      condiciones y reglamento de las Asistencias está disponible en este
      enlace. ​ 5.1.4. Beneficios complementarios. El cliente titular, en
      cualquiera de sus categorías BRONCE, PLATA U ORO, tiene derecho a los
      siguientes beneficios complementarios: Canje de productos y artículos
      promocionales. Red de descuentos. Descuentos y ofertas en indumentaria y
      accesorios deportivos, hospedaje, servicios médicos, farmacias, agencias
      de viaje, inscripciones, estética y belleza, centros educativos y de
      formación continua, servicios nutricionales, servicios de
      acondicionamiento físico y gimnasios. Este beneficio está sujeto a
      procedimientos internos, fechas de vigencia y restricciones comerciales
      establecidas previamente por el proveedor y/o emisor del servicio en
      cuestión y pueden ser modificadas posteriormente. ​ ​ 5.2. Uso y reclamo
      de beneficios ​ El acceso y/o reclamo de cualquier Beneficio, otorgado por
      la compra cualquiera de los Planes de Seguro de ALL SPORTS PLUS®, estará
      sujeto a la “condición de aplicabilidad”. La verificación de la “condición
      de aplicabilidad”. El acceso y/o reclamación de cualquier Beneficio,
      estará sujeto a procedimientos internos establecidos por el proveedor y/o
      emisor del servicio en cuestión. Todos los Beneficios podrán ser
      consultados en el Sitio Web del ALL SPORTS PLUS® en este enlace. Para
      estar correctamente informado acerca de los Beneficios que resultan
      aplicables en cada momento, el usuario debe revisar periódicamente la
      sección. ​ ​ 5.3. Obligaciones del cliente ​ Es obligación del cliente
      proveer y actualizar toda la información pertinente y requerida por el
      plan de Plan de Seguro contratado. EL CLIENTE DEBERÁ ESTAR AL DÍA CON EL
      PAGO. Este deberá pagar la cuota mensual dentro de los periodos de
      facturación establecidos en la Solicitud de Plan de Seguro, para lo cual
      se hará el cargo automático en la tarjeta que haya proporcionado. Ante la
      eventualidad de que el cliente se atrase en el pago de la cuota mensual,
      ALL SPORTS PLUS® generará una serie de notificaciones de impago al correo
      electrónico verificado, notificando el atraso en el pago de la cuota
      mensual. ALL SPORTS PLUS® podrá incrementar la cuota producto ante
      impuestos futuros que establezca la Legislación Costarricense con el fin
      de que ALL SPORTS PLUS® reciba la cuota neta anteriormente pactada. ​ ​
      5.4. Transferibilidad de los beneficios ​ Los beneficios asociados a un
      Plan Premium son exclusivamente para el uso y reclamo del titular de la
      cuenta. BAJO NINGÚN TÉRMINO O CONDICIÓN, EL PLAN DE SEGUROS O ALGUNO DE
      LOS BENEFICIOS COMPLEMENTARIOS ASOCIADOS PODRÁ SER TRANSFERIBLE A OTRA
      PERSONA FÍSICA O JURÍDICA. ​ 5.5. Periodo de facturación ​ El periodo de
      facturación corresponde a los primeros 10 días calendario de cada mes. Si
      finalmente su Plan de Seguro expira debido a la no recurrencia del pago,
      dejará de tener derecho a todos los beneficios complementarios y
      asistencias tal y como se establecen en el título “BENEFICIOS”. De
      presentarse problemas operativos con el método de pago suministrado en la
      plataforma asociado a una membresía mensual con recurrencia de pago
      automático, el cliente titular será notificado oportunamente para corregir
      cualquier contratiempo que se presente, con el fin de que este pueda
      mantener activa su condición de cliente dentro de los plazos establecidos
      en los “Términos y Condiciones de Membresías”. ​ ​ 5.6. Duración del Plan
      de Seguro ​ La duración de un Plan Premium BRONE, PLATA y ORO es limitada.
      Su vigencia corresponde a un mes natural, la cual se renovará
      automáticamente cada mes en el periodo de facturación correspondiente. El
      cliente puede solicitar la cancelación de su Plan de Seguro en cualquier
      momento a través del siguiente formulario. El proceso de análisis de la
      solicitud será gestionado en un periodo de 4 a 14 días hábiles por un
      ejecutivo de servicio al cliente designado. Tras ser aprobada dicha
      solicitud de cancelación, el cliente será notificado oportunamente. ​ 5.7.
      Notificaciones formales a los clientes ​ Como cliente recibirá
      notificaciones mediante el correo electrónico validado en la plataforma.
      ALL SPORTS PLUS® se compromete al envío de notificaciones relevantes sobre
      el estado de su Plan de Seguro, con el fin de evitar conflictos y vacíos
      de cobertura a sus clientes. ​ ​ 5.7. Periodo de gracia de las coberturas
      del Plan de Seguro ​ Inmediatamente el cliente tenga conocimiento de la
      ocurrencia de un accidente, daño o pérdida, deberá comunicar los hechos a
      la Autoridad Competente según corresponda y a SEGUROS DEL MAGISTERIO S.A.
      Dicho aviso contendrá información suficiente para poder identificar al
      Asegurado, así como todos los datos que sean razonablemente obtenibles con
      relación al lugar, fecha, hora y circunstancias del accidente, datos de
      los bienes de terceros afectados, los nombres y direcciones de las
      personas lesionadas, y de los testigos disponibles. La notificación a
      SEGUROS DEL MAGISTERIO S.A. podrá realizarse a cualquier hora del día y
      cualquier día del año (24 horas, 365 días), llamando siempre al número
      2503 2772. Igualmente, el asegurado puede presentar en las oficinas de
      SEGUROS DEL MAGISTERIO S.A. su reclamo. ​ ​ ​ 6. Servicio al cliente y
      soporte ​ Para obtener asistencia con preguntas relacionadas con la cuenta
      o los pagos, utilice los recursos del Servicio de atención al cliente
      enumerados en la sección Ayuda en nuestro sitio web.
    </Typography>
  );
};
