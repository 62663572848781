import { Box, Container } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { styles } from "../../styles/styles";
import { Typography } from "@mui/material";
import { UsersTable } from "../../components/users/UsersTable";
import { InclusionsTable } from "../../components/inclusions/InclusionsTable";

const Users = () => {
  const dispatch = useDispatch();

  const { adminData } = useSelector((state) => state.auth);
  const associationId = adminData.associationInfo.associationId;
  return (
    <Container maxWidth={"xxl"}>
      <Box sx={styles.formCard}>
        <Box mt={3}>
          <Typography variant="h5" sx={styles.title}>
            Usuarios
          </Typography>
        </Box>

        <UsersTable associationId={associationId} />
      </Box>
      <Box sx={[styles.formCardSecond, { mb: 8 }]}>
        <Box mt={3}>
          <Typography variant="h5" sx={styles.title}>
            Solicitudes
          </Typography>
        </Box>
        <InclusionsTable associationId={associationId} />
      </Box>
    </Container>
  );
};

export default Users;
