import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MUIDataTable from "mui-datatables";
import { Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import CustomBodyWithSpinner from "../general/CustomBodyWithSpinner";
import { useNavigate } from "react-router-dom";
import { IoRefreshOutline } from "react-icons/io5";
import { IconButton } from "@mui/material";

import {
  getAllUsersFromAssociation,
  getUserByUserRef,
} from "../../store/actions/users";
import { getAssociationCodes } from "../../store/actions/association";
import { MdEdit } from "react-icons/md";
import { UserModal } from "./UserModal";

export const UsersTable = ({ associationId, associationCode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  // const { users } = useSelector((state) => state.users);

  const { codes } = useSelector((state) => state.association);
  const [usersFromDb, setUsersFromDB] = useState([]);
  const isFirstUse = useRef(true);
  const [loading, setloading] = useState(false);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const getUsersFromFB = useCallback(() => {
    try {
      dispatch(getAssociationCodes(associationId));
      setUsersFromDB(codes);
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, associationId]);

  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getUsersFromFB();
      isFirstUse.current = false;
    } else if (codes?.length > 0) {
      setloading(false);
      setUsersFromDB(codes);
    } else {
      const timeout = setTimeout(() => {
        setNoDataAvailable(true);
      }, 4200);

      return () => clearTimeout(timeout);
    }
  }, [getUsersFromFB, codes]);
  // console.log("Codes:", codes);
  // console.log("Loading:", loading);

  const handleRefresh = () => {
    setloading(true);
    getUsersFromFB();
    const timerId = setTimeout(() => {
      setloading(false);
    }, 1000);

    return () => clearTimeout(timerId); // Clear the timer on unmount
  };
  const onClose = () => {
    setEditModalOpen(false);

    setSelectedItem();
  };

  const BodyComponent = useMemo(
    () => (tableProps) => {
      if (noDataAvailable) {
        return (
          <CustomBodyWithSpinner loading={!noDataAvailable} {...tableProps} />
        );
      }

      return (
        <CustomBodyWithSpinner
          loading={loading || codes?.length === 0}
          {...tableProps}
        />
      );
    },
    [codes, loading, noDataAvailable]
  );

  const handleOpenEditModal = async (item) => {
    setEditModalOpen(true);

    setSelectedItem(item);
    console.log(item);
  };

  const columns = [
    // {
    //   name: "name",
    //   label: "Nombre", // Combined column label
    //   options: {
    //     display: true,
    //     filter: false,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       const name = tableMeta.rowData[0]; // Assuming "name" is the first column
    //       const lastname = tableMeta.rowData[1]; // Assuming "lastname" is the second column
    //       return `${name} ${lastname}`;
    //     },
    //   },
    // },

    // {
    //   name: "lastname",
    //   label: "Apellido",
    //   options: {
    //     display: false,
    //     filter: false,
    //   },
    // },
    {
      name: "userEmail",
      label: "Email",
      options: {
        display: true,
        filter: false,
      },
    },
    {
      name: "codeId",
      label: "Código",
      options: {
        display: true,
        filter: false,
      },
    },

    {
      name: "duration",
      label: "Duración",
      options: {
        customBodyRender: (value) => {
          return value ? value + " meses" : "N/A";
        },
        filter: false,
      },
    },
    {
      name: "activationDate",
      label: "Fecha",
      options: {
        customBodyRender: (value) => {
          if (typeof value === "object" && value.seconds && value.nanoseconds) {
            const date = new Date(
              value.seconds * 1000 + value.nanoseconds / 1000000
            );
            const formattedDate = date.toLocaleDateString();
            return formattedDate;
          } else {
            return "-";
          }
        },

        filter: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return value === "active"
            ? "Activo"
            : value === "pending"
            ? "Pendiente"
            : value === "pending request"
            ? "Pendiente de aprobación"
            : value === "pending acceptance"
            ? "Pendiente de aceptar términos"
            : value === "pending user activation"
            ? "Pendiente de activación"
            : "Desactivado";
        },
        filter: true,
      },
    },
    {
      name: "userRef",
      label: "Referencia",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "edit",
      label: "Editar",
      options: {
        empty: true,
        customBodyRender: (_, tableMeta) => {
          const { rowIndex, rowData } = tableMeta;
          const selectUser = usersFromDb.find(
            (user) => user.codeId === rowData[1]
          ); // Assuming "codeId" is in the third column (index 2)

          // console.log("schedules:", schedules);
          // console.log("dataIndex:", dataIndex);
          // console.log("schedule:", schedule);

          const handleEdit = () => {
            console.log(" edit button");
            handleOpenEditModal(selectUser);
          };

          return (
            <IconButton
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: "white",
              }}
              onClick={handleEdit}
            >
              <MdEdit size={14} />
            </IconButton>
          );
        },
      },
      filter: false,
    },
  ];
  const CustomToolbar = ({ onRefresh }) => {
    return (
      <IconButton className="refresh-button" onClick={onRefresh}>
        <IoRefreshOutline size={25} />
      </IconButton>
    );
  };
  const options = {
    filter: true,
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 10,
    onTableChange: () => setUsersFromDB(codes),
    sortOrder: {
      name: "status",
      direction: "asc",
    },
    textLabels: {
      body: {
        noMatch: "No hay usuarios activos",
      },
    },

    customToolbar: () => {
      return <CustomToolbar onRefresh={handleRefresh} />;
    },

    // onRowClick: (rowData) => {
    //   const clickedOwnerId = rowData[0];

    //   handleUserSelect(clickedOwnerId);
    // },
  };
  // if (loading) {
  //   return <div>loading</div>;
  // }
  return (
    <Box mt={4} sx={{ width: "100%" }}>
      {/* <div className="refresh-container">
              <Button className="refresh-button">
                <IoRefreshOutline size={25} onClick={handleRefresh} />
              </Button>
            </div> */}
      <MUIDataTable
        title=""
        data={usersFromDb}
        columns={columns}
        options={options}
        components={{ TableBody: BodyComponent }}
      />
      {selectedItem && (
        <UserModal
          user={selectedItem}
          open={editModalOpen}
          onClose={onClose}
          onRefresh={handleRefresh}
        />
      )}
    </Box>
  );
};
