import jwtDecode from "jwt-decode";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MUIDataTable from "mui-datatables";
import { Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import CustomBodyWithSpinner from "../general/CustomBodyWithSpinner";
import { useNavigate } from "react-router-dom";
import { IoRefreshOutline } from "react-icons/io5";
import { IconButton } from "@mui/material";

import {
  getAllUsersFromAssociation,
  getUserByUserRef,
  removeUser,
} from "../../store/actions/users";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { refreshToken } from "../../store/actions/auth";
import { MdDelete, MdEdit } from "react-icons/md";
import { getAssociationCodes } from "../../store/actions/association";
import { ASbutton } from "../buttons/ASbutton";

const MySwal = withReactContent(Swal);

export const ExclusionsTable = ({ associationId }) => {
  const { idToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { codes } = useSelector((state) => state.association);
  const [usersFromDb, setUsersFromDB] = useState([]);
  const isFirstUse = useRef(true);
  const [loading, setloading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const getUsersFromFB = useCallback(() => {
    try {
      dispatch(getAssociationCodes(associationId));
      const filteredUsers = codes.filter(
        (c) => c.status === "pending" || c.status === "active"
      );
      setUsersFromDB(filteredUsers);
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, associationId]);

  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getUsersFromFB();
      isFirstUse.current = false;
    } else if (codes?.length > 0) {
      setloading(false);
      const filteredUsers = codes.filter(
        (c) => c.status === "pending" || c.status === "active"
      );
      setUsersFromDB(filteredUsers);
    } else {
      const timeout = setTimeout(() => {
        setNoDataAvailable(true);
      }, 4200);

      return () => clearTimeout(timeout);
    }
    if (noDataAvailable) {
      setloading(false);
    }
  }, [getUsersFromFB, codes]);

  const handleOpenExcludeModal = async (item) => {
    // const userData = await getUserByUserRef(item.userRef);
    // const updatedData = { ...item, ...userData };

    MySwal.fire({
      title: "¿Estás seguro?",
      text: `¿Deseas excluir al usuario ${item?.email}  del plan?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      cancelButtonColor: "#d33",
      confirmButtonColor: "#3085d6",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const loadingSwal = MySwal.fire({
          title: "Guardando...",

          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading();
          },
        });
        try {
          const userId = item?.userId;
          const code = item?.codeId;

          const data = {
            userId,
            code,
            associationId,
          };

          if (isValidToken(idToken)) {
            const res = await removeUser(data, idToken);

            if (res.ok) {
              MySwal.fire({
                title: "Usuario pendiente de desactivación",
                text: `Se ha enviado la petición para remover a el usuario ${item?.email} `,
                icon: "success",
              });
              handleRefresh();
            }
          } else {
            const newToken = await refreshToken();
            const res = await removeUser(data, newToken);

            if (res.ok) {
              loadingSwal.close();
              MySwal.fire({
                title: "Usuario pendiente de desactivación",
                text: `Se ha enviado la petición para remover a el usuario ${item?.email}  `,
                icon: "success",
              });
              handleRefresh();
            }
          }
          setSelectedItem();
        } catch (error) {
          console.log(error);
          loadingSwal.close();
          MySwal.fire("Error", "Hubo un error al enviar la solicitud", "error");
        }
      }
    });
  };

  const handleOpenIncludeModal = async (item) => {
    // const userData = await getUserByUserRef(item.userRef);
    // const updatedData = { ...item, ...userData };

    MySwal.fire({
      title: "¿Estás seguro?",
      text: `¿Deseas incluir al usuario ${item?.name}  ${" "} ${
        item?.lastname
      } del plan?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      cancelButtonColor: "#d33",
      confirmButtonColor: "#3085d6",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const loadingSwal = MySwal.fire({
          title: "Guardando...",
          html: '<div class="swal2-spinner"></div>',
          showConfirmButton: false,
          allowOutsideClick: false,
          // didOpen: () => {
          //   MySwal.showLoading();
          // },
        });
        try {
          const userId = item?.userId;
          const code = item?.codeId;
          const type = "exclusionRequest";
          const data = {
            userId,
            code,
            associationId,
            type,
          };

          if (isValidToken(idToken)) {
            const res = await removeUser(data, idToken);

            if (res.ok) {
              MySwal.fire({
                title: "Usuario pendiente de desactivación",
                text: `Se ha enviado la petición para remover a el usuario ${item?.email}  `,
                icon: "success",
              });
            }
          } else {
            const newToken = await refreshToken();
            const res = await removeUser(data, newToken);

            if (res.ok) {
              loadingSwal.close();
              MySwal.fire({
                title: "Usuario pendiente de desactivación",
                text: `Se ha enviado la petición para remover a el usuario ${item?.email}  `,
                icon: "success",
              });
            }
          }
          setSelectedItem();
        } catch (error) {
          console.log(error);
          loadingSwal.close();
          MySwal.fire("Error", "Hubo un error al enviar la solicitud", "error");
        }
      }
    });
  };
  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };

  const handleUserSelect = (url) => {
    navigate(`/user/${url}`);
  };

  const handleRefresh = () => {
    setloading(true);
    getUsersFromFB();
    setTimeout(() => {
      setloading(false);
    }, 1000);
  };

  const BodyComponent = useMemo(
    () => (tableProps) => {
      if (noDataAvailable) {
        return (
          <CustomBodyWithSpinner loading={!noDataAvailable} {...tableProps} />
        );
      }

      return (
        <CustomBodyWithSpinner
          loading={loading || codes?.length === 0}
          {...tableProps}
        />
      );
    },
    [codes, loading, noDataAvailable]
  );
  const columns = [
    // {
    //   name: "name",
    //   label: "Nombre", // Combined column label
    //   options: {
    //     display: true,
    //     filter: false,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       const name = tableMeta.rowData[0]; // Assuming "name" is the first column
    //       const lastname = tableMeta.rowData[1]; // Assuming "lastname" is the second column
    //       return `${name} ${lastname}`;
    //     },
    //   },
    // },

    // {
    //   name: "lastname",
    //   label: "Apellido",
    //   options: {
    //     display: false,
    //     filter: false,
    //   },
    // },
    {
      name: "email",
      label: "Email",
      options: {
        display: true,
        filter: false,
      },
    },
    {
      name: "codeId",
      label: "Código",
      options: {
        display: true,
        filter: false,
      },
    },

    {
      name: "duration",
      label: "Duración",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A";
        },
        filter: false,
      },
    },
    {
      name: "exclusionRequestDate",
      label: "Fecha",
      options: {
        customBodyRender: (value) => {
          if (typeof value === "object" && value.seconds && value.nanoseconds) {
            const date = new Date(
              value.seconds * 1000 + value.nanoseconds / 1000000
            );
            const formattedDate = date.toLocaleDateString();
            return formattedDate;
          } else {
            return "-";
          }
        },

        filter: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return value === "pending" ? "Pendiente" : "Activo";
        },
        filter: false,
      },
    },
    {
      name: "userRef",
      label: "Referencia",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "remove",
      label: "Excluir",
      options: {
        empty: true,
        customBodyRender: (_, tableMeta) => {
          const { rowIndex, rowData } = tableMeta;
          const selectUser = usersFromDb.find(
            (user) => user.codeId === rowData[1]
          ); //
          const handleEdit = () => {
            handleOpenExcludeModal(selectUser);
          };
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <ASbutton
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                }}
                buttonText={"Excluir"}
                onClick={handleEdit}
              />
            </Box>
          );
        },
        filter: false,
        sort: false,
      },
    },
  ];
  const CustomToolbar = ({ onRefresh }) => {
    return (
      <IconButton className="refresh-button" onClick={onRefresh}>
        <IoRefreshOutline size={25} />
      </IconButton>
    );
  };
  const options = {
    filter: true,
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 15,
    textLabels: {
      body: {
        noMatch: "No hay datos disponibles",
      },
    },
    sortOrder: {
      name: "status",
      direction: "asc",
    },
    sortCompare: (order) => (a, b) => {
      const isAsc = order === "asc";
      const statusOrder = ["active", "pending", "deactivated"];

      // Replace "status" with the column name you want to sort by
      const column = "status";

      const aValue = a.data[column];
      const bValue = b.data[column];

      const aIndex = statusOrder.indexOf(aValue);
      const bIndex = statusOrder.indexOf(bValue);

      if (aIndex === bIndex) {
        // If statuses are the same, use default comparison
        return isAsc
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else {
        // Compare based on statusOrder array
        return isAsc ? aIndex - bIndex : bIndex - aIndex;
      }
    },
    customToolbar: () => {
      return <CustomToolbar onRefresh={handleRefresh} />;
    },

    // onRowClick: (rowData) => {
    //   const clickedOwnerId = rowData[0];

    //   handleUserSelect(clickedOwnerId);
    // },
  };
  // if (loading) {
  //   return <div>loading</div>;
  // }
  return (
    <Box mt={6} sx={{ width: "100%" }}>
      {/* <div className="refresh-container">
                <Button className="refresh-button">
                  <IoRefreshOutline size={25} onClick={handleRefresh} />
                </Button>
              </div> */}
      <MUIDataTable
        title="Disponibles para exclusión"
        data={usersFromDb}
        columns={columns}
        options={options}
        components={{ TableBody: BodyComponent }}
      />
    </Box>
  );
};
