import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import loginCover from "./../../assets/forgotpassword.png";
import logo from "./../../assets/logo.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import authErrors from "../../firebase/auth-error-codes";
import { forgotPassword, loginUser } from "../../store/actions/auth";
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error: authenticationError } = useSelector((state) => state.auth);
  const theme = useTheme();
  const validate = Yup.object({
    email: Yup.string().email("Email no valido").required("Email es requerido"),
  });
  const navigateTo = (route) => {
    navigate(route);
  };
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validate,
    onSubmit: async (values, { resetForm }) => {
      const result = await Swal.fire({
        title: "¿Está seguro?",
        text: "¿Quiere restablecer la contraseña?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        try {
          await forgotPassword(values.email);
          resetForm();
          Swal.fire("Éxito", "Correo enviado con éxito", "success");
        } catch (error) {
          Swal.fire("Error", "Hubo un error al enviar el correo", "error");
        }
      }
    },
  });

  const customHandleSubmit = async (email, password) => {
    //event.preventDefault();
    //resetFormFields();
    dispatch(loginUser(email, password));
  };

  // useEffect(() => {
  //   if (authenticationError) {
  //     if (authenticationError.code.includes("user")) {
  //       console.log("user error", errors);
  //       setFieldError("email", authErrors[authenticationError.code].msg);
  //       setFieldTouched();
  //       validateForm();
  //       console.log("user error", errors);
  //     } else {
  //       setFieldError("password", authErrors[authenticationError.code].msg);
  //       setFieldTouched();
  //       validateForm();
  //     }

  //     setSubmitting(false);
  //   }
  // }, [authenticationError, setErrors, setSubmitting]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Container>
        <Grid container>
          <Grid item sm={6}>
            <Box
              sx={{
                width: "100%",
                height: "auto",
                [theme.breakpoints.down("sm")]: {
                  margin: "1em auto 2em auto",
                  width: "50%",
                  height: "auto",
                },
              }}
            >
              <img
                src={loginCover}
                alt="Home"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <form
              onSubmit={formik.handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "65%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "100%",
                }}
                mb={2}
              >
                <Button
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigateTo("/auth/login")}
                >
                  Regresar
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "100%",
                }}
                mb={2}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Olvidaste la contraseña
                </Typography>
                <Typography variant="body2">
                  Ingresa tu dirección de correo electrónico registrada. Te
                  enviaremos un código para restablecer tu contraseña.
                </Typography>
              </Box>
              <FormControl className="mb-3" fullWidth>
                <TextField
                  label="Email"
                  type="email"
                  id="email"
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 4 }}
              >
                Enviar Código
              </Button>
            </form>
            <p className="text-danger mt-3">{}</p>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
