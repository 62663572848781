import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAssociationProducts } from "../../store/actions/products";
import { ASbutton } from "../buttons/ASbutton";
import { styles } from "../../styles/styles";
import PlanModal from "../general/PlanModal";
import { IconButton } from "@mui/material";
import { MdArrowOutward, MdQrCode, MdQuestionMark } from "react-icons/md";

const ActivePlans = ({
  handleNext,
  handleBack,
  selectedPlan,
  setSelectedPlan,
  associationId,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);
  const [productsFromDB, setProductsFromDB] = useState([]);
  const [inactiveProducts, setInactiveProducts] = useState([]);
  const [loading, setloading] = useState(false);
  const isFirstUse = useRef(true);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPlan, setModalPlan] = useState(null);

  const dummyPlans = [
    { id: 1, subTier: "Basic Plan", code: "BASIC123", price: 10.99 },
    { id: 2, subTier: "Standard Plan", code: "STANDARD456", price: 19.99 },
    { id: 3, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 4, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 5, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 6, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 7, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 8, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 9, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 10, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },

    // ... add more dummy plans
  ];

  const getProductsFromFB = useCallback(() => {
    try {
      dispatch(getAssociationProducts(associationId));
    } catch (error) {
      console.log(error);
    }
    // console.log(products);
  }, [products]);

  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getProductsFromFB();

      isFirstUse.current = false;
    } else if (products?.length > 0) {
      setloading(false);
    } else {
      const timeout = setTimeout(() => {
        setNoDataAvailable(true);
      }, 4200);

      return () => clearTimeout(timeout);
    }
    if (noDataAvailable) {
      setloading(false);
    }
  }, [getProductsFromFB, productsFromDB]);

  useEffect(() => {
    if (products) {
      const filteredProducts = products.filter((p) => p.isB2bActive);
      const inactive = products.filter((p) => !p.isB2bActive);
      setProductsFromDB(filteredProducts);
      setInactiveProducts(inactive);
    }
  }, [products]);

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan.id === selectedPlan ? null : plan);
  };
  const next = () => {
    const dataToAdd = {
      planSelected: selectedPlan,
    };

    handleNext(dataToAdd);
  };
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderCard = (plan) => (
    <Grid item xs={10} sm={8} md={5} lg={4} xl={3.2} key={plan.id}>
      <Paper
        elevation={selectedPlan.id === plan.id ? 5 : 3}
        sx={{
          padding: "16px",
          backgroundColor: "#F6F6F6",
          cursor: "pointer",
          borderRadius: "18px",
          position: "relative",
          borderWidth: "2px",
          border: selectedPlan.id === plan.id ? "solid" : "none",
          borderColor: "white",
        }}
        onClick={() => handlePlanSelect(plan)}
      >
        <Typography variant="h6" gutterBottom>
          {plan.subTier}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Código {plan.code}
        </Typography>
        <IconButton
          variant="outlined"
          onClick={() => {
            setModalPlan(plan);
            setIsModalOpen(true);
          }}
          sx={{
            position: "absolute",
            right: -5,
            top: -5,
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <MdQuestionMark color="white" size={12} />
        </IconButton>
      </Paper>
    </Grid>
  );
  const renderGrid = () => {
    if (loading && !noDataAvailable) {
      return (
        <Grid container spacing={2} sx={[styles.planSelectionLoading]}>
          <CircularProgress />
        </Grid>
      );
    }
    if (noDataAvailable || productsFromDB.length === 0) {
      return (
        <Grid container spacing={2} sx={[styles.planSelectionLoading]}>
          <Typography>No Hay productos disponibles</Typography>
        </Grid>
      );
    }

    return (
      <Grid
        container
        spacing={2}
        rowGap={2}
        sx={styles.planSelection}
        justifyContent={
          productsFromDB.length > 2 ? "space-around" : "flex-start"
        }
      >
        {productsFromDB.map((plan) => renderCard(plan))}
      </Grid>
    );
  };
  const renderGridInactive = () => {
    if (loading && !noDataAvailable) {
      return (
        <Grid container spacing={2} sx={[styles.planSelectionLoading]}>
          <CircularProgress />
        </Grid>
      );
    }
    if (noDataAvailable || inactiveProducts.length === 0) {
      return (
        <Grid container spacing={2} sx={[styles.planSelectionLoading]}>
          <Typography>No Hay planes inactivos</Typography>
        </Grid>
      );
    }

    return (
      <Grid
        container
        spacing={2}
        rowGap={2}
        sx={styles.planSelection}
        justifyContent={
          inactiveProducts.length > 2 ? "space-around" : "flex-start"
        }
      >
        {inactiveProducts.map((plan) => renderCard(plan))}
      </Grid>
    );
  };

  return (
    <Box sx={styles.plansCard}>
      <Box sx={styles.formTitle} mb={4}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Planes Disponibles" />
          <Tab label="Planes Inactivos" />
        </Tabs>
      </Box>
      {activeTab === 0 ? renderGrid() : renderGridInactive()}
      {/* <Grid
        container
        sx={styles.buttonsContainer}
        justifyContent={"flex-end"}
        direction="row"
      >
        <Grid item>
          <ASbutton
            onClick={handleBack}
            type="submit"
            backgroundColor={"grey"}
            color={"white"}
            buttonText={"Atrás"}
          />
        </Grid>
        <Grid item>
          <Box ml={2}>
            <ASbutton
              onClick={() => next()}
              backgroundColor={theme.palette.primary.main}
              color={"white"}
              disabled={selectedPlan === ""}
              buttonText={"Siguiente"}
            >
              Submit
            </ASbutton>
          </Box>
        </Grid>
      </Grid> */}
      <PlanModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        plan={modalPlan}
      />
    </Box>
  );
};

export default ActivePlans;
