import { db, storageRef } from "../../firebase/firebase-config";
import firebase from "firebase/compat/app";
import { API_URL } from "../../utils/globals";

export const GET_ASSOCIATION = "GET_ASSOCIATION";
export const GET_ASSOCIATION_CODES = "GET_ASSOCIATION_CODES";
export const IS_LOADING_ASSOCIATION = "IS_LOADING_ASSOCIATION";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
//ACTIONS

export const getAssociationFromDB = (uid) => {
  return db.doc(`admins/${uid}`).get();
};

// export const getAssociationData = (associationId) => {
//   return async (dispatch) => {
//     dispatch(isLoading(true));

//     try {
//       const associationRef = db.collection("associations").doc(associationId);
//       const associationSnapshot = await associationRef.get();

//       if (associationSnapshot.exists) {
//         const associationData = {
//           ...associationSnapshot.data(),
//           associationId: associationId,
//         };
//         dispatch(getAssociation(associationData));
//         dispatch(isLoading(false));
//         return associationData;
//       }
//     } catch (error) {
//       console.error(error);

//       dispatch(isLoading(false));
//     }
//   };
// };

export const getAssociationData = (associationId) => {
  return async (dispatch) => {
    dispatch(isLoading(true));

    try {
      const associationRef = db.collection("associations").doc(associationId);
      const associationSnapshot = await associationRef.get();

      if (associationSnapshot.exists) {
        const associationData = {
          ...associationSnapshot.data(),
          associationId: associationId,
        };

        // const codesSnapshot = await associationRef.collection("codes").get();
        // const codesData = codesSnapshot.docs.map((codeDoc) => {
        //   const codeId = codeDoc.id;
        //   const codeData = codeDoc.data();

        //   return {
        //     codeId: codeId,
        //     ...codeData,
        //   };
        // });

        // associationData.codes = codesData;

        dispatch(getAssociation(associationData));
        dispatch(isLoading(false));
        return associationData;
      }
    } catch (error) {
      console.error(error);

      dispatch(isLoading(false));
    }
  };
};
export const getAssociationCodes = (associationId) => {
  return async (dispatch) => {
    dispatch(isLoading(true));

    try {
      const associationRef = db.collection("associations").doc(associationId);

      const codesSnapshot = await associationRef.collection("codes").get();

      const codesDataPromises = codesSnapshot.docs.map(async (codeDoc) => {
        const codeId = codeDoc.id;
        const userRef = codeDoc.data().userRef;
        const codeData = codeDoc.data();
        // const userSnapshot = await db.collection("users").doc(userRef).get();
        // const userData = userSnapshot.data();
        // const { personalId, phone } = await getPrivateData(userRef);
        return {
          codeId: codeId,
          userId: userRef,
          // personalId: personalId ?? "N/A",
          // phone: phone ?? "N/A",
          // ...userData,
          ...codeData,
        };
      });

      // Await all the promises using Promise.all
      const codesData = await Promise.all(codesDataPromises);

      dispatch(getCodes(codesData)); // Dispatch codes to your Redux store
      dispatch(isLoading(false));
      // console.log(codesData);
      return codesData;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
    }
  };
};

export const getPrivateData = async (userId) => {
  return await db
    .collection("users")
    .doc(userId)
    .collection("private")
    .doc("data")
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.log("Error getting private data:", error);
      return {};
    });
};
// export const getUsersFromAssociationCodes = (associationId) => {
//   return async (dispatch) => {
//     dispatch(isLoading(true));

//     try {
//       const codeSnapshots = await db
//         .collection("associations")
//         .doc(associationId)
//         .collection("codes")
//         .get();

//       const userPromises = codeSnapshots.docs.map(async (codeDoc) => {
//         const userRef = codeDoc.data().userRef;
//         const userSnapshot = await db.collection("users").doc(userRef).get();

//         if (!userSnapshot.exists) {
//           console.log("User does not exist for ID:", userRef);
//           return null;
//         }

//         const userData = userSnapshot.data();
//         const { personalId, phone } = await getPrivateData(userRef);

//         const { activationDate, duration, expirationDate, productId, status } =
//           codeDoc.data();

//         return {
//           id: userRef,
//           name: userData.name ?? "N/A",
//           lastname: userData.lastname ?? "N/A",
//           secondLastname: userData.secondLastname ?? "N/A",
//           email: userData.email ?? "N/A",
//           plan: userData.planType?.subTier ?? "N/A",
//           personalId: personalId ?? "N/A",
//           phone: phone ?? "N/A",
//           activationDate: activationDate ?? "N/A",
//           duration: duration ?? "N/A",
//           expirationDate: expirationDate ?? "N/A",
//           productId: productId ?? "N/A",
//           status: status ?? "N/A",
//           ...userData,
//         };
//       });

//       const usersData = await Promise.all(userPromises);
//       const filteredUsersData = usersData.filter((user) => user !== null); // Filter out null users

//       dispatch(getCodes(filteredUsersData));
//     } catch (error) {
//       console.log(error);
//     }
//   };
// };
export const getDataFromCode = async (code) => {
  const url = `${API_URL}/b2b/user/${code}`;

  const headers = {
    "Content-Type": "application/json",
  };

  const options = {
    method: "GET",
    headers: headers,
  };

  try {
    const response = await fetch(url, options);

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const getDataFromAssociation = async (associationId) => {
  const url = `${API_URL}/b2b/associations/plans/${associationId}`;

  const headers = {
    "Content-Type": "application/json",
  };

  const options = {
    method: "GET",
    headers: headers,
  };

  try {
    const response = await fetch(url, options);

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const updateB2b = async (id, data) => {
  try {
    db.collection("associations").doc(id).set(data, { merge: true });
    console.log("Documento actualizado:", data);
  } catch (error) {
    console.log(error);
  }
};
export const createAssociationImage = async (imageUpload, id) => {
  const imageName = imageUpload.name;

  try {
    const path = `associations/${id}/${imageName}`;
    const fileUrl = await uploadFile(path, imageUpload);

    if (fileUrl) {
      const newImg = { imageURL: fileUrl };
      await updateB2b(id, newImg);
    }
  } catch (error) {
    console.log(error);
  }
};
export const uploadFile = async (path, file) => {
  if (file === null) return;
  console.log(path, file);
  try {
    const upload = await storageRef.ref(path).put(file);
    const fileUrl = await upload.ref.getDownloadURL();

    return fileUrl;
  } catch (error) {
    console.log(file, path);
    console.log("No se puede subir el archivo seleccionado");
  }
};

export const trackEmailLinkClick = () => {
  firebase.analytics().logEvent("email_link_click", {
    link_type: "email", // You can specify the type of link
  });
};

export const subscribeToNotifications = (uid, callback) => {
  const notificationsRef = db
    .collection("notifications")
    .doc("b2b")
    .collection("cms");

  // Subscribe to real-time updates and pass the callback to handle updates
  const unsubscribe = notificationsRef
    .where("associationId", "==", uid)
    .orderBy("cdate", "desc") // Order by cdate in descending order
    .limit(10) // Limit the result to 10 notifications
    .onSnapshot((snapshot) => {
      const notifications = snapshot.docs.map((doc) => {
        const data = doc.data();
        data.id = doc.id; // Include the document ID as "id" in the notification data
        return data;
      });
      callback(notifications); // Call the callback function with filtered notifications data
    });

  return unsubscribe; // Return the unsubscribe function
};

export const subscribeToUserNotifications = (userId, onUpdate) => {
  const userNotificationsRef = db.collection("admins").doc(userId);

  return userNotificationsRef.onSnapshot((doc) => {
    if (doc.exists) {
      const adminUserData = doc.data();

      if (
        adminUserData &&
        adminUserData.notifications &&
        adminUserData.notifications.b2b
      ) {
        const userNotifications = Object.entries(
          adminUserData.notifications.b2b
        ).map(([id, data]) => ({ id, ...data }));
        onUpdate(userNotifications);
      } else {
        onUpdate([]); // User has no notifications or notifications.b2b doesn't exist
      }
    } else {
      onUpdate([]); // User document doesn't exist
    }
  });
};

export const addNotificationToAdmin = async (adminUserId, notification) => {
  const adminUserRef = db.collection("admins").doc(adminUserId); // Replace adminUserId with the actual user ID

  try {
    // Get the current data of the admin user
    const adminUserDoc = await adminUserRef.get();
    const adminUserData = adminUserDoc.data() || {}; // Initialize as an empty object if data doesn't exist

    // Check if the admin user document has a notifications object
    if (!adminUserData.notifications) {
      adminUserData.notifications = {}; // Initialize the notifications object if it doesn't exist
    }

    if (!adminUserData.notifications.b2b) {
      adminUserData.notifications.b2b = {}; // Initialize the "b2b" map if it doesn't exist
    }

    // Include the notification properties in the object
    const notificationData = {
      cdate: new Date(),
      isRead: notification.isRead,
    };

    // Add the new notification to the "b2b" map with the notification ID as the key
    adminUserData.notifications.b2b[notification.id] = notificationData;

    // Update the admin user document with the new notifications object
    await adminUserRef.update({ notifications: adminUserData.notifications });
    console.log("Notification added to admin user successfully.");
  } catch (error) {
    console.error("Error adding notification to admin user:", error);
  }
};

export const getUserNotifications = async (adminUserId) => {
  try {
    const adminUserRef = db.collection("admins").doc(adminUserId);
    const adminUserDoc = await adminUserRef.get();

    if (adminUserDoc.exists) {
      const adminUserData = adminUserDoc.data();

      if (
        adminUserData &&
        adminUserData.notifications &&
        adminUserData.notifications.b2b
      ) {
        const userNotifications = Object.entries(
          adminUserData.notifications.b2b
        ).map(([id, data]) => ({ id, ...data }));
        console.log("User Notifications:", userNotifications);
        return userNotifications;
      } else {
        console.log("User has no notifications.");
        return [];
      }
    } else {
      console.log("User document does not exist.");
      return [];
    }
  } catch (error) {
    console.error("Error getting user notifications:", error);
    return [];
  }
};

export const getAssociation = (association) => ({
  type: GET_ASSOCIATION,
  payload: {
    association,
  },
});
export const getCodes = (codes) => ({
  type: GET_ASSOCIATION_CODES,
  payload: {
    codes,
  },
});
export const getNotifications = (notifications) => ({
  type: GET_NOTIFICATIONS,
  payload: {
    notifications,
  },
});

export const isLoading = (isLoadingAssociation) => ({
  type: IS_LOADING_ASSOCIATION,
  payload: {
    isLoadingAssociation,
  },
});
