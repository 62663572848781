import jwtDecode from "jwt-decode";

import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  TextField,
  useTheme,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { styles } from "../../styles/styles";
import { Typography } from "@mui/material";

import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Md10K,
  MdNotificationImportant,
  MdQuestionAnswer,
} from "react-icons/md";
import { useCallback, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import GeneratePlans from "../../components/generate/GeneratePlans";
import { getSupportPhone, refreshToken } from "../../store/actions/auth";
import { GenerateSummary } from "../../components/generate/GenerateSummary";

import { ASbutton } from "../../components/buttons/ASbutton";
import { GenerateReview } from "../../components/generate/GenerateReview";
import {
  addProductToUser,
  doesUserHaveCode,
  getUserByEmail,
  getUserByUserRef,
} from "../../store/actions/users";
import { GenerateModal } from "../../components/generate/GenerateModal";

const MySwal = withReactContent(Swal);

const GenerateCode = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { idToken, supportPhone } = useSelector((state) => state.auth);
  const { association } = useSelector((state) => state.association);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [apportionmentPrice, setApportionmentPrice] = useState(0);
  const isFirstUse = useRef(true);
  const { adminData } = useSelector((state) => state.auth);
  const associationId = adminData.associationInfo.associationId;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setloading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [noData, setNoData] = useState(false);
  const [noUserData, setNoUserData] = useState(true);
  const [userisActive, setUserisActive] = useState(false);
  const [userHasCode, setUserHasCode] = useState(false); // State to control the overlay visibility

  useEffect(() => {
    const fetchSupportPhone = async () => {
      if (!supportPhone) {
        dispatch(getSupportPhone());
      }
    };

    fetchSupportPhone();
  }, [dispatch, supportPhone]);
  const whatsappLink = `https://wa.me/${supportPhone}`;
  const handleWhatsappLink = () => {
    window.open(whatsappLink, "_blank");
  };

  const duration = [
    {
      value: 12,
      label: "12 meses",
    },
    {
      value: 6,
      label: "6 meses",
    },
    {
      value: 3,
      label: "3 meses",
    },
  ];

  const [data, setData] = useState({
    email: "",
    billNumber: 0,
    price: 0,
    planDuration: "",
    apportionmentPrice: 0,
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Campo requerido").email("Email no válido"),
    planDuration: Yup.number()
      .required("Campo requerido")
      .min(1, "La duración del plan debe ser mayor a 0"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      price: selectedPlan.price ?? "0",
      acceptTerms: false,
      billNumber: 0,
      apportionmentPrice: 0,
      planDuration: 0,
    },
    // enableReinitialize: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const finalData = {
        email: values.email,
        plan: selectedPlan,
        planDuration: values.planDuration,
        finalPrice: apportionmentPrice,
        billNumber: 0,
        associationId: associationId,
        type: "generateExistingUserCode",
      };

      console.log("FINAL DATA", finalData);

      const confirmResult = await MySwal.fire({
        title: "Confirmar ",
        text: "¿Estás seguro de que quieres activar este usuario?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, activar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });
      if (confirmResult.isConfirmed) {
        const loadingSwal = MySwal.fire({
          title: "Guardando...",

          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading();
          },
        });
        try {
          if (isValidToken(idToken)) {
            const res = await addProductToUser(finalData, idToken);
            if (res.ok) {
              setCode(res.code);

              loadingSwal.close();
              handleOpenDialog();
            } else {
              const errorMessage = res.message;
              MySwal.fire("Error", `${errorMessage}`, "error");
            }
          } else {
            const newToken = await refreshToken();
            const res = await addProductToUser(finalData, newToken);

            if (res.ok) {
              setCode(res.code);

              loadingSwal.close();
              handleOpenDialog();
            } else {
              const errorMessage = res.message;
              MySwal.fire("Error", `${errorMessage}`, "error");
            }
          }

          loadingSwal.close();
        } catch (error) {
          console.log(error);
          loadingSwal.close();

          MySwal.fire("Error", "Hubo un error al activar el usuario", "error");
        }
        loadingSwal.close();
      }
    },
  });

  useEffect(() => {
    if (userData.empty) {
      setNoUserData(true);
    } else if (userData) {
      if (userData.isActiveMember) {
        setUserisActive(true);
        setSelectedPlan("");
      } else {
        setUserisActive(false);
      }
    }
  }, [userData]);

  const handleVerifyUser = async () => {
    try {
      if (formik.values.email !== "") {
        setSelectedPlan("");
        setloading(true);
        const dataFromUser = await getUserByEmail(formik.values.email);

        if (dataFromUser) {
          setUserData(dataFromUser);
          setNoData(false);
          setNoUserData(false);

          const userCode = await doesUserHaveCode(
            associationId,
            dataFromUser.userId
          );

          if (userCode) {
            setUserHasCode(true);
            console.log("User has a code!");
          } else {
            setUserHasCode(false);
            console.log("User does not have a code.");
          }
        } else {
          setNoData(true);
          setUserisActive(false);
        }

        setloading(false);
      } else {
        setloading(false);
        setNoData(true);
      }
    } catch (error) {
      setNoData(true);
      console.error(error);
    }
  };

  const handleRefresh = () => {
    setSelectedPlan("");
    setUserData([]);
    setNoUserData(true);

    formik.resetForm();
    formik.setTouched({
      email: false,
      planDuration: false,
    });
    setCode("");
  };
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const handleCloseDialog = () => {
    handleRefresh();
    setDialogOpen(false);
  };

  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };
  const renderContent = () => {
    if (loading) {
      return (
        <Grid
          container
          sx={[
            styles.formCardUser,
            {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
        >
          <Grid
            container
            columnGap={2}
            rowGap={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      );
    }
    if (noData) {
      return (
        <Grid
          container
          sx={styles.formCardUser}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            container
            columnGap={2}
            rowGap={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item lg={6} md={12} sm={12}>
              <Typography
                variant="h7"
                sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
              >
                Usuario no existe
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    if (noUserData) {
      return (
        <Grid
          container
          sx={styles.formCardUser}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            container
            columnGap={2}
            rowGap={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item lg={6} md={12} sm={12}>
              <Typography
                variant="h7"
                sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
              >
                Escriba un correo electrónico.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid
        container
        sx={
          ([styles.formCardUser],
          userisActive || userHasCode
            ? [styles.overlay]
            : [styles.formCardUser])
        }
      >
        {(userisActive || userHasCode) && (
          <Box
            sx={{
              position: "absolute",
              margin: "0 auto",
              zIndex: 10000,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 8,
              }}
            >
              <Typography
                variant="h7"
                sx={{
                  fontWeight: "bold",
                  color: "white",
                  textAlign: "center",
                  margin: "1em auto",
                }}
              >
                {userHasCode
                  ? "Usuario ya tiene código activo"
                  : "Usuario ya se encuentra activo, contacte a soporte"}
              </Typography>

              {userisActive && (
                <ASbutton
                  buttonText={"Contactar"}
                  onClick={handleWhatsappLink}
                />
              )}
            </Box>
          </Box>
        )}
        <Grid
          container
          columnGap={2}
          rowGap={2}
          mb={2}
          mt={2}
          justifyContent={"space-between"}
        >
          <Grid item xl={3.5} lg={5.5} md={12} sm={12}>
            <FormControl fullWidth>
              <TextField
                label="Nombre"
                variant="outlined"
                value={
                  userData
                    ? `${userData?.name} ${userData?.lastname} ${userData?.secondLastname}`
                    : ""
                }
                disabled={userisActive}
                InputProps={{
                  readOnly: true,
                  pointerEvents: "none",
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xl={3.5} lg={5.5} md={12} sm={12}>
            <FormControl fullWidth>
              <TextField
                label="Correo Electrónico"
                variant="outlined"
                value={userData.email}
                disabled={userisActive}
                InputProps={{
                  readOnly: true,
                  pointerEvents: "none",
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xl={3.5} lg={5.5} md={12} sm={12}>
            <FormControl fullWidth>
              <TextField
                label="Teléfono"
                variant="outlined"
                value={userData.phone}
                disabled={userisActive}
                InputProps={{
                  readOnly: true,
                  pointerEvents: "none",
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xl={3.5} lg={5.5} md={12} sm={12}>
            <FormControl fullWidth>
              <TextField
                label="Identificación"
                variant="outlined"
                value={userData.personalId}
                disabled={userisActive}
                InputProps={{
                  readOnly: true,
                  pointerEvents: "none",
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container maxWidth={"xxl"}>
      <Box
        sx={[
          styles.formCard,
          {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            padding: 4,
            [theme.breakpoints.down("sm")]: {
              pt: 2,
              pb: 2,
              pr: 1,
              pl: 1,
            },
          },
        ]}
      >
        <Grid container alignItems={"flex-end"}>
          <Box sx={styles.formTitle} mb={2}>
            <Typography
              variant="h7"
              sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
            >
              Selecciona el correo electrónico
            </Typography>
          </Box>
          <Grid item xs={12} sm={12} md={5} mb={4}>
            <FormControl fullWidth>
              <TextField
                id="email"
                name="email"
                label="Correo Electrónico"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={2} mb={4} ml={4}>
            <ASbutton buttonText={"Verificar"} onClick={handleVerifyUser} />
          </Grid>
        </Grid>

        <Box sx={styles.formTitle} mb={2}>
          <Typography
            variant="h7"
            sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
          >
            Datos de el Usuario
          </Typography>
        </Box>
        {renderContent()}
        <Grid container spacing={2}>
          <Grid container item lg={6} md={12} sm={12}>
            <GeneratePlans
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              associationId={associationId}
              userisActive={userisActive}
              handleWhatsappLink={handleWhatsappLink}
              noData={noData}
              userHasCode={userHasCode}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <GenerateSummary
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              apportionmentPrice={apportionmentPrice}
              setApportionmentPrice={setApportionmentPrice}
              associationId={associationId}
              setData={setData}
              data={data}
              userisActive={userisActive}
              handleWhatsappLink={handleWhatsappLink}
              userHasCode={userHasCode}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <GenerateReview
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              apportionmentPrice={apportionmentPrice}
              setApportionmentPrice={setApportionmentPrice}
              associationId={associationId}
              setData={setData}
              data={data}
              userisActive={userisActive}
              handleWhatsappLink={handleWhatsappLink}
              userHasCode={userHasCode}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={12} md={5}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Seleccione Duración del Plan"
                id="planDuration"
                name="planDuration"
                select
                value={formik.values.planDuration}
                onChange={(e) =>
                  formik.setFieldValue("planDuration", e.target.value)
                }
                helperText={formik.touched.device && formik.errors.device}
                error={Boolean(formik.errors.device)}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdNotificationImportant />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                disabled={!selectedPlan}
              >
                {duration.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          {/* <Grid container spacing={2} mt={4}>
            <Grid item md={12}>
              <GenerateReview
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                apportionmentPrice={apportionmentPrice}
                setApportionmentPrice={setApportionmentPrice}
                associationId={association.associationId}
                setData={setData}
                data={data}
              />
            </Grid>
          </Grid> */}
          <Grid container mt={4} justifyContent={"flex-end"}>
            <Grid item>
              <ASbutton
                buttonText={"Generar Código"}
                onClick={formik.handleSubmit}
                disabled={
                  !formik.isValid ||
                  !selectedPlan ||
                  userisActive ||
                  userHasCode
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <GenerateModal
          open={dialogOpen}
          onClose={handleCloseDialog}
          handleRefresh={handleRefresh}
          code={code}
          email={formik.values.email}
        />
      </Box>
    </Container>
  );
};

export default GenerateCode;
