import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import cheers from "../../assets/cheers.png";
import { ASbutton } from "../buttons/ASbutton";
import QRCode from "react-qr-code";
import { styles } from "../../styles/styles";

export const GenerateModal = ({
  open,
  onClose,
  email,
  code,
  handleRefresh,
}) => {
  const handleClose = () => {
    onClose();
    handleRefresh();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      PaperProps={{
        sx: {
          // backgroundColor: theme.palette.secondary.secondary,
          width: "100%",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          mt={2}
          mb={2}
        >
          <img
            src={cheers}
            alt="Usuario Agregado"
            style={{ width: "100%", maxWidth: 150 }}
          />
        </Box>
        <DialogContentText
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            mb: 2,
          }}
        >
          <Typography variant="body1" color={"black"}>
            Correo Electrónico: {email}
          </Typography>
        </DialogContentText>

        {/* <Grid container spacing={2} mb={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Box mt={3} sx={styles.qrCard}>
              <Box mb={2}>
                <Typography>Escanee el Código QR </Typography>
              </Box>
              <QRCode value={qrCodeUrl} size={150} />
            </Box>
          </Grid>
        </Grid> */}
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12} sm={12} md={12}>
            <FormControl fullWidth>
              <TextField
                label="Código"
                variant="outlined"
                value={code}
                InputProps={{
                  readOnly: true,
                  pointerEvents: "none",
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mb: 2,
        }}
      >
        <ASbutton
          onClick={handleClose}
          color="primary"
          buttonText={" Entendido"}
        />
      </DialogActions>
    </Dialog>
  );
};
