import {
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useTheme,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { styles } from "../../styles/styles";
import { Typography } from "@mui/material";
import hacker from "../../assets/hacker.png";
import { Md10K, MdQuestionAnswer } from "react-icons/md";
import { getSupportPhone } from "../../store/actions/auth";
import { useEffect } from "react";

const Inquiries = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { supportPhone } = useSelector((state) => state.auth);
  useEffect(() => {
    const fetchSupportPhone = async () => {
      if (!supportPhone) {
        dispatch(getSupportPhone());
      }
    };

    fetchSupportPhone();
  }, [dispatch, supportPhone]);
  const whatsappLink = `https://wa.me/${supportPhone}`;

  const questions = [
    {
      title: "Enviar una consulta",
      description: "Puede enviar a través de WhatsApp tu consulta",
      icon: <MdQuestionAnswer />,
      link: () => window.open(whatsappLink, "_blank"),
    },
    {
      title: "Reportar un Error",
      description: "Juntos mejoramos la experiencia de Usuario",
      icon: <MdQuestionAnswer />,
      link: () => window.open(whatsappLink, "_blank"),
    },
    {
      title: "Como utilizar el sistema",
      description:
        "Encontra el tutorial de uso de la Herramienta Allsports B2B",
      icon: <MdQuestionAnswer />,
      link: () => window.open(whatsappLink, "_blank"),
    },
    {
      title: "Soporte Técnico",
      description: "Estamos para apoyarte en toda situación",
      icon: <MdQuestionAnswer />,
      link: () => window.open(whatsappLink, "_blank"),
    },
    {
      title: "Consultar sobre un suceso a la Aseguradora",
      description: "En caso de un suceso ingrese aquí",
      icon: <MdQuestionAnswer />,
      link: () => window.open(whatsappLink, "_blank"),
    },
  ];
  const renderFaq = () => {
    return (
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {questions.map((question, index) => (
          <ListItem>
            <ListItemAvatar>
              <IconButton
                sx={{
                  bgcolor: theme.palette.terciary.main,
                  width: 48,
                  height: 48,
                  mr: 2,
                }}
                onClick={question.link}
              >
                {question.icon}
              </IconButton>
            </ListItemAvatar>
            <ListItemText
              sx={{ cursor: "pointer" }}
              onClick={() => window.open(whatsappLink, "_blank")}
              primary={question.title}
              secondary={question.description}
            />
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Container maxWidth={"xxl"}>
      <Box
        sx={[
          styles.formCard,
          {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          },
        ]}
      >
        <Grid
          container
          mt={4}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Grid
            item
            md={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              [theme.breakpoints.down("md")]: {
                display: "flex",
                width: "325px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto 2em auto",
                maxWidth: "325px",
              },
              [theme.breakpoints.up("lg")]: {
                display: "flex",
                width: "450px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto 2em auto",
                maxWidth: "450px",
              },
            }}
          >
            <img
              src={hacker}
              alt="faq"
              style={{ width: "80%", height: "auto" }}
            />
          </Grid>
          <Grid item md={7}>
            {renderFaq()}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Inquiries;
