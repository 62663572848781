import { auth, db } from "../../firebase/firebase-config";

export const LOGIN_START = "LOGIN_START";
export const LOGIN = "LOGIN";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const IS_LOADING_AUTH = "IS_LOADING_AUTH";
export const LOGOUT = "LOGOUT";
export const SET_ADMIN_DATA = "SET_ADMIN_DATA";
export const IS_ASSOCIATION = "IS_ASSOCIATION";
export const SUPPORT_PHONE = "SUPPORT_PHONE";
export const getUserByEmail = async (email) => {
  const hasAccount = await db
    .collection("admins")
    .where("email", "==", email)
    .get();
  return !hasAccount.empty;
};
export const refreshToken = async () => {
  const token = await auth.currentUser.getIdToken(true);

  return token;
};

/*export const loginUser = (user) => ({
  type: types.login,
  payload: user,
});*/

/*export const login = async (email, password) => {
  try {
    const response = await auth.signInWithEmailAndPassword(email, password);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};*/

export const getSupportPhone = () => {
  return async (dispatch) => {
    try {
      const phone = await db.collection("utils").doc("contact").get();
      const supportPhone = phone.data().supportPhone;

      // Dispatch the action with the supportPhone value
      dispatch(setSupportPhone(supportPhone));
    } catch (error) {
      // Handle any potential errors here
      console.error("Error in getSupportPhone:", error);
      throw error; // You may choose to rethrow the error for handling in higher-level code
    }
  };
};

export const loginUser = (email, password) => {
  return async (dispatch) => {
    dispatch(loginUserStart());
    try {
      const response = await auth.signInWithEmailAndPassword(email, password);
      const idToken = await response.user.getIdToken(true);
      const claims = await response.user.getIdTokenResult();

      if (claims.superadmin && claims.associationId) {
        dispatch(
          loginUserSuccess(response.user.toJSON(), true, idToken, claims)
        );
      } else {
        dispatch(isAssociation(false));
      }
    } catch (error) {
      dispatch(loginUserFailure(error));
      console.log(error);
    }
  };
};

// isAlmighty: action.payload.isAlmighty || false,
// isAdmin: action.payload.isAdmin || false,
// isModerator: action.payload.isModerator || false,

export const forgotPassword = async (password) => {
  try {
    await auth.sendPasswordResetEmail(password);

    console.log("Password reset email sent successfully!");
  } catch (error) {
    console.error("Error sending password reset email:", error);
  }
};
export const logoutUser = () => {
  return async (dispatch) => {
    dispatch(logoutUserStart());
    try {
      await auth.signOut();
      dispatch(logoutUserSuccess());
    } catch (error) {
      dispatch(logoutUserFailure(error));
      console.error(error);
    }
  };
};

export const loginUserStart = () => ({
  type: LOGIN_START,
  payload: true,
});

export const loginUserSuccess = (
  user,
  loggedIn,
  idToken,
  userGetToken,
  claims
) => ({
  type: LOGIN,
  payload: {
    user: user,
    loggedIn: loggedIn,
    idToken: idToken,
    isLoadingAuth: false,
    userGetToken: userGetToken,
    isAlmighty: claims.almighty,
    isSuperAdmin: claims.superadmin,
    isAdmin: claims.admin,
    isModerator: claims.moderator,
  },
});

export const loginUserFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});
export const isAssociation = (error) => ({
  type: IS_ASSOCIATION,
  payload: error,
});
export const setSupportPhone = (supportPhone) => ({
  type: SUPPORT_PHONE,
  payload: supportPhone,
});

export const logoutUserStart = () => ({
  type: LOGOUT_START,
});

export const logoutUserSuccess = (user) => ({
  type: LOGOUT_SUCCESS,
});

export const logoutUserFailure = (error) => ({
  type: LOGOUT_ERROR,
  payload: error,
});

export const signInWithEmail = (user, claims) => ({
  type: LOGIN,
  payload: {
    user,
    loggedIn: true,
    isAlmighty: claims.almighty,
    isSuperAdmin: claims.superadmin,
    isAdmin: claims.admin,
    isModerator: claims.moderator,
  },
});
export const setAdmin = (adminData) => ({
  type: SET_ADMIN_DATA,
  payload: {
    adminData: adminData,
  },
});
export const setIsLoadingAuth = (isLoading) => ({
  type: IS_LOADING_AUTH,
  payload: {
    isLoadingAuth: isLoading,
  },
});

export const logout = () => ({
  type: LOGOUT,
  payload: {},
});
