import { Routes, Route, Navigate } from "react-router-dom";

import { ProtectedRoute } from "../pages/protected-routes/protected-routes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { auth } from "../firebase/firebase-config";

import { Login } from "../pages/login/Login";
import SidebarLayout from "../pages/navigation/SidebarLayout";
import { CoinLoader } from "../components/coin-loader/CoinLoader";
import {
  loginUserSuccess,
  setAdmin,
  setIsLoadingAuth,
} from "../store/actions/auth";
import Home from "../pages/Home/Home";
import { ForgotPassword } from "../pages/login/ForgotPassword";
import { Inclusion } from "../pages/inclusion/Inclusion";
import Users from "../pages/users/Users";
import { EditProfile } from "../pages/profile/EditProfile";
import Exclusion from "../pages/exclusions/Exclusion";
import {
  getAssociationData,
  getAssociationFromDB,
} from "../store/actions/association";

import Inquiries from "../pages/inquiries/Inquiries.js";
import Settings from "../pages/settings/Settings";
import Acceptance from "../pages/acceptance/Acceptance";
import { AcceptanceUser } from "../pages/acceptance/AcceptanceUser";
import GenerateCode from "../pages/generate/GenerateCode";

function AppRoutes() {
  const dispatch = useDispatch();
  const { loggedIn, isLoadingAuth, adminData } = useSelector(
    (state) => state.auth
  );
  const { association } = useSelector((state) => state.association);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idToken = await user.getIdToken(true);
        const claims = await user.getIdTokenResult();
        const admin = await getAssociationFromDB(user.uid);

        if (admin) {
          try {
            const adminData = admin.data();
            // console.log(adminData);
            if (adminData) {
              const associationId = adminData?.associationInfo?.associationId;
              dispatch(getAssociationData(associationId));

              dispatch(setAdmin(adminData));

              dispatch(
                loginUserSuccess(user.toJSON(), true, idToken, user, claims)
              );
              dispatch(setIsLoadingAuth(false));
            }
          } catch (error) {
            console.log(error);
            dispatch(setIsLoadingAuth(false));
          }
        }
      } else {
        dispatch(setIsLoadingAuth(false));
      }
    });
  }, [dispatch]);

  if (isLoadingAuth) {
    const size = {
      width: 125,
      height: 125,
    };
    return <CoinLoader size={size} fullScreen={true} />;
  }

  return (
    <Routes>
      <Route path="/" element={<SidebarLayout />}>
        <Route
          path=""
          element={
            loggedIn ? (
              <Navigate replace to="/home" />
            ) : (
              <Navigate replace to="/auth/login" />
            )
          }
        />

        <Route
          path="/*"
          element={
            <ProtectedRoute isLoggedIn={loggedIn}>
              <Routes>
                <Route
                  path="/auth/login"
                  element={<Navigate replace to="/home" />}
                />
                <Route index path="/home" element={<Home />} />
                <Route path="/inclusions" element={<Inclusion />} />
                <Route path="/generate" element={<GenerateCode />} />
                <Route path="/exclusions" element={<Exclusion />} />
                <Route path="/b2bUsers" element={<Users />} />
                <Route path="/inquiries" element={<Inquiries />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/profile" element={<EditProfile />} />
                {/* <Route path="/user/:id" element={<User />} /> */}
              </Routes>
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="*"
        element={
          <h1
            className="d-flex align-items-center vh-100"
            style={{ marginLeft: "30%" }}
          >
            Lo sentimos , esta página no esta disponible
          </h1>
        }
      />
      <Route path="/acceptance/:association/:id" element={<Acceptance />} />
      <Route path="/association/:association/" element={<AcceptanceUser />} />
      <Route path="/auth/forgotpassword" element={<ForgotPassword />} />
      {!loggedIn && <Route path="/auth/login" element={<Login />}></Route>}
    </Routes>
  );
}

export default AppRoutes;
