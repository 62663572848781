import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
  useTheme,
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { styles } from "../../styles/styles";

import { ASbutton } from "../../components/buttons/ASbutton";
import { MdNotificationImportant } from "react-icons/md";

import { Provinces as provinces } from "../../utils/provinces";
import { Cantons as cantons } from "../../utils/cantons";
import { Districts as districts } from "../../utils/districts";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useFormik } from "formik";
import * as Yup from "yup";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond/dist/filepond.min.css"; // Import FilePond styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import imgDrop from "../../assets/imgDrop.png";
import UploadImage from "../general/UploadImage";

export const EditProfileForm = ({
  handleNext,
  handleRegister,
  data,
  image,
  setImage,
  setImageUpload,
  imageUpload,
  setFileUrl,
  loading,
}) => {
  const theme = useTheme();
  const [filteredCantons, setFilteredCantons] = useState([]);
  const [filteredDistricts, setFilteredDistricts] = useState([]);

  useEffect(() => {
    // Update formik initial values and the image state when data prop changes
    formik.setValues({
      name: data.name ?? "",
      email: data.email || "",
      businessName: data.businessName || "",
      codeName: data.codeName ?? "",
      legalId: data.legalId ?? "",
      phone: data.phone ?? "",
      //   imageURL: data.imageURL ?? "",
      location: data.location ?? "",
      address: data.address ?? "",
      latitude: data.location?.latitude ?? "",
      longitude: data.location?.longitude ?? "",
      isActive: data.isActive ?? false,
      imageURL: data.imageURL ?? "",
    });
    setImage(data.imageURL);
  }, [data]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
    lastname: Yup.string().required("Campo requerido"),
    // lastname2: Yup.string().required("Campo requerido"),
    phone: Yup.string().required("Campo requerido"),
    email: Yup.string().required("Campo requerido").email("Email no válido"),

    civilState: Yup.mixed().required("Campo requerido"),
    gender: Yup.mixed().required("Campo requerido"),
    nationality: Yup.mixed().required("Campo requerido"),
    personalId: Yup.mixed().required("Campo requerido"),
    address: Yup.mixed().required("Campo requerido"),
    province: Yup.mixed().required("Campo requerido"),
    canton: Yup.mixed().required("Campo requerido"),
    district: Yup.mixed().required("Campo requerido"),
    zipCode: Yup.mixed().required("Campo requerido"),
    platform: Yup.mixed().required("Campo requerido"),
  });

  const formik = useFormik({
    initialValues: {
      name: data.name ?? "",
      email: data.email || "",
      businessName: data.businessName || "",
      codeName: data.codeName ?? "",
      legalId: data.legalId ?? "",
      phone: data.phone ?? "",
      //   imageURL: data.imageURL ?? "",
      location: data.location ?? "",
      address: data.address ?? "",
      latitude: data.location?.latitude ?? "",
      longitude: data.location?.longitude ?? "",
    },
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      try {
        handleRegister(values);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleFileUpload = (image) => {
    // Handle the uploaded files, e.g., set them in the component's state
    setImage(image);
  };

  //   useEffect(() => {
  //     console.log(formik.values);
  //   }, [formik.values]);

  useEffect(() => {
    const selectedProvince = formik.values.province;

    if (selectedProvince) {
      const filterItems = cantons.filter(
        (canton) => canton.provinceName === selectedProvince
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });
      setFilteredCantons(filterItems);
    }
  }, [formik.values.province]);

  useEffect(() => {
    const selectedCanton = formik.values.canton;

    if (selectedCanton) {
      const filterItems = districts.filter(
        (district) => district.cantonName === selectedCanton
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });
      setFilteredDistricts(filterItems);
    }
  }, [formik.values.canton]);
  if (loading) {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "450px",
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box sx={styles.formContainer}>
      <UploadImage
        loadUrl={setFileUrl}
        imageUpload={imageUpload}
        setImageUpload={setImageUpload}
        setImage={setImage}
        image={image}
      />

      <form style={{ padding: 30 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Nombre Asociación"
              fullWidth
              variant="outlined"
              name="name"
              value={formik.values.name}
              disabled
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Correo Electrónico"
              fullWidth
              variant="outlined"
              name="email"
              disabled
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Empresa de la asociación"
              fullWidth
              disabled
              variant="outlined"
              name="businessName"
              value={formik.values.businessName}
              onChange={formik.handleChange}
              error={
                formik.touched.businessName &&
                Boolean(formik.errors.businessName)
              }
              helperText={
                formik.touched.businessName && formik.errors.businessName
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Codigo"
              fullWidth
              variant="outlined"
              name="codeName"
              disabled
              value={formik.values.codeName}
              onChange={formik.handleChange}
              error={formik.touched.codeName && Boolean(formik.errors.codeName)}
              helperText={formik.touched.codeName && formik.errors.codeName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Teléfono"
              fullWidth
              variant="outlined"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Cedula Jurídica"
              fullWidth
              variant="outlined"
              disabled
              name="legalId"
              value={formik.values.legalId}
              onChange={formik.handleChange}
              error={formik.touched.legalId && Boolean(formik.errors.legalId)}
              helperText={formik.touched.legalId && formik.errors.legalId}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Dirección"
              fullWidth
              variant="outlined"
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Latitud"
              fullWidth
              variant="outlined"
              name="latitude"
              value={formik.values.latitude}
              onChange={formik.handleChange}
              error={formik.touched.latitude && Boolean(formik.errors.latitude)}
              helperText={formik.touched.latitude && formik.errors.latitude}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Longitud"
              fullWidth
              variant="outlined"
              name="longitude"
              value={formik.values.longitude}
              onChange={formik.handleChange}
              error={
                formik.touched.longitude && Boolean(formik.errors.longitude)
              }
              helperText={formik.touched.longitude && formik.errors.longitude}
            />
          </Grid>

          {/* <Grid item xs={6} mt={2}>
            <FormControl
              component="fieldset"
              sx={{
                marginLeft: "0px !important",
                flexDirection: "row",
                marginBottom: "15px",
              }}
            >
              <FormControlLabel
                sx={{ marginLeft: "0px !important", flexDirection: "row" }}
                value="isActive"
                label="Activo"
                labelPlacement="start"
                control={
                  <Checkbox
                    name="isActive"
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
              />
            </FormControl>
          </Grid> */}
        </Grid>
      </form>
      <Grid
        container
        sx={styles.buttonsContainer}
        justifyContent={"flex-end"}
        direction="row"
      >
        {/* <Grid item>
          <ASbutton
            type="submit"
            backgroundColor={"grey"}
            color={"white"}
            disabled={activeStep === 0}
            buttonText={"Cancelar"}
          />
        </Grid> */}
        <Grid item>
          <Box ml={2}>
            <ASbutton
              onClick={formik.handleSubmit}
              backgroundColor={theme.palette.primary.main}
              color={"white"}
              buttonText={"Guardar"}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
