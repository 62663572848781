import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import { useMediaQuery, useTheme } from "@mui/material";

function MenuButton({ item, open, disabled, handleDrawerToggle }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const navigateTo = (link) => {
    navigate(link);
  };
  const location = useLocation();

  const activeStyle = {
    color: theme.palette.primary.main,
  };
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMobileClick = () => {
    handleDrawerToggle();
    navigateTo(item.route);
  };

  return (
    <>
      <ListItemButton
        key={item.title}
        disabled={disabled}
        onClick={mobile ? handleMobileClick : () => navigateTo(item.route)}
        className={item.route === `${location.pathname}` ? "isActive" : ""}
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
          color:
            item.route === `${location.pathname}`
              ? theme.palette.primary.main
              : "grey",
        }}
      >
        {item.icon && (
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {item.icon}
          </ListItemIcon>
        )}

        <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </>
  );
}

export default MenuButton;
