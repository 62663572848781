import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { styles } from "../../styles/styles";

const PlanModal = ({ isOpen, onClose, plan }) => {
  const theme = useTheme();
  if (!plan) return null;

  const renderList = () => {
    return plan.data.map((data) => (
      <Grid
        container
        item
        md={10}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        <Grid item md={8}>
          <Typography
            textAlign={"left"}
            variant="body1"
            sx={{ fontSize: "0.8rem !important" }}
            key={data.id}
          >
            {data.description}
          </Typography>
        </Grid>
        <Grid item md={4}>
          <Typography variant="body2" key={data.id} textAlign={"right"}>
            {data.value}
          </Typography>
        </Grid>
      </Grid>
    ));
  };
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          // backgroundColor: theme.palette.secondary.secondary,
          width: "350px",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle sx={{ color: theme.palette.primary.main }}>
        Información del Plan
        <Box sx={styles.closeButton} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",

          flexDirection: "column",
        }}
      >
        <Typography variant="h6">{plan.subTier}</Typography>
        <Typography variant="body1">Código: {plan.code}</Typography>
        <Typography variant="body1">Precio: ₡{plan.finalPrice}</Typography>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
            mt: 2,
            flexDirection: "column",

            borderRadius: "18px",
            borderWidth: "2px !important",
            border: "solid",
            borderColor: "#ECECEE",
            pt: 1,
            pb: 1,
          }}
        >
          <Box mb={1}>
            <Typography variant="body1">Detalles del Plan</Typography>
          </Box>
          {renderList()}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlanModal;
