import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAssociationProducts } from "../../store/actions/products";
import { ASbutton } from "../buttons/ASbutton";
import { styles } from "../../styles/styles";
import { useFormik } from "formik";
import { MdNotificationImportant } from "react-icons/md";

export const AccPlans = ({
  handleNext,
  handleBack,
  selectedPlan,
  setSelectedPlan,
  apportionmentPrice,
  setApportionmentPrice,
  associationInfo,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);

  const [loading, setloading] = useState(false);
  const isFirstUse = useRef(true);
  const [noDataAvailable, setNoDataAvailable] = useState(false);

  const duration = [
    {
      value: 12,
      label: "12 meses",
    },
    {
      value: 6,
      label: "6 meses",
    },
    {
      value: 3,
      label: "3 meses",
    },
  ];
  const dummyPlans = [
    { id: 1, subTier: "Basic Plan", code: "BASIC123", price: 10.99 },
    { id: 2, subTier: "Standard Plan", code: "STANDARD456", price: 19.99 },
    { id: 3, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 4, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 5, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 6, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 7, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 8, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 9, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 10, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },

    // ... add more dummy plans
  ];

  const [priceNoIVA, setPriceNoIva] = useState(0);
  const [priceToCharge, setPriceToCharge] = useState(0);

  useEffect(() => {
    const overallDate = new Date();
    const year = overallDate.getFullYear();
    const month = overallDate.getMonth() + 1;
    const date = overallDate.getDate();

    //Days in the month
    const apportionmentDays = new Date(year, month, 0).getDate();
    //Days left in the month
    const apportionment = apportionmentDays - date + 1;
    //SET Price without IVA
    setPriceNoIva(selectedPlan.finalPrice - selectedPlan.finalPrice * 0.13);

    setApportionmentPrice((priceNoIVA / apportionmentDays) * apportionment);

    setPriceToCharge(
      (apportionmentPrice + apportionmentPrice * 0.13).toFixed(2)
    );
  }, [apportionmentPrice, selectedPlan, priceNoIVA]);

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan.id === selectedPlan ? null : plan);
  };

  const formik = useFormik({
    initialValues: {
      planDuration: 0,
      price: selectedPlan.price,
      apportionmentPrice: 0,
    },

    onSubmit: (values) => {
      try {
        const dataToAdd = {
          planSelected: selectedPlan,
          planDuration: values.planDuration,

          price: 0,

          billNumber: 0,
          apportionmentPrice: apportionmentPrice,
        };

        handleNext(dataToAdd);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const renderCard = (plan, index) => (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      key={index}
      p={"15px !important"}
    >
      <Paper
        elevation={selectedPlan.id === plan.id ? 5 : 3}
        style={{
          padding: "16px",
          backgroundColor: selectedPlan.id === plan.id ? "#FAFAFB" : "#F6F6F6",
          cursor: "pointer",
          borderRadius: "18px",
          borderWidth: "2px",
          border: selectedPlan.id === plan.id ? "solid" : "none",
          borderColor:
            selectedPlan.id === plan.id ? theme.palette.primary.main : "white",
        }}
        onClick={() => handlePlanSelect(plan)}
      >
        <Typography variant="h6" gutterBottom>
          {plan.subTier}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Código {plan.code}
        </Typography>
      </Paper>
    </Grid>
  );
  const renderGrid = () => {
    if (loading && !noDataAvailable) {
      return (
        <Grid container spacing={2} sx={[styles.planSelectionLoading]}>
          <CircularProgress />
        </Grid>
      );
    }
    if (noDataAvailable) {
      return (
        <Grid container spacing={2} sx={[styles.planSelectionLoading]}>
          <Typography>No Hay productos disponibles</Typography>
        </Grid>
      );
    }

    return (
      <Grid
        container
        spacing={2}
        rowGap={2}
        sx={styles.planSelection}
        justifyContent={"center"}
      >
        {associationInfo.map((plan) => renderCard(plan))}
      </Grid>
    );
  };

  return (
    <Box sx={styles.plansCardAcceptance}>
      <Box sx={styles.formTitle} mb={4}>
        <Typography
          variant="h7"
          sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
        >
          Selecciona un plan
        </Typography>
      </Box>
      {renderGrid()}
      <Grid
        container
        mt={3}
        justifyContent={"flex-end"}
        sx={{
          width: "90%",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <Grid item xs={12} sm={12} md={12}>
          {selectedPlan && (
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Seleccione Duración del Plan"
                id="planDuration"
                name="planDuration"
                select
                value={formik.values.planDuration}
                onChange={(e) =>
                  formik.setFieldValue("planDuration", e.target.value)
                }
                helperText={formik.touched.device && formik.errors.device}
                error={Boolean(formik.errors.device)}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdNotificationImportant />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              >
                {duration.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        sx={styles.buttonsContainer}
        justifyContent={"flex-end"}
        direction="row"
      >
        <Grid item>
          <ASbutton
            onClick={handleBack}
            type="submit"
            backgroundColor={"grey"}
            color={"white"}
            buttonText={"Atrás"}
          />
        </Grid>
        <Grid item>
          <Box ml={2}>
            <ASbutton
              onClick={formik.handleSubmit}
              backgroundColor={theme.palette.primary.main}
              color={"white"}
              disabled={formik.values.planDuration === 0 || !selectedPlan}
              buttonText={"Siguiente"}
            >
              Submit
            </ASbutton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
