import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { styles } from "../../styles/styles";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import { ASbutton } from "../../components/buttons/ASbutton";

import { isMobile } from "react-device-detect";

import { useFormik } from "formik";
import * as Yup from "yup";

import { activateUserAcc, getDataFromCode } from "../../store/actions/users";
import { useParams } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Terms } from "./Terms";

const MySwal = withReactContent(Swal);
const Acceptance = () => {
  const theme = useTheme();

  const [apportionmentPrice, setApportionmentPrice] = useState(0);
  const [priceNoIVA, setPriceNoIva] = useState(0);
  const [priceToCharge, setPriceToCharge] = useState(0);
  const [loading, setloading] = useState(true);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
  });

  const [selectedPlan, setSelectedPlan] = useState([]);
  const [data, setData] = useState([]);
  const [readTerms, setReadTerms] = useState(false);
  const [codeData, setCodeData] = useState("");
  const [linkAvailable, setLinkAvailable] = useState(true);
  const { id } = useParams();
  const scrollContainerRef = useRef(null);
  const code = id.split("-")[1];
  const getInfo = useCallback(async () => {
    try {
      const codeFromFb = await getDataFromCode(id);

      const codeInfo = codeFromFb.codeInfo;

      const plan = codeInfo.plan;

      setCodeData(codeFromFb.codeInfo);

      if (codeInfo && codeInfo.linkUsed === true) {
        setLinkAvailable(false);
      }
      setSelectedPlan(plan);
      // setData(userData);

      setloading(false);
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  useEffect(() => {
    const overallDate = new Date();
    const year = overallDate.getFullYear();
    const month = overallDate.getMonth() + 1;
    const date = overallDate.getDate();

    //Days in the month
    const apportionmentDays = new Date(year, month, 0).getDate();
    //Days left in the month
    const apportionment = apportionmentDays - date + 1;
    //SET Price without IVA
    setPriceNoIva(selectedPlan.finalPrice - selectedPlan.finalPrice * 0.13);

    setApportionmentPrice((priceNoIVA / apportionmentDays) * apportionment);

    setPriceToCharge(
      (apportionmentPrice + apportionmentPrice * 0.13).toFixed(2)
    );
  }, [apportionmentPrice, selectedPlan, priceNoIVA]);

  const formik = useFormik({
    initialValues: {
      email: data.email,
      price: selectedPlan.price ?? "0",
      acceptTerms: false,
      apportionmentPrice: 0,
    },
    enableReinitialize: true,

    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      const confirmResult = await Swal.fire({
        title: "Confirmar",
        text: "¿Enviar solicitud?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, agregar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });

      if (confirmResult.isConfirmed) {
        const loadingSwal = Swal.fire({
          title: "Guardando...",
          html: '<div class="swal2-spinner"></div>',
          showConfirmButton: false,
          allowOutsideClick: false,
        });

        try {
          const associationId = codeData.plan.associationRef;
          const userId = codeData.userRef;

          const requestData = {
            userId,
            code,
            associationId,
          };

          if (codeData) {
            const res = await activateUserAcc(requestData);

            if (res.ok) {
              loadingSwal.close();

              Swal.fire({
                title: `Tu solicitud ha sido enviada`,
                icon: "success",
                showCancelButton: true,
                confirmButtonText: "OK",
                cancelButtonText: "Cancelar",
              });

              setLinkAvailable(false);
            } else {
              MySwal.fire(
                "Error",
                "Hubo un error al enviar la solicitud",
                "error"
              );
            }
          } else {
            loadingSwal.close();

            MySwal.fire("Error", "Hubo un error al crear el usuario", "error");
          }
        } catch (error) {
          console.log(error);
          loadingSwal.close();

          MySwal.fire("Error", "Hubo un error al crear el usuario", "error");
        }
      }
    },
  });

  const renderContent = () => {
    if (isMobile) {
      return (
        <Grid item xs={12} sm={12} md={12} mb={3}>
          <FormControl fullWidth>
            <Box
              ref={scrollContainerRef} // Attach the ref to the scroll container
              sx={{
                padding: "8px",
                maxHeight: "200px", // Set the desired max height for the box
                overflowY: "auto",
                borderRadius: "18px",
                borderWidth: "2px !important",
                border: "solid",
                borderColor: "#ECECEE",
              }}
              onTouchMove={(e) => {
                e.preventDefault();

                const { scrollTop, scrollHeight, clientHeight } = e.target;
                const isAtBottom = scrollTop + clientHeight === scrollHeight;

                // Set the checkbox state based on whether the user is at the bottom
                formik.setFieldValue("acceptTerms", isAtBottom);
                setReadTerms(isAtBottom);
              }}
            >
              <Terms />
            </Box>
          </FormControl>
        </Grid>
      );
    }
    return (
      <Grid item xs={12} sm={12} md={12} mb={3}>
        <FormControl fullWidth>
          <Box
            ref={scrollContainerRef} // Attach the ref to the scroll container
            sx={{
              padding: "8px",
              maxHeight: "200px", // Set the desired max height for the box
              overflowY: "auto",
              borderRadius: "18px",
              borderWidth: "2px !important",
              border: "solid",
              borderColor: "#ECECEE",
            }}
            onScroll={(e) => {
              const { scrollTop, scrollHeight, clientHeight } = e.target;
              const isAtBottom = scrollTop + clientHeight === scrollHeight; // Check if the scroll position is at the bottom

              // Set the checkbox state based on whether the user is at the bottom
              formik.setFieldValue("acceptTerms", isAtBottom);
              setReadTerms(isAtBottom);
            }}
          >
            <Terms />
          </Box>
        </FormControl>
      </Grid>
    );
  };

  const renderList = () => {
    return selectedPlan.data.map((data, index) => (
      <Grid container item xs={12} key={index}>
        <Grid item xs={8}>
          <Typography
            textAlign={"left"}
            variant="body1"
            sx={{ fontSize: "0.8rem !important" }}
            key={data.id}
          >
            {data.description}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" key={data.id} textAlign={"right"}>
            {data.value}
          </Typography>
        </Grid>
      </Grid>
    ));
  };

  if (loading) {
    return (
      <Container maxWidth="xs" id="acceptanceContainer">
        <Box
          sx={{
            // width: "80%",
            // maxWidth: 350,
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            margin: "1em auto 4em auto",
            borderRadius: "18px",
            borderWidth: "2px !important",
            border: "solid",
            borderColor: "#ECECEE",
            backgroundColor: "#F9FBFF",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;",
            p: 2,
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }
  if (linkAvailable === false) {
    return (
      <Container
        maxWidth="xs"
        id="acceptanceContainer"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 5,
        }}
      >
        <Box
          sx={{
            // width: "80%",
            // maxWidth: 350,
            minHeight: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            margin: "1em auto 4em auto",
            borderRadius: "18px",
            borderWidth: "2px !important",
            border: "solid",
            borderColor: "#ECECEE",
            backgroundColor: "#F9FBFF",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;",
            p: 2,
          }}
        >
          <Box mt={2} mb={4}>
            <Typography
              variant="h5"
              color="warning"
              sx={[
                styles.title,
                { textAlign: "center", color: theme.palette.primary.main },
              ]}
            >
              Tu solicitud de inclusión ha sido enviada
            </Typography>
          </Box>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="xs" id="acceptanceContainer">
      <Box
        sx={{
          // width: "80%",
          // maxWidth: 350,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "1em auto 4em auto",
          borderRadius: "18px",
          borderWidth: "2px !important",
          border: "solid",
          borderColor: "#ECECEE",
          backgroundColor: "#F9FBFF",
          boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;",
          p: 2,
        }}
      >
        <Box mt={2} mb={4}>
          <Typography variant="h5" sx={styles.title}>
            Solicitud de inclusión
          </Typography>
        </Box>
        <Box sx={[styles.plansCard, { mt: 0, mb: 2, p: 0 }]}>
          {/* <Grid container columnGap={2} justifyContent={"flex-start"} mb={2}>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  label="Nombre"
                  variant="outlined"
                  value={data.name}
                  InputProps={{
                    readOnly: true,
                    pointerEvents: "none",
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container columnGap={0} justifyContent={"flex-start"} mb={4}>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  label="Correo Electrónico"
                  variant="outlined"
                  value={data.email}
                  InputProps={{
                    readOnly: true,
                    pointerEvents: "none",
                  }}
                />
              </FormControl>
            </Grid>
          </Grid> */}
          <Box sx={styles.formTitle} mb={4}>
            <Typography
              variant="h7"
              sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
            >
              Resumen del Plan
            </Typography>
          </Box>
          <Grid container columnGap={2} justifyContent={"space-between"}>
            <Grid item md={12} container mb={3} sx={styles.summaryCard}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",

                  flexDirection: "column",
                }}
              >
                <Typography variant="h6">{selectedPlan.subTier}</Typography>
                <Typography variant="body1">
                  Código: {selectedPlan.code}
                </Typography>

                <Grid
                  container
                  sx={{
                    margin: "0 auto",
                    mt: 2,

                    pt: 1,
                    pb: 1,
                  }}
                >
                  <Box mb={1}>
                    <Typography variant="body1">Detalles del Plan</Typography>
                  </Box>
                  {renderList()}
                </Grid>
              </Box>
            </Grid>
            <Grid item md={12} container mb={3} sx={styles.summaryCard}>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Precio Mensual"
                    variant="standard"
                    value={selectedPlan.finalPrice}
                    InputProps={{
                      readOnly: true,
                      pointerEvents: "none",
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Prima a pagar"
                    variant="standard"
                    value={priceToCharge}
                    id="ApportionmentPrice"
                    InputProps={{
                      readOnly: true,
                    }}
                    name="ApportionmentPrice"
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                    }}
                  >
                    El pago de tu plan {selectedPlan.subTier} fue ajustando a la
                    fecha {new Date().getDate()}/{new Date().getMonth() + 1}/
                    {new Date().getFullYear()} . Al activar este plan autorizas
                    su recurrencia mensual el primer día del mes en curso por un
                    total de ₡{selectedPlan.finalPrice} I.V.A.I. mensuales.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* @ND ROW */}

          <Grid
            container
            spacing={2}
            mb={3}
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {renderContent()}
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <FormControlLabel
                  value="acceptTerms"
                  label="Acepto términos y condiciones"
                  labelPlacement="end"
                  disabled={!readTerms}
                  control={
                    <Checkbox
                      name="acceptTerms"
                      checked={formik.values.acceptTerms}
                      onChange={formik.handleChange}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Grid
          container
          sx={styles.buttonsContainer}
          justifyContent={"flex-end"}
          direction="row"
        >
          <Grid item>
            <Box ml={2}>
              <ASbutton
                onClick={formik.handleSubmit}
                backgroundColor={theme.palette.primary.main}
                color={"white"}
                disabled={!readTerms}
                buttonText={"Finalizar"}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default Acceptance;
