import React, { useCallback, useEffect, useRef, useState } from "react";

import { Box, Container, Tab, Tabs } from "@mui/material";
import { styles } from "../../styles/styles";

import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  createAssociationImage,
  getAssociationData,
  updateB2b,
} from "../../store/actions/association";
import { EditProfileForm } from "../../components/profile/EditProfileForm";
import ActivePlans from "../../components/profile/ActivePlans";

const MySwal = withReactContent(Swal);

export const EditProfile = () => {
  const { idToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { association } = useSelector((state) => state.association);

  const [activeStep, setActiveStep] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState("");
  const isFirstUse = useRef(true);
  const [activeTab, setActiveTab] = useState(0); // Initialize activeTab to 0

  const [loading, setLoading] = useState(false);
  //IMAGE
  const [imageUpload, setImageUpload] = useState("");
  const [image, setImage] = useState("");
  const [fileUrl, setFileUrl] = useState(null);
  const associationId = association.associationId;
  const [data, setData] = useState({});

  const getAssociationFromFB = useCallback(async () => {
    try {
      dispatch(getAssociationData(associationId));
      setData(association);
    } catch (error) {
      console.log(error);
    }
  }, [associationId, dispatch]);

  useEffect(() => {
    if (isFirstUse.current) {
      setLoading(true);
      getAssociationFromFB();
      isFirstUse.current = false;
    } else if (association.length === 0) {
      setLoading(true);
      getAssociationFromFB();
    } else if (association) {
      setData(association);
      setLoading(false);
    }
  }, [getAssociationFromFB, association]);

  const handleRefresh = () => {
    setLoading(true);

    getAssociationFromFB();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  //   useEffect(() => {
  //     console.log(association);
  //   }, [data]);

  //   console.log(imageUpload);

  const handleNext = (newData) => {
    // console.log("click");
    setData((prev) => ({ ...prev, ...newData }));

    // console.log("Data after handleNext:", { ...data, ...newData });

    handleRegister(newData);
    // console.log(newData);

    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleRegister = async (values) => {
    const updatedData = {
      name: values.name,
      codeName: values.codeName,
      legalId: values.legalId,
      phone: values.phone,
      email: values.email,
      businessName: values.businessName,
      location: new firebase.firestore.GeoPoint(
        parseFloat(values.location.latitude),
        parseFloat(values.location.longitude)
      ),
      address: values.address,
      isActive: values.isActive ?? false,
      imageURL: image ?? "",
    };

    const confirmResult = await MySwal.fire({
      title: "Confirmar edición",
      text: "¿Estás seguro de que deseas editar tu asociación?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, editar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    });

    if (confirmResult.isConfirmed) {
      try {
        if (imageUpload !== "") {
          createAssociationImage(imageUpload, associationId);
        }
        console.log(associationId, updatedData);
        await updateB2b(associationId, updatedData);
        handleRefresh();

        MySwal.fire("Guardado!", "", "success");
      } catch (error) {
        MySwal.fire("Error", "Hubo un error al editar la asociación", "error");
      }

      handleRefresh();
      setLoading(false);
    }
  };

  const steps = ["Información de la Asociación", "Tipos de Seguro Activos"];

  //   const handleNext = (newData) => {
  //     setData((prev) => ({ ...prev, ...newData }));

  //     // console.log("Data after handleNext:", { ...data, ...newData });

  //     if (activeStep === 2) {
  //       handleRegister(newData);

  //       return;
  //     }
  //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleBack = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Container maxWidth="xxl">
      <Box sx={styles.formCard}>
        <Tabs
          value={activeTab} // Use activeTab here
          onChange={handleTabChange} // Handle tab changes
          indicatorColor="primary"
          sx={{ marginTop: 1, marginLeft: 1 }}
        >
          {steps.map((label, index) => (
            <Tab key={index} label={label} />
          ))}
        </Tabs>

        <Box>
          {activeTab === 0 && (
            <EditProfileForm
              handleNext={handleNext}
              handleBack={handleBack}
              loading={loading}
              data={data}
              handleRegister={handleRegister}
              activeStep={activeStep}
              loadUrl={setFileUrl}
              imageUpload={imageUpload}
              setImageUpload={setImageUpload}
              setImage={setImage}
              image={image}
            />
          )}

          {activeTab == 1 && (
            <ActivePlans
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              associationId={associationId}
            />
          )}
          {/* {activeStep == 2 && (
            <B2bSummary
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
              selectedPlan={selectedPlan}
              data={data}
            />
          )} */}
          {/* {activeStep == 3 && (
            <B2bSummary
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
            />
          )} */}
        </Box>

        <Box></Box>
      </Box>
    </Container>
  );
};
