import React from "react";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import createCache from "@emotion/cache";
import { CacheProvider, jsx, css } from "@emotion/react";

import responsiveASTheme from "./styles/asTheme";
import AppRoutes from "./router/AppRoutes";
import { LocalizationProvider } from "@mui/x-date-pickers";

// meter cache provider
function App() {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={responsiveASTheme}>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
}

export default App;
