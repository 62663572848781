import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../store/actions/auth";

import { Box, styled, useTheme, Avatar, useMediaQuery } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { ReactComponent as HomeIcon } from "./../../assets/homeIcon.svg";
import achievemetsIcon from "./../../assets/achievements.png";
import { ReactComponent as WalletMoneyIcon } from "./../../assets/walletIcon.svg";
import { ReactComponent as CommunityIcon } from "./../../assets/communityIcon.svg";
import { ReactComponent as UsersIcon } from "./../../assets/userMenuIcon.svg";
import {
  IoHelp,
  IoPeopleOutline,
  IoPersonAddOutline,
  IoPersonOutline,
  IoPersonRemoveOutline,
  IoReaderOutline,
  IoSettingsOutline,
  IoStarOutline,
} from "react-icons/io5";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import "./navigation.scss";
import MenuButton from "./MenuButton";
import logo from "../../assets/logo.png";

import { MdPersonAdd, MdPersonRemove } from "react-icons/md";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const drawerWidth = 225;
const miniDrawerWidth = 64; // Define the width for the mini sidebar
const MySwal = withReactContent(Swal);
const Navigation = ({
  handleDrawerToggle,
  opened,
  open,
  mobileVerticalDrawerOpen,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const navigateTo = (link) => {
    navigate(link);
  };

  const handleLogout = async () => {
    const confirmResult = await MySwal.fire({
      title: "¿Estás seguro de que quieres cerrar la sesión?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Volver",
      confirmButtonText: "Sí",
      reverseButtons: true,
    });

    if (confirmResult.isConfirmed) {
      dispatch(logoutUser());
      navigate("/auth/login", { replace: true });
    } else {
    }
  };
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const tablet = useMediaQuery(theme.breakpoints.down("lg"));
  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(6)} + 1px)`,
    },
  });

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",

    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const drawer = (
    <Drawer
      variant={"permanent"}
      open={open}
      sx={
        tablet
          ? {
              "& .MuiPaper-root": {
                // position: "absolute !important",
                backgroundColor: "#fbfafc !important",
                height: " 65vh !important",
                marginLeft: "1.5em",
                mt: 10,
                borderRadius: "18px",
                borderWidth: "1px !important",
                border: "solid",
                borderColor: "#ECECEE",
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;",
              },
            }
          : {
              "& .MuiPaper-root": {
                backgroundColor: "#fbfafc !important",
                height: " 65vh !important",
                marginLeft: "1.5em",
                mt: 10,
                borderRadius: "18px",
                borderWidth: "1px !important",
                border: "solid",
                borderColor: "#ECECEE",
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;",
              },
            }
      }
    >
      <DrawerHeader>
        <Box
          component={"a"}
          id="sidebar"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "175px",
          }}
          onClick={() => navigateTo("/")}
        >
          {open && (
            <img
              src={logo}
              alt="Home"
              style={{ width: "75%", height: "auto" }}
            />
          )}
        </Box>

        <IconButton
          onClick={handleDrawerToggle}
          sx={{ color: theme.palette.primary.main }}
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>

      <List>
        <MenuButton
          item={{ title: "Inicio", icon: <HomeIcon />, route: "/home" }}
          open={open}
        />
        <MenuButton
          item={{
            title: "Consultas",
            icon: <IoReaderOutline size={25} className="inactive-icon" />,
            route: "/inquiries",
          }}
          open={open}
        />
        <MenuButton
          item={{
            title: "Tus Usuarios",
            icon: <IoPeopleOutline size={25} className="inactive-icon" />,
            route: "/b2busers",
          }}
          open={open}
        />
        <MenuButton
          item={{
            title: "Generar Código",
            icon: <IoPeopleOutline size={25} className="inactive-icon" />,
            route: "/generate",
          }}
          open={open}
        />
        <MenuButton
          item={{
            title: "Inclusiones",
            icon: <IoPersonAddOutline size={25} className="inactive-icon" />,
            route: "/inclusions",
          }}
          open={open}
        />
        <MenuButton
          item={{
            title: "Exclusiones",
            icon: <IoPersonRemoveOutline size={25} className="inactive-icon" />,
            route: "/exclusions",
          }}
          open={open}
        />

        <MenuButton
          item={{
            title: "Perfil",
            icon: <IoPersonOutline size={25} className="inactive-icon" />,
            route: "/profile",
          }}
          open={open}
        />
        <MenuButton
          item={{
            title: "Configuración",
            icon: <IoSettingsOutline size={25} className="inactive-icon" />,
            route: "/settings",
          }}
          open={open}
        />
      </List>
      <Box style={{ flexGrow: 1 }} />

      <List>
        <ListItem
          onClick={handleLogout}
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
            cursor: "pointer",
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Cerrar sesión" />
        </ListItem>
      </List>
    </Drawer>
  );
  const mobileDrawer = (
    <Drawer
      variant={"permanent"}
      open={false}
      sx={{
        "& .MuiPaper-root": {
          position: "absolute",
          backgroundColor: "#fbfafc !important",
          height: " 410px !important",
          marginLeft: "1.5em",
          marginTop: "3em",
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;",
        },
      }}
    >
      <List>
        <MenuButton
          item={{ title: "Inicio", icon: <HomeIcon />, route: "/home" }}
          open={false}
          handleDrawerToggle={handleDrawerToggle}
        />
        <MenuButton
          item={{
            title: "Consultas",
            icon: <IoReaderOutline size={25} className="inactive-icon" />,
            route: "/inquiries",
          }}
          handleDrawerToggle={handleDrawerToggle}
          open={false}
        />
        <MenuButton
          item={{
            title: "Tus Usuarios",
            icon: <IoPeopleOutline size={25} className="inactive-icon" />,
            route: "/b2busers",
          }}
          handleDrawerToggle={handleDrawerToggle}
          open={false}
        />
        <MenuButton
          item={{
            title: "Inclusiones",
            icon: <MdPersonAdd size={25} className="inactive-icon" />,
            route: "/inclusions",
          }}
          handleDrawerToggle={handleDrawerToggle}
          open={false}
        />
        <MenuButton
          item={{
            title: "Exclusiones",
            icon: <MdPersonRemove size={25} className="inactive-icon" />,
            route: "/exclusions",
          }}
          handleDrawerToggle={handleDrawerToggle}
          open={false}
        />

        <MenuButton
          item={{
            title: "Perfil",
            icon: <IoPersonOutline size={25} className="inactive-icon" />,
            route: "/profile",
          }}
          handleDrawerToggle={handleDrawerToggle}
          open={false}
        />
        <MenuButton
          item={{
            title: "Configuración",
            icon: <IoSettingsOutline size={25} className="inactive-icon" />,
            route: "/settings",
          }}
          handleDrawerToggle={handleDrawerToggle}
          open={false}
        />
      </List>

      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <ListItem
          onClick={handleLogout}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,

              justifyContent: "center",
            }}
          >
            <ExitToAppIcon />
          </ListItemIcon>
          {/* <ListItemText primary="Cerrar sesión" /> */}
        </ListItem>
      </Box>
    </Drawer>
  );

  return (
    <Box sx={{ display: "flex" }}>
      {mobile ? (
        <>
          {!mobileVerticalDrawerOpen ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              sx={{
                zIndex: theme.zIndex.drawer + 1,
                color: theme.palette.primary.main,
              }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                sx={{ zIndex: theme.zIndex.drawer + 1 }}
              >
                <MenuIcon />
              </IconButton>
              {mobileDrawer}
            </>
          )}
        </>
      ) : (
        drawer
      )}
    </Box>
  );
};

export default Navigation;
