import { types } from "../types";
import {
  LOGIN,
  LOGIN_START,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  IS_LOADING_AUTH,
  IS_ASSOCIATION,
  LOGOUT,
  SET_ADMIN_DATA,
  SUPPORT_PHONE,
} from "../actions/auth";

const initialState = {
  user: null,
  loggedIn: false,
  idToken: "",
  isAlmighty: false,
  isAdmin: false,
  isModerator: false,
  isLoadingAuth: true,
  userGetToken: null,
  adminData: null,
  isAssociation: true,
  supportPhone: "",
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: action.payload.user,
        loggedIn: action.payload.loggedIn,
        idToken: action.payload.idToken,
        isAlmighty: action.payload.isAlmighty || false,
        isAdmin: action.payload.isAdmin || false,
        isModerator: action.payload.isModerator || false,
        isLoadingAuth: action.payload.isLoadingAuth || false,
        userGetToken: action.payload.userGetToken,
      };
    case IS_LOADING_AUTH:
      return {
        ...state,
        isLoadingAuth: action.payload.isLoadingAuth,
      };

    case LOGIN_START:
      return {
        ...state,
        isLoadingAuth: action.payload,
      };

    case LOGOUT:
      return initialState;

    case LOGOUT_SUCCESS:
      return {
        ...initialState,
        isLoadingAuth: false,
      };

    case SET_ADMIN_DATA:
      return {
        ...state,
        adminData: action.payload.adminData,
      };

    case LOGOUT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case IS_ASSOCIATION:
      return {
        ...state,
        isAssociation: action.payload,
        isLoadingAuth: false,
      };
    case SUPPORT_PHONE:
      return {
        ...state,
        supportPhone: action.payload,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoadingAuth: false,
      };

    default:
      return state;
  }
};
