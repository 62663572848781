import { db } from "../../firebase/firebase-config";
import { ADMIN_API_URL, API_URL } from "../../utils/globals";

export const GET_PRODUCTS = "GET_USERS";

export const IS_LOADING_PRODUCTS = "IS_LOADING_PRODUCTS";

//ACTIONS

export const getAssociationProducts = (associationId) => {
  return async (dispatch) => {
    dispatch(isLoading(true)); // Set loading to true before fetching

    try {
      const productsQuerySnapshot = await db
        .collection("products")
        .where("associationRef", "==", associationId)
        .get();

      const productsData = productsQuerySnapshot.docs.map((productDoc) => ({
        id: productDoc.id,
        ...productDoc.data(),
      }));

      dispatch(getProducts(productsData));
      dispatch(isLoading(false));
    } catch (error) {
      console.error(error);

      dispatch(isLoading(false));
    }
  };
};

export const getProductFromCode = async (productId) => {
  const product = await db.collection("products").doc(productId);

  return product.data();
};

export const getApportionment = async (data, token) => {
  const url = ADMIN_API_URL + "/createUser";

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const getProducts = (products) => ({
  type: GET_PRODUCTS,
  payload: {
    products,
  },
});

export const isLoading = (isLoadingProducts) => ({
  type: IS_LOADING_PRODUCTS,
  payload: {
    isLoadingProducts,
  },
});
